export const formInitialValues = (data) => {
  return Object({
    tgl_permintaan_produksi_aset:
      data?.tgl_permintaan_produksi_aset ?? undefined,
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    no_produksi_aset: data?.no_produksi_aset ?? "",
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_aset ?? "",
    keperluan: data?.keperluan ?? "",
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    bahan:
      data?.bahan?.length > 0
        ? data?.bahan?.map((val) => ({
            id_item: val?.id_item_bahan ?? undefined,
            nama_item: val?.nama_item ?? "",
            qty: val?.qty ?? 0,
            harga_satuan: val?.harga_satuan ?? 0,
            id_satuan: val?.id_satuan ?? undefined,
            nama_satuan: val?.nama_satuan ?? "",
            id_gudang: val?.id_gudang ?? undefined,
            nama_gudang: val?.nama_gudang ?? "",
          }))
        : [],
    upah:
      data?.upah?.length > 0
        ? data?.upah?.map((val) => ({
            id_item: val?.id_item_upah ?? undefined,
            nama_item: val?.nama_item ?? "",
            qty: val?.qty ?? 0,
            harga_satuan: val?.harga_satuan ?? 0,
            nama_satuan: val?.nama_satuan ?? "",
          }))
        : [],
    alatMesin:
      data?.alat_mesin?.length > 0
        ? data?.alat_mesin?.map((val) => ({
            id_item: val?.id_item_alat_mesin ?? undefined,
            nama_item: val?.nama_item ?? "",
            qty: val?.qty ?? 0,
            harga_satuan: val?.harga_satuan ?? 0,
            nama_satuan: val?.nama_satuan ?? "",
          }))
        : [],
    subkon:
      data?.subkon?.length > 0
        ? data?.subkon?.map((val) => ({
            id_item: val?.id_item_subkon ?? undefined,
            nama_item: val?.nama_item ?? "",
            qty: val?.qty ?? 0,
            harga_satuan: val?.harga_satuan ?? 0,
            nama_satuan: val?.nama_satuan ?? "",
          }))
        : [],
    overhead:
      data?.overhead?.length > 0
        ? data?.overhead?.map((val) => ({
            id_item: val?.id_item_overhead ?? undefined,
            nama_item: val?.nama_item ?? "",
            qty: val?.qty ?? 0,
            harga_satuan: val?.harga_satuan ?? 0,
            nama_satuan: val?.nama_satuan ?? "",
          }))
        : [],
  });
};
