// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { ButtonGroup } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  UpdateButton,
  Table,
  InputSearch,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  Switch,
  DeleteModal,
  FilterButton,
  CreateButton,
} from "components";
import { PageNumber as TableNumber, DateConvert } from "utilities";

// API
import { AnalisaBarangJadiApi } from "api";

// View Components
import { ModalFilter } from "./components";
import { getStatusApprovalLabel } from "./functions";

const RegistrasiAnalisaBarangJadi = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const filter = location?.state?.pagination;
  const title = "Analisa Barang Jadi";

  // States
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter?.analisa?.active,
    page: filter?.analisa?.page ?? 1,
    per_page: filter?.analisa?.per_page ?? 10,
    q: filter?.analisa?.q ?? "",
    jenis_barang: filter?.analisa?.jenis_barang,
    status_approval: filter?.analisa?.status_approval,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [deleteData, setDeleteData] = useState({
    show: false,
    data: {
      id_item_buaso: "",
    },
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);

  const getInitialData = () => {
    const filterData = { ...dataFilter };
    delete filterData.active;

    setIsPageLoading(true);

    AnalisaBarangJadiApi.page(filterData)
      .then((res) => {
        setData(res?.data?.data ?? []);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [setNavbarTitle]);

  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [dataFilter]);

  const HapusModal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const deleteDataHandler = () => {
      setBtnLoading(true);

      AnalisaBarangJadiApi.delete({ id_item_buaso: deleteData.data.id_item_buaso })
        .then(() =>
          setAlertConfig({ show: true, variant: "primary", text: "Hapus data berhasil!" })
        )
        .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Hapus data gagal!" }))
        .finally(() => {
          setDeleteData({ ...deleteData, show: false });
          getInitialData();
        });
    };

    return (
      <DeleteModal
        show={deleteData.show}
        onHide={() => setDeleteData({ ...deleteData, show: false })}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Hapus Barang Jadi"
      >
        <div>Kode Item : {deleteData.data.kode_item}</div>
        <div>Nama Barang Jadi : {deleteData.data.nama_item}</div>
      </DeleteModal>
    );
  };

  const changeDataStatus = (status, id_jobmix_design) => {
    setIsPageLoading(true);
    const value = { id_jobmix_design };

    const onLoadedSuccess = () => {
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
    };

    status === true
      ? AnalisaBarangJadiApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getInitialData())
      : AnalisaBarangJadiApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getInitialData());
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode Barang Jadi</ThFixed>
            <Th style={{ minWidth: "250px" }}>Nama Barang Jadi</Th>
            <Th style={{ width: "150px" }}>Varian</Th>
            <Th style={{ width: "150px" }}>Kelompok Barang</Th>
            <Th style={{ width: "150px" }}>Jenis Barang</Th>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Update Terakhir</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(dataFilter.page, dataFilter.per_page, index)}</TdFixed>
              <Td className="align-middle">
                <ButtonGroup>
                  {getStatusApprovalLabel(val.status_approval).level >= 1 && (
                    <ReadButton
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/master/analisa-barang-jadi/detail/${val.id_analisa_barang_jadi}`,
                          {
                            pagination: {
                              ...filter,
                              analisa: dataFilter,
                            },
                          }
                        )
                      }
                    />
                  )}
                  {getStatusApprovalLabel(val.status_approval).level >= 2 && (
                    <UpdateButton
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/master/analisa-barang-jadi/ubah/${val.id_analisa_barang_jadi}`,
                          {
                            pagination: {
                              ...filter,
                              analisa: dataFilter,
                            },
                          }
                        )
                      }
                    />
                  )}
                  {/* {getStatusApprovalLabel(val.status_approval).level >= 3 && (
                    <DeleteButton
                      size="sm"
                      onClick={() => setDeleteData({ show: true, data: val })}
                    />
                  )} */}

                  {getStatusApprovalLabel(val.status_approval).level >= 4 && (
                    <Switch
                      id={toString(index + 1)}
                      checked={val.is_hidden === false ? true : false}
                      onChange={() => changeDataStatus(val.is_hidden, val.id_jobmix_design)}
                    />
                  )}
                </ButtonGroup>
              </Td>
              <TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>{val.nama_varian ?? "-"}</Td>
              <Td>{val.nama_kelompok ?? "-"}</Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
              <Td>
                <ApprovalStatusButton variant={getStatusApprovalLabel(val.status_approval).variant}>
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </Td>
              <TdFixed>
                {val.tgl_analisa_barang_jadi
                  ? DateConvert(new Date(val.tgl_analisa_barang_jadi)).defaultDMY
                  : "-"}
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>{`List Data ${title}`}</b>
        <DataTable />
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(dataFilter.page, dataFilter.per_page, index);
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={dataFilter.q}
              onChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  q: e.target.value,
                })
              }
            />

            <FilterButton active={dataFilter?.active} onClick={() => setModalFilter(true)} />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/master/analisa-barang-jadi/list-job-mix-design", {
                pagination: {
                  ...filter,
                  analisa: dataFilter,
                },
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div style={{ minHeight: "500px" }}>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => dismissAlert()}
        />

        {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

        {deleteData.show && <HapusModal />}
        {modalFilter && (
          <ModalFilter
            show={modalFilter}
            setShow={setModalFilter}
            data={dataFilter}
            setData={setDataFilter}
          />
        )}
      </div>
    </CRUDLayout>
  );
};

export default RegistrasiAnalisaBarangJadi;
