import React, { useEffect, useState } from "react";
import {
  DatePicker,
  InfoItemHorizontal,
  Input,
  SelectSearch,
} from "components";
import { Card, Col, Form, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";
import { CloseJobMixApi } from "api";

const InfoFormSection = ({ dataInfo, formik, type }) => {
  const [noLoading, setNoLoading] = useState(false);

  const { values, touched, errors, setFieldValue } = formik;

  // Generate nomor ke server
  const getNoHandler = (val) => {
    setNoLoading(true);
    const tgl = val ?? values.tgl_close_jobmix;

    CloseJobMixApi.getNo({ tanggal: tgl })
      .then((res) => {
        setFieldValue("no_close_jobmix", res.data.data);
      })
      .finally(() => setNoLoading(false));
  };

  const InfoItemDetail = ({ text, label }) => (
    <div>
      <small style={{ color: "#999999", fontSize: 11 }}>{label}</small>
      <div>
        <b>{text}</b>
      </div>
    </div>
  );

  useEffect(() => {
    getNoHandler();
  }, []);

  return (
    <Card className="mb-4">
      <Card.Body>
        <Row>
          <Col md>
            <InfoItemHorizontal
              label="Tgl. Produksi"
              text={DateConvert(new Date(dataInfo.tgl_produksi)).detail}
            />
            <InfoItemHorizontal
              label="No. Produksi"
              text={dataInfo.no_produksi}
            />
            <InfoItemHorizontal
              label="Item Produksi"
              text={dataInfo.nama_item}
            />
            <InfoItemHorizontal
              label="Qty. Produksi"
              text={DecimalConvert(dataInfo.qty_produksi).getWithComa}
            />
            <InfoItemHorizontal
              label="Total Qty. Telah Direalisasi"
              text={DecimalConvert(dataInfo.qty_realisasi).getWithComa}
            />
          </Col>
          <Col md>
            <InfoItemHorizontal
              label="Tgl. Sales Order"
              text={
                dataInfo?.tgl_sales_order
                  ? DateConvert(new Date(dataInfo?.tgl_sales_order)).detail
                  : "-"
              }
            />
            <InfoItemHorizontal
              label="No. Sales Order"
              text={dataInfo.no_sales_order}
            />
            <InfoItemHorizontal
              label="Customer"
              text={dataInfo.nama_customer}
            />
            <InfoItemHorizontal
              label="Proyek"
              text={dataInfo.nama_proyek ?? "-"}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="3">
            {type === "detail" ? (
              <InfoItemDetail
                label="Tgl. Close Produksi"
                text={DateConvert(new Date(values.tgl_close_jobmix)).detail}
              />
            ) : (
              <DatePicker
                label="Tgl. Close Produksi*"
                dateFormat="dd/MM/yyyy"
                selected={
                  values.tgl_close_jobmix
                    ? new Date(values.tgl_close_jobmix)
                    : undefined
                }
                onChange={(val) => {
                  const tgl = DateConvert(new Date(val)).default;
                  setFieldValue("tgl_close_jobmix", tgl);
                  getNoHandler(tgl);
                }}
                error={
                  Boolean(errors.tgl_close_jobmix) && touched.tgl_close_jobmix
                }
                errorText={errors.tgl_close_jobmix}
              />
            )}
          </Col>
          {console.log(values)}
          <Col md="3">
            {type === "detail" ? (
              <InfoItemDetail
                label="No. Close Produksi"
                text={dataInfo.no_close_jobmix ?? "-"}
              />
            ) : (
              <Input
                label="No. Close Produksi*"
                type="text"
                value={noLoading ? "Memuat Data..." : values.no_close_jobmix}
                readOnly={true}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoFormSection;
