import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  // Dashboard
  {
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["SUPA", "PRD"],
  },
  // Master
  {
    text: "Master Data",
    type: "dropdown",
    icon: <IoServerOutline />,
    hak: ["SUPA", "PRD", "PRD_MAS_JMD"],
    menu: [
      {
        text: "Jenis Barang Jadi",
        link: "/master/jenis-barang-jadi",
        hak: ["SUPA", "PRD_MAS_JBJ"],
      },
      // {
      //   text: "Barang Jadi",
      //   link: "/master/registrasi-barang-jadi",
      //   hak: ["SUPA", "PRD_MAS_BRJ"],
      // },
      {
        text: "Pabrik",
        link: "/master/pabrik",
        hak: ["SUPA", "PRD_MAS_PAB"],
      },
      {
        text: "Job Mix Design",
        link: "/master/job-mix-design",
        hak: ["SUPA", "PRD_MAS_JMD"],
      },
      // {
      //   text: "Analisa Barang Jadi",
      //   link: "/master/analisa-barang-jadi",
      //   hak: ["SUPA", "PRD_MAS_ABJ"],
      // },
    ],
  },
  // Transaksi
  {
    text: "Transaksi",
    type: "dropdown",
    icon: <IoCashOutline />,
    hak: [
      "SUPA",
      "PRD",
      "PRD_TRN_JBX",
      "PRD_TRN_PPR",
      "PRD_TRN_REP",
      "PRD_TRN_CLJ",
    ],
    menu: [
      {
        text: "Produksi",
        link: "/transaksi/job-mix",
        hak: ["SUPA", "PRD_TRN_JBX"],
      },
      {
        text: "Permintaan Produksi",
        link: "/transaksi/permintaan-produksi",
        hak: ["SUPA", "PRD_TRN_PPR"],
      },
      {
        text: "Realisasi Produksi",
        link: "/transaksi/realisasi-produksi",
        hak: ["SUPA", "PRD_TRN_REP"],
      },
      {
        text: "Close Produksi",
        link: "/transaksi/close-job-mix",
        hak: ["SUPA", "PRD_TRN_CLJ"],
      },
      {
        text: "Produksi Aset",
        link: "/transaksi/produksi-aset",
        hak: ["SUPA", "PRD_TRN_PAT"],
      },
      {
        text: "Permintaan Produksi Aset",
        link: "/transaksi/permintaan-produksi-aset",
        hak: ["SUPA", "PRD_TRN_PPAT"],
      },
      {
        text: "Realisasi Produksi Aset",
        link: "/transaksi/realisasi-produksi-aset",
        hak: ["SUPA", "PRD_TRN_RPA"],
      },
      {
        text: "Close Produksi Aset",
        link: "/transaksi/close-produksi-aset",
        hak: ["SUPA", "PRD_TRN_CPA"],
      },
    ],
  },
  {
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentTextOutline />,
    hak: ["SUPA", "PRD"],
    menu: [
      {
        text: "Laporan Produksi",
        link: "/laporan/laporan-produksi",
        hak: ["SUPA", "PRD_RPT_JBX"],
      },
      {
        text: "Progress Produksi",
        link: "/laporan/progress-produksi",
        hak: ["SUPA", "PRD_RPT_PRP"],
      },
      {
        text: "Catatan Transaksi Harian Produksi",
        link: "/laporan/catatan-transaksi-harian",
        hak: ["SUPA", "PRD_RPT_CTHP"],
      },
    ],
  },

  // {
  //   text: "Profil",
  //   type: "dropdown",
  //   icon: <IoPersonCircleOutline />,
  //   hak: ["SUPA", "PRD"],
  //   menu: [
  //     {
  //       text: "Akun Saya",
  //       link: "/profil",
  //       hak: ["SUPA", "PRD"],
  //     },
  //     {
  //       text: "Ubah Akun",
  //       link: "/profil/ubah",
  //       hak: ["SUPA", "PRD"],
  //     },
  //     {
  //       text: "Ganti Password",
  //       link: "/profil/ganti-password",
  //       hak: ["SUPA", "PRD"],
  //     },
  //   ],
  // },
];
