export const formSubmitValueMapper = (data = {}) => ({
  tgl_produksi_aset: data?.tgl_produksi_aset,
  id_permintaan_aset_detail: data?.id_permintaan_aset_detail,
  bahan: data?.bahan?.map((val) => ({
    qty: val?.qty,
    id_item_bahan: val?.id_item,
    harga_satuan: val?.harga_satuan,
  })),
  upah: data?.upah?.map((val) => ({
    qty: val?.qty,
    id_item_upah: val?.id_item,
    harga_satuan: val?.harga_satuan,
  })),
  alat_mesin: data?.alatMesin?.map((val) => ({
    qty: val?.qty,
    id_item_alat_mesin: val?.id_item,
    harga_satuan: val?.harga_satuan,
  })),
  subkon: data?.subkon?.map((val) => ({
    qty: val?.qty,
    id_item_subkon: val?.id_item,
    harga_satuan: val?.harga_satuan,
  })),
  overhead: data?.overhead?.map((val) => ({
    qty: val?.qty,
    id_item_overhead: val?.id_item,
    harga_satuan: val?.harga_satuan,
  })),
});
