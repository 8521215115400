import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  NumberFormat,
} from 'components';
import { DateConvert, DecimalConvert } from 'utilities';
import { RealisasiProduksiApi } from 'api';

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, '');
  const convert = newValue.substring(0, 10);

  return convert;
};

export const InfoSection = ({ data = {}, action, setDataOverhead }) => {
  const { values, touched, errors, setFieldValue, setValues } =
    useFormikContext();
  const [loading, setLoading] = useState({
    nomor: false,
  });

  const getNomor = (date) => {
    setLoading({ ...loading, nomor: true });
    RealisasiProduksiApi.getNomor({ tgl_realisasi_produksi: date })
      .then((res) => {
        setValues({
          ...values,
          tgl_realisasi_produksi: date,
          no_realisasi_produksi: res?.data?.data,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          no_realisasi_produksi: '',
          tgl_realisasi_produksi: '',
        });
      })
      .finally(() => {
        setLoading({ ...loading, nomor: false });
      });
  };

  return (
    <>
      <Row>
        <Col md>
          <InfoItemHorizontal
            label="Tgl. Produksi"
            text={
              data?.tgl_jobmix
                ? DateConvert(new Date(data?.tgl_jobmix)).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Produksi"
            text={data?.no_jobmix ?? '-'}
          />
          <InfoItemHorizontal
            label="Kode Item Produksi"
            text={data?.kode_item ?? '-'}
          />
          <InfoItemHorizontal
            label="Item Produksi"
            text={data?.nama_item ?? '-'}
          />
        </Col>
        <Col md>
          <InfoItemHorizontal
            label="Qty. Produksi"
            text={`${
              data?.qty_jobmix
                ? DecimalConvert(data.qty_jobmix).getWithComa
                : '-'
            } ${data?.kode_satuan ?? ''}`}
          />
          <InfoItemHorizontal
            label="Total Qty. Telah Direalisasi"
            text={`${
              data?.qty_realisasi
                ? DecimalConvert(data.qty_realisasi).getWithComa
                : 0
            } ${data?.kode_satuan ?? ''}`}
          />
          <InfoItemHorizontal
            label={action === 'CREATE' ? 'Gudang Tujuan' : 'Gudang Produksi'}
            text={data?.nama_gudang ?? '-'}
          />
          <InfoItemHorizontal label="Proyek" text={data?.nama_proyek ?? '-'} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg>
          <Row>
            <Col lg>
              {action === 'DETAIL' ? (
                <InfoItemVertical
                  label="Tgl. Realisasi Produksi"
                  text={
                    data?.tgl_realisasi_produksi
                      ? DateConvert(new Date(data?.tgl_realisasi_produksi))
                          .detail
                      : '-'
                  }
                />
              ) : (
                <>
                  <DatePicker
                    label="Tgl. Realisasi Produksi"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Masukan tgl. realisasi"
                    selected={
                      values.tgl_realisasi_produksi
                        ? new Date(values.tgl_realisasi_produksi)
                        : null
                    }
                    onChange={(e) => {
                      setFieldValue(
                        'tgl_realisasi_produksi',
                        DateConvert(new Date(e)).default,
                      );
                      getNomor(DateConvert(new Date(e)).default);
                    }}
                    error={
                      errors.tgl_realisasi_produksi &&
                      touched.tgl_realisasi_produksi
                    }
                    errorText={
                      !!(
                        errors.tgl_realisasi_produksi &&
                        touched.tgl_realisasi_produksi
                      ) && errors.tgl_realisasi_produksi
                    }
                  />
                </>
              )}
            </Col>
            <Col lg>
              {action === 'DETAIL' ? (
                <InfoItemVertical
                  label="No. Realisasi Produksi"
                  text={data?.no_realisasi_produksi ?? '-'}
                />
              ) : (
                <>
                  <Input
                    disabled
                    label="No. Realisasi Produksi"
                    placeholder="Masukan no. realisasi"
                    value={
                      loading.nomor
                        ? 'Memuat . . .'
                        : values.no_realisasi_produksi ?? ''
                    }
                    error={
                      errors.no_realisasi_produksi &&
                      touched.no_realisasi_produksi
                    }
                    errorText={
                      !!(
                        errors.no_realisasi_produksi &&
                        touched.no_realisasi_produksi
                      ) && errors.no_realisasi_produksi
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col lg>
          <Row>
            <Col lg>
              {action === 'DETAIL' ? (
                <InfoItemVertical
                  label="Qty. Realisasi Item Produksi"
                  text={`${
                    DecimalConvert(data?.qty_realisasi_produksi ?? 0)
                      .getWithComa
                  } ${data?.nama_satuan ?? ''}`}
                />
              ) : (
                <>
                  <NumberFormat
                    label="Qty. Realisasi Item Produksi"
                    placeholder="Masukan qty realisasi produksi"
                    value={values?.qty_realisasi_produksi}
                    onChange={(e) => {
                      const available =
                        parseFloat(data.qty_jobmix ?? 0) -
                        parseFloat(data.qty_realisasi ?? 0);

                      const checkQty =
                        parseFloat(e ?? 0) <= parseFloat(available)
                          ? parseFloat(e)
                          : available;

                      setFieldValue('qty_realisasi_produksi', checkQty);

                      setDataOverhead((prev) =>
                        prev?.map?.((item) => ({
                          ...item,
                          qty_realisasi: e ?? 0,
                        })),
                      );
                    }}
                    error={
                      errors.qty_realisasi_produksi &&
                      touched.qty_realisasi_produksi
                    }
                    errorText={
                      !!(
                        errors.qty_realisasi_produksi &&
                        touched.qty_realisasi_produksi
                      ) && errors.qty_realisasi_produksi
                    }
                  />
                </>
              )}
            </Col>
            <Col lg>
              {action === 'DETAIL' ? (
                <></>
              ) : (
                <>
                  <Input
                    disabled
                    label="Satuan"
                    value={data?.nama_satuan ?? '-'}
                  />
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
