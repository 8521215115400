import {
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
  ButtonVerify,
  tableNumber,
} from "@bhawanadevteam/react-core";
import {
  Alert,
  DataStatus,
  Pagination,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import React, { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateConvert, DecimalConvert } from "utilities";
import { ModalDetail } from "./ModalDetail";

export const TableList = ({
  paginationConfig,
  setPaginationConfig,
  alertConfig,
  setAlertConfig,
  query,
  type,
}) => {
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });
  const onPaginationChange = ({ selected }) =>
    setPaginationConfig({
      ...paginationConfig,
      [type]: { ...paginationConfig[type], page: selected + 1 },
    });

  const checkTab = type === "alatMesin" ? "alat_mesin" : type;

  const onPaginationDataLengthChange = (e) =>
    setPaginationConfig({
      ...paginationConfig,
      [type]: {
        ...paginationConfig[type],
        page: 1,
        per_page: e.target.value,
      },
    });

  return query.isFetching ? (
    <DataStatus text="Memuat Data..." loading />
  ) : (
    <>
      <Alert
        show={alertConfig?.[type]?.show}
        text={alertConfig?.[type]?.text}
        variant={alertConfig?.[type]?.variant}
        showCloseButton
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            [type]: {
              ...alertConfig?.[type],
              show: false,
            },
          })
        }
      />
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi Aset</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <Th>Item Permintaan Produksi</Th>
            <Th>Qty. Permintaan Produksi</Th>
            <Th>Qty. Telah Ditransfer</Th>
          </Tr>
        </THead>
        <TBody>
          {query?.data?.data?.length > 0 ? (
            query?.data?.data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {tableNumber({
                    pageCurrent: paginationConfig?.[type]?.page,
                    dataLength: paginationConfig?.[type]?.per_page,
                    index,
                  })}
                </TdFixed>
                <TdFixed>
                  <ButtonGroup>
                    <ButtonDetail
                      icon
                      noText
                      variant="primary"
                      onClick={() => setModalConfig({ data: val, show: true })}
                    />
                  </ButtonGroup>
                </TdFixed>
                <Td>
                  <div>
                    {
                      DateConvert(
                        new Date(
                          val?.[`tgl_permintaan_produksi_aset_${checkTab}`]
                        )
                      ).custom
                    }
                  </div>
                  <div>
                    {val?.[`no_permintaan_produksi_aset_${checkTab}`] ?? "-"}
                  </div>
                </Td>
                <Td>
                  <div>
                    {DateConvert(new Date(val?.tgl_produksi_aset)).custom}
                  </div>
                  <div>{val.no_produksi_aset ?? "-"}</div>
                </Td>
                <Td>{val?.nama_item ?? "-"}</Td>
                <Td className="text-right">
                  {
                    DecimalConvert(
                      parseFloat(val?.qty_permintaan_produksi ?? 0)
                    ).getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    DecimalConvert(parseFloat(val?.qty_transfer ?? 0))
                      .getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <Pagination
        dataLength={paginationConfig?.[type]?.per_page}
        dataNumber={
          paginationConfig?.[type]?.page * paginationConfig?.[type]?.per_page -
          paginationConfig?.[type]?.per_page +
          1
        }
        dataPage={
          paginationConfig?.[type]?.dataCount <
          paginationConfig?.[type]?.per_page
            ? paginationConfig?.[type]?.dataCount
            : paginationConfig?.[type]?.page *
              paginationConfig?.[type]?.per_page
        }
        dataCount={query?.data?.data_count}
        currentPage={paginationConfig?.[type]?.page}
        totalPage={query?.data?.total_page}
        onPaginationChange={onPaginationChange}
        onDataLengthChange={onPaginationDataLengthChange}
      />

      {modalConfig.show && (
        <ModalDetail
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          tab={checkTab}
        />
      )}
    </>
  );
};
