import React from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { TableKomponenProduksi } from "./TableKomponenProduksi";
import { TableSisaProduksi } from "./TableSisaProduksi";

export const TabKomponenProduksiAset = ({ action, section, title }) => {
  return (
    <div className="mb-4">
      <small className="font-weight-bold">{title}</small>
      <Card className="mt-3">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bahan">Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upah">Upah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alatMesin">Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="subkon">Subkon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overhead">Overhead</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sisaProduksi">Sisa Produksi</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="bahan">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="bahan"
                  section={section}
                  action={action}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="upah">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="upah"
                  section={section}
                  action={action}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="alatMesin">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="alatMesin"
                  section={section}
                  action={action}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="subkon">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="subkon"
                  section={section}
                  action={action}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="overhead">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="overhead"
                  section={section}
                  action={action}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="sisaProduksi">
              <div className="m-2">
                <TableSisaProduksi
                  tab="sisaProduksi"
                  section={section}
                  action={action}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  );
};
