// React
import React, { useState, useEffect } from "react"

// Router
import { useHistory, useParams } from "react-router-dom"

// Components
import { Card, Nav, Tab } from "react-bootstrap"
import { Alert, BackButton, DataStatus } from "components"

// Form
import { Formik } from "formik"

// API
import { JobMixDesignApi } from "api"

// View Components
import { 
  TabGambar, TabFile, TabAnalisa, SummarySection, Body, 
  Divider, TabInfoDetail, TabKonversiDetail, ApprovalSection
} from "./components"

const DetailJobMixDesign = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory()
  const { id_jobmix_design } = useParams()

  // Variables
  const title = "Job Mix Design"

  // States
  const [data, setData] = useState({})
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)
    JobMixDesignApi.single({ id_jobmix_design }).then(single => {
      const dataSingle = single.data.data
      setData({
        ...dataSingle,
        nilai_konversi: parseFloat(dataSingle.nilai_konversi),
        path_gambar: dataSingle.path_gambar.map(val => val.path_gambar)
      })
    }).catch(() => {
      setIsFetchingFailed(true)
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })
    })
    .finally(() => setIsPageLoading(false))
  }

  useEffect(() => {
    setNavbarTitle(title)
    getInitialData()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="mb-2 text-right">
        <BackButton onClick={() => history.goBack()} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            ...data,
            nama_item: data.nama_item_base,
          }}
        >
          {(formik) => {
            const totalSummary = () => {
              return formik.values.analisa.reduce((val, {harga, qty}) => {
                const subtotal = Math.round(parseFloat(qty ?? 0) * parseFloat(harga ?? 0))
                return val + subtotal
              }, 0)
            }

            const summary = [
              {
                buaso: "Bahan",
                total: totalSummary()
              },
              {
                buaso: "Upah",
                total: 0
              },
              {
                buaso: "Alat & Mesin",
                total: 0
              },
              {
                buaso: "Subkon",
                total: 0
              },
              {
                buaso: "Overhead",
                total: 0
              }
            ]
            const total = summary.reduce((prev, { total }) => {
              return prev + total
            }, 0)

            return (
              <form onSubmit={formik.handleSubmit}>
                <Divider>
                  <b>Detail Data {title}</b>
                  <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-4">Konversi Satuan</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-2">Gambar</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-3">File</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="tab-1">
                          <Body>
                            <TabInfoDetail />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-4">
                          <Body>
                            <TabKonversiDetail />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-2">
                          <Body>
                            <TabGambar readOnly />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-3">
                          <Body>
                            <TabFile readOnly />
                          </Body>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <div className="d-flex justify-content-between align-items-end">
                    <b>Data Analisa Barang Jadi</b>
                  </div>
                  <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="analisa-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="analisa-1">
                          <TabAnalisa dropdown={{}} id_buaso='1' nama_buaso='Bahan' readOnly />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>

                <Divider>
                  <ApprovalSection data={data} />
                </Divider>
              </form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default DetailJobMixDesign
