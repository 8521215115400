// React
import React, { useState, useEffect } from "react";

// Router
import { useLocation, useHistory, useParams } from "react-router-dom";

// Components
import { Card, Nav, Tab } from "react-bootstrap";
import { Alert, BackButton, ActionButton, DataStatus } from "components";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { JobMixDesignApi } from "api";

// View Components
import {
  TabInfo,
  TabGambar,
  TabFile,
  TabAnalisa,
  SummarySection,
  Body,
  Divider,
  SalinAnalisa,
  TabKonversi,
} from "./components";
import { DateConvert } from "utilities";
import { mappingData, mappingDataItem } from "./functions";

const TambahJobMixDesign = ({ setNavbarTitle }) => {
  // Hooks
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  // Variables
  const routerState = location.state;
  const title = "Job Mix Design";

  // States
  const [dropdown, setDropdown] = useState({
    item_bahan: [],
    kelompok: [],
    jenis: [],
    satuan: [],
    pabrikan: [],
    barang_jadi: [],
  });
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [data, setData] = useState({
    id_buaso: 1,
    kode_item: "",
    nama_item: "",
    id_satuan: "",
    id_kelompok: "7",
    kode_kelompok: "",
    id_jenis: "",
    kode_jenis: "",
    id_pabrikan: "",
    tgl_input: DateConvert(new Date()).default,
    id_satuan_jual: "",
    id_satuan_beli: "",
    id_satuan_pakai: "",
    link_referensi: "",
    nilai_konversi: "",
    uraian: "",
    tipe: "",
    bisa_diproduksi: true,
    bisa_dijual: true,
    nama_varian: "",
    path_gambar: [],
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([
      id && JobMixDesignApi.item_barang_single(id),
      JobMixDesignApi.dropdown({ tipe: "item_bahan" }),
      JobMixDesignApi.dropdown({ tipe: "kelompok" }),
      JobMixDesignApi.dropdown({ tipe: "jenis" }),
      JobMixDesignApi.dropdown({ tipe: "satuan" }),
      JobMixDesignApi.dropdown({ tipe: "pabrikan" }),
      JobMixDesignApi.dropdown({ tipe: "barang_jadi" }),
    ])
      .then(
        Axios.spread((single, item_bahan, kelompok, jenis, satuan, pabrikan, barang_jadi) => {
          if (id) {
            const dataSingle = single.data.data;
            setData({
              id_buaso: dataSingle.id_buaso,
              id_item_buaso: dataSingle.id_item_buaso,
              kode_item: dataSingle.kode_item,
              nama_item: dataSingle.nama_item,
              id_satuan: dataSingle.id_satuan_pakai,
              id_kelompok: dataSingle.id_kelompok,
              kode_kelompok: kelompok?.data?.data?.[0]?.kode_kelompok ?? "",
              id_jenis: dataSingle.id_jenis,
              kode_jenis: dataSingle.kode_jenis,
              id_pabrikan: dataSingle.id_pabrikan,
              tgl_input: DateConvert(new Date()).default,
              id_satuan_jual: dataSingle.id_satuan_jual,
              id_satuan_beli: dataSingle.id_satuan_beli,
              kode_satuan_beli: dataSingle.kode_satuan_beli,
              id_satuan_pakai: dataSingle.id_satuan_pakai,
              kode_satuan_pakai: dataSingle.kode_satuan_pakai,
              link_referensi: dataSingle.link_referensi,
              nilai_konversi: parseFloat(dataSingle.nilai_konversi),
              uraian: dataSingle.uraian,
              tipe: dataSingle.tipe,
              bisa_diproduksi: dataSingle.bisa_diproduksi,
              bisa_dijual: dataSingle.bisa_dijual,
              nama_varian: dataSingle.nama_varian,
              path_gambar: dataSingle.gambar_file.map((val) => val.path_gambar),
            });
          } else {
            setData({
              ...data,
              kode_kelompok: kelompok?.data?.data?.[0]?.kode_kelompok ?? "",
            });
          }

          setDropdown({
            item_bahan: mappingDataItem(item_bahan, "item_buaso"),
            kelompok: mappingData(kelompok, "kelompok"),
            jenis: mappingData(jenis, "jenis"),
            satuan: mappingData(satuan, "satuan"),
            pabrikan: mappingData(pabrikan, "pabrikan"),
            barang_jadi: barang_jadi.data.data.map((val) => {
              return {
                value: val.id_item_atribut,
                label: val.nama_item,
              };
            }),
          });
        })
      )
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mb-2 text-right">
        <BackButton onClick={() => history.push("/master/job-mix-design", { ...routerState })} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
      ) : (
        <Formik
          initialValues={{
            ...data,
            id_buaso: 1,
            analisa: [],
          }}
          validationSchema={Yup.object().shape({
            nama_item: Yup.string()
              .required("Nama Barang Jadi wajib diisi")
              .test({
                name: "nama_item",
                message: "Nama Barang Jadi & Nama Varian telah digunakan",
                test: (_value, all) => {
                  const nama_item = all?.parent?.nama_item ?? "";
                  const nama_varian = all?.parent?.nama_varian ?? "";

                  return JobMixDesignApi.cek_nama({ nama_item, nama_varian })
                    .then(() => {
                      return true;
                    })
                    .catch(() => {
                      return false;
                    });
                },
              }),
            id_satuan: Yup.string().required("Satuan Pakai wajib diisi"),
            id_jenis: Yup.string().required("Jenis Barang Jadi wajib diisi"),
            id_pabrikan: Yup.string().required("Pabrikan wajib diisi"),
            id_satuan_jual: Yup.string().required("Satuan Jual wajib diisi"),
            id_satuan_beli: Yup.string().required("Satuan Beli wajib diisi"),
          })}
          onSubmit={(value) => {
            JobMixDesignApi.create(value)
              .then(() => {
                history.push("/master/job-mix-design", {
                  ...routerState,
                  alert: {
                    show: true,
                    variant: "primary",
                    text: "Data berhasil ditambah!",
                  },
                });
              })
              .catch(() =>
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: "Data gagal ditambah!",
                })
              );
          }}
        >
          {(formik) => {
            const totalSummary = () => {
              return formik.values.analisa.reduce((val, { harga, qty }) => {
                const subtotal = Math.round(parseFloat(qty ?? 0) * parseFloat(harga ?? 0));
                return val + subtotal;
              }, 0);
            };

            const summary = [
              {
                buaso: "Bahan",
                total: totalSummary(),
              },
              {
                buaso: "Upah",
                total: 0,
              },
              {
                buaso: "Alat & Mesin",
                total: 0,
              },
              {
                buaso: "Subkon",
                total: 0,
              },
              {
                buaso: "Overhead",
                total: 0,
              },
            ];
            const total = summary.reduce((prev, { total }) => {
              return prev + total;
            }, 0);

            return (
              <form onSubmit={formik.handleSubmit}>
                <Divider>
                  <b>Tambah Data {title}</b>
                  <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-4">Konversi Satuan</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-2">Gambar</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-3">File</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="tab-1">
                          <Body>
                            <TabInfo dropdown={dropdown} />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-4">
                          <Body>
                            <TabKonversi />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-2">
                          <Body>
                            <TabGambar />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-3">
                          <Body>
                            <TabFile />
                          </Body>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <div className="d-flex justify-content-between align-items-end">
                    <b>Data Analisa Barang Jadi</b>

                    <SalinAnalisa dropdown={dropdown} />
                  </div>
                  <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="analisa-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="analisa-1">
                          <TabAnalisa dropdown={dropdown} id_buaso="1" nama_buaso="Bahan" />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>

                <div className="d-flex justify-content-end align-items-center mt-3">
                  <ActionButton
                    variant="primary"
                    text="Simpan"
                    type="submit"
                    loading={formik.isSubmitting}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default TambahJobMixDesign;
