// React
import { useState } from 'react'

// Component
import { DataStatus, Table, DeleteButton, SelectSearch } from 'components'
import { ActionButton } from 'pages/Master/Pabrik/ViewComponent'

// Icon
import { IoAddOutline } from 'react-icons/io5'

// Cleave
import Cleave from "cleave.js/react"

const TableWaste = ({ dataWaste, setDataWaste, dataAtribut, tipe }) => {
	const [values, setValues] = useState({
		id_item_buaso: "",
		nama_item: "",
		kode_item: "",
		nama_satuan: "",
		qty_realisasi_waste: "",
	})

	const Th = props => (
		<th
			{...props}
			className={`${props.className} align-middle`}
			style={{
				...props.style,
				padding: 0,
				fontSize: "14px"
			}}
		>
			{props.children}
		</th>
	)

	const Td = props => (
		<td
			{...props}
			className={`${props.className} p-1`}
			style={{
				...props.style,
				fontSize: "13px"
			}}
		>
			{props.children}
		</td>
	)

	const submitData = () => {
		if (Object.values(values).every(field => field.length > 0)) {
			setDataWaste([
				...dataWaste,
				values
			])
		}
	}

	const deleteData = index => setDataWaste(dataWaste.filter((val, id) => id !== index))

	return (
		<>
			<div className="pb-2">
				<b className="p-1">List Item Waste</b>
			</div>
			<Table>
				<thead className="bg-light">
					<tr>
						<Th className="py-2 text-center" style={{ width: 40 }}>No.</Th>
						<Th className="p-2 text-center" style={{ width: 80 }}>Kode Waste</Th>
						<Th className="p-2 text-center" style={{ width: 400 }}>Item Waste</Th>
						<Th className="p-1 text-center" style={{ width: 70 }}>Qty. Waste</Th>
						<Th className="p-0 text-center" style={{ width: 70 }}>Satuan</Th>
						{tipe === 'realisasi' && (
							<Th className="p-0 text-center" style={{ width: 40 }}>Aksi</Th>
						)}
					</tr>
				</thead>
				<tbody>
					{tipe === 'realisasi' && (
						<tr>
							<Td></Td>
							<Td colSpan="2">
								<SelectSearch
									noMargin
									label=""
									placeholder="Pilih Item"
									onChange={(val) => setValues({
										...values,
										id_item_buaso: val.value,
										nama_item: val.label,
										kode_item: val.kode_item,
										nama_satuan: val.nama_satuan,
									})}
									option={dataAtribut.waste.map((val) => {
										return {
											value: val.id_item_buaso,
											label: val.nama_item,
											kode_item: val.kode_item,
											nama_satuan: val.nama_satuan,
										}
									})}
									defaultValue={values.id_item_buaso !== "" ? {
										value: values.id_item_buaso,
										label: values.nama_item
									} : ""}
								/>
							</Td>
							<Td>
								<Cleave
									id="qty_waste"
									options={{
										numeral: true,
										numeralThousandsGroupStyle: "thousand"
									}}
									value={values.qty_realisasi_waste}
									onChange={e => {
										const update = new Promise((resolve) => {
											setValues({
												...values,
												qty_realisasi_waste: e.target.rawValue
											})
											resolve()
										})

										update
											.then(() => document.getElementById(`qty_waste`).focus())
									}}
									className="form-control form-control-sm"
								/>
							</Td>
							<Td></Td>
							<Td className='text-center'>
								<ActionButton
									type="submit"
									variant="primary"
									onClick={submitData}
									size="sm"
									iconOnly
								>
									<IoAddOutline size={18} />
								</ActionButton>
							</Td>
						</tr>
					)}
					{dataWaste.length > 0
						? dataWaste.map((val, index) => {
							return (
								<tr key={index}>
									<Td className="text-center">{index + 1}</Td>
									<Td>{val.kode_item}</Td>
									<Td>{val.nama_item}</Td>
									<Td className="text-right">{parseFloat(val.qty_realisasi_waste ?? 0)}</Td>
									<Td>{val.nama_satuan}</Td>
									{tipe === 'realisasi' && (
										<Td className='text-center'>
											<DeleteButton size='sm' onClick={() => deleteData(index)} />
										</Td>
									)}
								</tr>
							)
						})
						: (
							<tr>
								<Td colSpan={tipe === 'realisasi' ? 6 : 5}>
									<DataStatus text="Tidak ada data" />
								</Td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</>
	)
}

export default TableWaste