import { useEffect, useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { dateConvert } from 'utilities2';
import { DataStatus } from 'components';
import { ChartBarVertical } from 'components2';
import DashboardApi from 'api/Dashboard';

export const InfoBarangTotalProduksi = ({
  monthOptions,
  startMonth,
  endMonth,
  year,
}) => {
  const date = dateConvert();
  const monthValue = startMonth; // Angka bulan (contoh: 10 untuk Oktober)
  const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
  const dayOfMonth = date.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

  const todayDate = date.getDetailMonth(
    new Date(currentYear, monthValue - 1, dayOfMonth),
  );

  const [selectedMonth, setSelectedMonth] = useState({
    label: '',
    value: startMonth,
  });

  // function get month range
  function getMonthsBetweenStartAndEnd(
    startMonthValue,
    endMonthValue,
    monthOptions,
  ) {
    const filteredMonths = monthOptions.filter(
      (val) => val.value >= startMonthValue && val.value <= endMonthValue,
    );

    return filteredMonths;
  }

  // navbar month name
  const monthsInRange = getMonthsBetweenStartAndEnd(
    startMonth,
    endMonth,
    monthOptions,
  );

  // fetchApi
  const getProduksiPerbulan = useQuery(
    ['dashboard', 'produksi_perbulan', year, selectedMonth],
    () =>
      DashboardApi.getProduksiPerbulan({
        tahun: year,
        bulan: selectedMonth?.value,
      }),
  );

  useEffect(() => {
    if (startMonth) {
      setSelectedMonth({
        label: todayDate,
        value: startMonth,
      });
    }
  }, [startMonth, todayDate]);

  return (
    <Card className="border">
      {monthsInRange?.length ? (
        <Tab.Container
          key={monthsInRange.join('-')}
          defaultActiveKey={monthsInRange[0].value}
        >
          <Card.Header className='className="d-flex justify-content-between align-items-center'>
            <Nav variant="tabs">
              {monthsInRange?.map((month) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={month.value}
                    onClick={() =>
                      setSelectedMonth({
                        label: month.label,
                        value: month.value,
                      })
                    }
                  >
                    {month.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              {getProduksiPerbulan.isFetching ? (
                <div>Memuat Data . . .</div>
              ) : (
                <>
                  <div className="text-center mb-2">
                    <b>
                      Total Produksi Per Bulan{' '}
                      {` ${selectedMonth.label} ${year}`}
                    </b>
                  </div>
                  <ChartBarVertical
                    showLegend={false}
                    data={{
                      labels: getProduksiPerbulan.data?.data?.map(
                        (val) => val?.nama_jenis ?? '-',
                      ),
                      datasets: [
                        {
                          data: getProduksiPerbulan.data?.data?.map(
                            (val) => val?.total_produksi ?? 0,
                          ),
                          maxBarThickness: 30,
                        },
                      ],
                    }}
                    options={{
                      elements: {
                        bar: {
                          borderWidth: 2,
                        },
                      },
                      legend: {
                        display: false,
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: function (tooltipItem) {
                              return tooltipItem.parsed.y;
                            },
                          },
                        },
                      },
                    }}
                  />
                </>
              )}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </Card>
  );
};
