export const formSubmitValueMapper = (data = {}) => ({
  tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset,
  id_produksi_aset: data?.id_produksi_aset,
  progress: data?.progress,
  bahan: data?.transfer?.bahan
    ?.filter((val) => parseFloat(val?.qty_realisasi) > 0)
    .map((val) => ({
      id_transfer_produksi_aset_bahan: val?.id_transfer_produksi_aset,
      qty_realisasi: val?.qty_realisasi,
      id_item_bahan: val?.id_item,
      id_satuan: val?.id_satuan,
    })),
  upah: data?.transfer?.upah
    ?.filter((val) => parseFloat(val?.qty_realisasi) > 0)
    .map((val) => ({
      id_transfer_produksi_aset_upah: val?.id_transfer_produksi_aset,
      qty_realisasi: val?.qty_realisasi,
      id_item_upah: val?.id_item,
    })),
  alat_mesin: data?.transfer?.alatMesin
    ?.filter((val) => parseFloat(val?.qty_realisasi) > 0)
    .map((val) => ({
      id_transfer_produksi_aset_alat_mesin: val?.id_transfer_produksi_aset,
      qty_realisasi: val?.qty_realisasi,
      id_item_alat_mesin: val?.id_item,
    })),
  subkon: data?.transfer?.subkon
    ?.filter((val) => parseFloat(val?.qty_realisasi) > 0)
    .map((val) => ({
      id_transfer_produksi_aset_subkon: val?.id_transfer_produksi_aset,
      qty_realisasi: val?.qty_realisasi,
      id_item_subkon: val?.id_item,
    })),
  overhead: data?.transfer?.overhead
    ?.filter((val) => parseFloat(val?.qty_realisasi) > 0)
    .map((val) => ({
      id_transfer_produksi_aset_overhead: val?.id_transfer_produksi_aset,
      qty_realisasi: val?.qty_realisasi,
      id_item_overhead: val?.id_item,
    })),
  sisa_produksi: data?.transfer?.sisaProduksi
    ?.filter((val) => parseFloat(val?.qty) > 0)
    .map((val) => ({
      id_item_buaso: val?.id_item,
      qty_realisasi: val?.qty,
      id_satuan: val?.id_satuan,
      id_gudang: val?.id_gudang,
    })),
});

export const formSubmitValueMapperRegisterAset = (data = {}) => ({
  id_produksi_aset: data?.id_produksi_aset,
  id_entitas_aset: data?.id_entitas_aset,
  id_grup_aset: data?.id_grup_aset,
  id_kategori_aset: data?.id_kategori_aset,
  id_jenis_aset: data?.id_jenis_aset,
  id_unit_organisasi: data?.id_unit_organisasi,
  harga_perolehan: data?.harga_perolehan,
  id_penerimaan_aset: data?.id_penerimaan_aset,
  id_kondisi_aset: data?.id_kondisi_aset,
  id_karyawan: data?.id_karyawan,
  id_provinsi: data?.id_provinsi,
  id_kabupaten: data?.id_kabupaten,
  id_kecamatan: data?.id_kecamatan,
  id_desa: data?.id_desa,
  detail_lokasi: data?.detail_lokasi,
  bulan_perolehan: data?.bulan_perolehan,
  tahun_perolehan: data?.tahun_perolehan,
  id_satuan_pakai: data?.id_satuan_pakai,
  id_pabrikan: data?.id_pabrikan,
  no_sertifikat: data?.no_sertifikat,
  disewa: data?.disewa,
  produksi: data?.produksi,
  nama_item_aset: data?.nama_item_aset,
  kode_item_aset: data?.kode_item_aset,
  plat_nomor: data?.plat_nomor,
  no_seri: data?.no_seri,
  keterangan: data?.keterangan,
  qr_code: data?.qr_code ?? "",
  barcode: data?.barcode ?? "",
});
