// React
import { Fragment, useEffect, useState } from "react";

// Router
import { useLocation, useParams, useHistory } from "react-router-dom";

// API
import axios from "axios";
import { JobMixApi } from "api";

// Components
import { ActionButton, BackButton, DataStatus, PopUpAlert } from "components";
import { Card, Form, Nav, Tab } from "react-bootstrap";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// View Components
import {
  InfoFormSection,
  ProduksiSection,
  TabAnalisa,
  SummarySection,
  Divider,
} from "./components";

// View Functions
import { dataSummary, mappingFlag, totalDataSummary } from "./functions";

const UbahJobMix = ({ setNavbarTitle }) => {
  // Hooks
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  // Variables
  const routerState = location.state;

  // States
  const [page, setPage] = useState({
    loading: true,
    status: true,
  });
  const [data, setData] = useState({});
  const [dropdown, setDropdown] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: "",
    title: "",
    text: "",
    hide: () => setAlertConfig({ ...alertConfig, show: false }),
  });

  useEffect(() => {
    setNavbarTitle("Produksi");

    axios
      .all([
        JobMixApi.getSingle({ id_jobmix: id }),
        JobMixApi.dropdown({ tipe: "bahan" }),
        JobMixApi.dropdown({ tipe: "upah" }),
        JobMixApi.dropdown({ tipe: "aset" }),
        JobMixApi.dropdown({ tipe: "subkon" }),
        JobMixApi.dropdown({ tipe: "overhead" }),
      ])
      .then(
        axios.spread((single, bahan, upah, alat_mesin, subkon, overhead) => {
          const dataSingle = single.data.data;

          setData({
            ...dataSingle,
            analisa_bahan: mappingFlag(
              dataSingle,
              "analisa_bahan",
              dataSingle.qty_jobmix
            ),
            analisa_upah: mappingFlag(
              dataSingle,
              "analisa_upah",
              dataSingle.qty_jobmix
            ),
            analisa_alat_mesin: mappingFlag(
              dataSingle,
              "analisa_alat_mesin",
              dataSingle.qty_jobmix
            ),
            analisa_subcont: mappingFlag(
              dataSingle,
              "analisa_subcont",
              dataSingle.qty_jobmix
            ),
            analisa_overhead: mappingFlag(
              dataSingle,
              "analisa_overhead",
              dataSingle.qty_jobmix
            ),
          });

          setDropdown({
            bahan: bahan.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
            upah: upah.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
            alat_mesin: alat_mesin.data.data.map((val) => {
              return {
                ...val,
                id_item_buaso: val.id_item_aset,
                value: val.id_item_aset,
                label: val.nama_item,
              };
            }),
            subkon: subkon.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
            overhead: overhead.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
          });

          setPage({
            loading: false,
            status: true,
          });
        })
      )
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });

    // eslint-disable-next-line
  }, []);

  if (page.loading || !page.status) {
    return (
      <DataStatus
        loading={page.loading}
        text={
          !page.status
            ? "Data gagal dimuat, tidak dapat menampilkan data"
            : "Memuat data..."
        }
      />
    );
  } else {
    return (
      <Fragment>
        <div className="text-right">
          <BackButton
            size="sm"
            onClick={() =>
              history.push("/transaksi/job-mix", { ...routerState })
            }
          />
        </div>

        <Formik
          initialValues={{
            ...data,
            nomor: data.no_jobmix ?? "",
          }}
          validationSchema={Yup.object().shape({
            tgl_jobmix: Yup.string().required("Tanggal Produksi wajib diisi"),
            id_gudang: Yup.string().required("Pilih Gudang Produksi"),
            qty_jobmix: Yup.number()
              .required("Qty. Produksi wajib diisi")
              .max(
                parseInt(data.qty_item ?? 0) -
                  parseInt(data.qty_sedang_diproduksi ?? 0) +
                  parseInt(data.qty_diproduksi ?? 0),
                `Qty Tidak boleh lebih dari ${
                  parseInt(data.qty_item ?? 0) -
                  parseInt(data.qty_sedang_diproduksi ?? 0) +
                  parseInt(data.qty_diproduksi ?? 0)
                }`
              )
              .min(1, "Qty Tidak boleh kurang dari 1")
              .nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            JobMixApi.save(values)
              .then(() => {
                setAlertConfig({
                  ...alertConfig,
                  show: true,
                  type: "success",
                  title: "Ubah Produksi Berhasil",
                  text: "Data berhasil diubah!",
                  hide: () =>
                    history.push("/transaksi/job-mix", {
                      pagination: {
                        dataCount: routerState?.pagination?.dataCount,
                        dataLength: routerState?.pagination?.dataLength,
                        page: 1,
                        totalPage: routerState?.pagination?.totalPage,
                      },
                    }),
                });
              })
              .catch(() => {
                setAlertConfig({
                  ...alertConfig,
                  show: true,
                  type: "danger",
                  title: "Ubah Produksi Gagal",
                  text: "Data gagal diubah!",
                  hide: () =>
                    setAlertConfig({
                      ...alertConfig,
                      show: false,
                    }),
                });
              })
              .finally(() => setSubmitting(false));
          }}>
          {({ handleSubmit, isSubmitting, values }) => {
            // Variables
            const summary = dataSummary(values);
            const total = totalDataSummary(summary);

            return (
              <Form onSubmit={handleSubmit}>
                <Divider>
                  <InfoFormSection data={data} />
                </Divider>

                <Divider>
                  <ProduksiSection title="Komponen Utama">
                    <Card>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="analisa-1">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-3">
                                Alat & Mesin
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="1"
                              nama_buaso="bahan"
                              label="Bahan"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-2">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="2"
                              nama_buaso="upah"
                              label="upah"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-3">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="3"
                              nama_buaso="alat_mesin"
                              label="Alat & Mesin"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-4">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="4"
                              nama_buaso="subcont"
                              label="Subkon"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-5">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="5"
                              nama_buaso="overhead"
                              label="Overhead"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </ProduksiSection>
                </Divider>

                <Divider>
                  <ProduksiSection title="Komponen Tambahan">
                    <Card>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="analisa-1">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-3">
                                Alat & Mesin
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="1"
                              nama_buaso="bahan"
                              label="Bahan"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-2">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="2"
                              nama_buaso="upah"
                              label="upah"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-3">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="3"
                              nama_buaso="alat_mesin"
                              label="Alat & Mesin"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-4">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="4"
                              nama_buaso="subcont"
                              label="Subkon"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-5">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="5"
                              nama_buaso="overhead"
                              label="Overhead"
                              flag="TB"
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </ProduksiSection>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>

                <div className="d-flex justify-content-end align-items-center mt-3">
                  <ActionButton
                    variant="primary"
                    text="Simpan"
                    type="submit"
                    loading={isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>

        <PopUpAlert
          show={alertConfig.show}
          onConfirm={alertConfig.hide}
          onHide={alertConfig.hide}
          title={alertConfig.title}
          text={alertConfig.text}
          type={alertConfig.type}
        />
      </Fragment>
    );
  }
};

export default UbahJobMix;
