import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { JobMixApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataBarangJadi, setDataBarangJadi] = useState([{ label: "Semua", value: undefined }]);
  const [dataStatusProgress, setDataBarangJdataStatusProgress] = useState([
    { label: "Semua", value: undefined },
    { label: "ON PROGRESS", value: "on progress" },
    { label: "CLOSED", value: "closed" },
  ]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([JobMixApi.dropdown({ tipe: "barang_jadi" })])
      .then(
        Axios.spread((barang) => {
          const mapDataBarangJAdi = barang?.data?.data
            ? barang.data.data.map((val) => ({
                label: val?.nama_barang_jadi,
                value: val?.id_barang_jadi,
              }))
            : [];

          setDataBarangJadi([...dataBarangJadi, ...mapDataBarangJAdi]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_mulai_close_produksi: data?.filter?.tgl_mulai_close_produksi,
    tgl_selesai_close_produksi: data?.filter?.tgl_selesai_close_produksi,
    tgl_mulai_produksi: data?.filter?.tgl_mulai_produksi,
    tgl_selesai_produksi: data?.filter?.tgl_selesai_produksi,
    tgl_mulai_sales_order: data?.filter?.tgl_mulai_sales_order,
    tgl_selesai_sales_order: data?.filter?.tgl_selesai_sales_order,
    barang_jadi: data?.filter?.barang_jadi,
  };
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalCloseProduksiChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_mulai_close_produksi: startDate,
      tgl_selesai_close_produksi: endDate,
    });
  };
  const onTanggalProduksiChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_mulai_produksi: startDate,
      tgl_selesai_produksi: endDate,
    });
  };
  const onTanggalSOChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_mulai_sales_order: startDate,
      tgl_selesai_sales_order: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_mulai_close_produksi: undefined,
      tgl_selesai_close_produksi: undefined,
      tgl_mulai_produksi: undefined,
      tgl_selesai_produksi: undefined,
      tgl_mulai_sales_order: undefined,
      tgl_selesai_sales_order: undefined,
      barang_jadi: undefined,
    });
  };

  return (
    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Close Produksi"
            placeholderText="Pilih Tanggal Close Produksi"
            startDate={
              values.tgl_mulai_close_produksi ? new Date(values.tgl_mulai_close_produksi) : ""
            }
            endDate={
              values.tgl_selesai_close_produksi ? new Date(values.tgl_selesai_close_produksi) : ""
            }
            onChange={(dates) => onTanggalCloseProduksiChange(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Produksi"
            placeholderText="Pilih Tanggal Produksi"
            startDate={values.tgl_mulai_produksi ? new Date(values.tgl_mulai_produksi) : ""}
            endDate={values.tgl_selesai_produksi ? new Date(values.tgl_selesai_produksi) : ""}
            onChange={(dates) => onTanggalProduksiChange(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Sales Order"
            placeholderText="Pilih Tanggal Sales Order"
            startDate={values.tgl_mulai_sales_order ? new Date(values.tgl_mulai_sales_order) : ""}
            endDate={values.tgl_selesai_sales_order ? new Date(values.tgl_selesai_sales_order) : ""}
            onChange={(dates) => onTanggalSOChange(dates, values, setValues)}
            monthsShown={2}
          />

          <SelectSearch
            key={values.barang_jadi}
            label="Item Produksi"
            placeholder="Pilih Item Produksi"
            defaultValue={dataBarangJadi.find((val) => val.value === values.barang_jadi)}
            option={dataBarangJadi}
            onChange={(val) => setFieldValue("barang_jadi", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
