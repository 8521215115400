// React
import { useEffect, useState } from "react";

// Form
import { DatePicker, InfoItemVertical, Input, NumberFormat, SelectSearch } from "components";
import { useFormikContext } from "formik";

// Components
import { Card, Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

// API
import { JobMixApi } from "api";
import axios from "axios";

// View Functions
import { generateStatusApproval, mappingFlag, mappingQty } from "../../functions";
import { mappingData } from "pages/Master/JobMixDesign/functions";

const InfoFormSection = ({ data, isCreate, readOnly, noSO }) => {
  // Hooks
  const { values, errors, touched, setFieldValue, setValues } = useFormikContext();

  // States
  const [loading, setLoading] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({
    barang_jadi: [],
    gudang: [],
  });
  const [loadingDropdown, setLoadingDropdown] = useState(false);

  const getNomorHandler = (date) => {
    setLoading(true);

    JobMixApi.getNomor({
      tanggal: date,
    })
      .then(({ data }) => {
        setFieldValue("nomor", data.data);
      })
      .catch(() => {
        window.alert("Nomor gagal dimuat!");
        setFieldValue("nomor", "");
      })
      .finally(() => setLoading(false));
  };

  const getDataAnalisa = (val) => {
    JobMixApi.getSingle({ id_item_atribut: val.id_item_atribut }).then((res) => {
      const dataSingle = res.data.data;

      setValues({
        ...values,
        id_item_buaso: val.value,
        kode_satuan: val.kode_satuan ?? "",
        analisa_bahan: mappingFlag(dataSingle, "analisa_bahan", values.qty_jobmix),
        analisa_upah: mappingFlag(dataSingle, "analisa_upah", values.qty_jobmix),
        analisa_alat_mesin: mappingFlag(dataSingle, "analisa_alat_mesin", values.qty_jobmix),
        analisa_subcont: mappingFlag(dataSingle, "analisa_subcont", values.qty_jobmix),
        analisa_overhead: mappingFlag(dataSingle, "analisa_overhead", values.qty_jobmix),
      });
    });
  };

  const setQtyAnalisa = (value) => {
    setValues({
      ...values,
      qty_jobmix: value,
      analisa_bahan: mappingQty(values, "analisa_bahan", value),
      analisa_upah: mappingQty(values, "analisa_upah", value),
      analisa_alat_mesin: mappingQty(values, "analisa_alat_mesin", value),
      analisa_subcont: mappingQty(values, "analisa_subcont", value),
      analisa_overhead: mappingQty(values, "analisa_overhead", value),
    });
  };

  useEffect(() => {
    setLoadingDropdown(true);

    axios
      .all([JobMixApi.dropdown({ tipe: "barang_jadi" }), JobMixApi.dropdown({ tipe: "d_gudang" })])
      .then(
        axios.spread((res, gudang) => {
          setDataDropdown({
            barang_jadi: mappingData(res, "barang_jadi"),
            gudang: mappingData(gudang, "gudang"),
          });
        })
      )
      .finally(() => setLoadingDropdown(false));

    if (isCreate) {
      setQtyAnalisa(
        parseFloat(data.qty_item ?? 0) -
          parseFloat(data.qty_sedang_diproduksi ?? 0) +
          parseFloat(data.qty_diproduksi ?? 0)
      );
    }

    // eslint-disable-next-line
  }, []);

  const ListItem = ({ label, text }) => (
    <tr>
      <td style={{ fontSize: 14 }} className="text-nowrap">
        {label}
      </td>
      <td style={{ fontSize: 14 }} className="px-1">
        :
      </td>
      <td style={{ fontSize: 14 }}>{text}</td>
    </tr>
  );

  return (
    <>
      <b>Informasi Umum</b>

      <Card>
        <Card.Body>
          {!noSO ? (
            <Row>
              <Col md>
                <table>
                  <tbody>
                    <ListItem
                      label="Tgl. Sales Order"
                      text={
                        data.tgl_sales_order
                          ? DateConvert(new Date(data.tgl_sales_order)).detail
                          : "-"
                      }
                    />
                    <ListItem label="No. Sales Order" text={data.no_sales_order ?? "-"} />
                    <ListItem
                      label="Barang Jadi"
                      text={isCreate ? data.nama_barang ?? "-" : data.nama_item ?? "-"}
                    />
                    <ListItem label="Customer" text={data.nama_customer ?? "-"} />
                  </tbody>
                </table>
              </Col>

              <Col md>
                <table>
                  <tbody>
                    <ListItem
                      label="Qty. Sales Order"
                      text={`${data.qty_item ? parseFloat(data.qty_item).toPrecision() : "-"} ${
                        data.kode_satuan ?? ""
                      }`}
                    />
                    <ListItem
                      label="Qty. Sedang Diproduksi"
                      text={`${
                        data.qty_sedang_diproduksi ? parseFloat(data.qty_sedang_diproduksi) : "-"
                      } ${data.kode_satuan ?? ""}`}
                    />
                    <ListItem
                      label="Qty. Telah Direalisasi"
                      text={
                        isCreate
                          ? `${data.qty_diproduksi ? Math.round(data.qty_diproduksi) : "-"} ${
                              data.kode_satuan ?? ""
                            }`
                          : `${data.qty_produksi ? Math.round(data.qty_produksi) : "-"} ${
                              data.kode_satuan ?? ""
                            }`
                      }
                    />
                    {readOnly && (
                      <ListItem label="Gudang Produksi" text={data.nama_gudang ?? "-"} />
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : (
            <SelectSearch
              label="Item Barang Yang Akan Diproduksi"
              placeholder="Pilih Item Barang"
              onChange={(val) => getDataAnalisa(val)}
              option={dataDropdown.barang_jadi}
              defaultValue={
                values.id_item_buaso &&
                dataDropdown.barang_jadi.find((val) => val.value === values.id_item_buaso)
              }
              error={errors.id_item_buaso && touched.id_item_buaso && true}
              errorText={errors.id_item_buaso && touched.id_item_buaso && errors.id_item_buaso}
              loading={loadingDropdown}
            />
          )}

          <hr />

          {readOnly ? (
            <Row>
              <Col md={6} lg>
                <InfoItemVertical
                  label="Tgl. Produksi"
                  text={DateConvert(new Date(data.tgl_jobmix)).detail}
                />
              </Col>
              <Col md={6} lg>
                <InfoItemVertical label="No. Produksi" text={data.no_jobmix} />
              </Col>
              <Col md={6} lg>
                <InfoItemVertical
                  label="Qty. Produksi"
                  text={`${data.qty_jobmix} ${data.kode_satuan}`}
                />
              </Col>
              <Col md={6} lg>
                <InfoItemVertical
                  label="Status Progress"
                  text={
                    <div className={generateStatusApproval(data).textColor}>
                      {generateStatusApproval(data).label}
                    </div>
                  }
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={6} lg>
                <DatePicker
                  label="Tgl. Produksi"
                  placeholderText="Pilih tanggal"
                  selected={values.tgl_jobmix ? new Date(values.tgl_jobmix) : ""}
                  onChange={(date) => {
                    setFieldValue("tgl_jobmix", DateConvert(date).default);
                    getNomorHandler(DateConvert(date).default);
                  }}
                  error={Boolean(errors.tgl_jobmix && touched.tgl_jobmix)}
                  errorText={Boolean(errors.tgl_jobmix && touched.tgl_jobmix) && errors.tgl_jobmix}
                />
              </Col>
              <Col md={6} lg>
                <Input
                  readOnly
                  label="Nomor Produksi"
                  placeholder="Pilih tanggal untuk menentukan nomor"
                  value={loading ? "Memuat nomor . . ." : values.nomor}
                  error={Boolean(errors.nomor && touched.nomor)}
                  errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
                />
              </Col>
              {console.log("cek", values.qty_jobmix)}
              <Col md={6} lg>
                <NumberFormat
                  label="Qty. Produksi"
                  placeholder="Qty. Produksi"
                  value={values.qty_jobmix ? values.qty_jobmix : ""}
                  onChange={(value) => setQtyAnalisa(value)}
                  thousandSeparator={false}
                  error={Boolean(errors.qty_jobmix && touched.qty_jobmix)}
                  errorText={Boolean(errors.qty_jobmix && touched.qty_jobmix) && errors.qty_jobmix}
                />
              </Col>
              <Col md={6} lg>
                <Input readOnly label="Satuan" placeholder="-" value={values.kode_satuan} />
              </Col>
              <Col md={6} lg>
                <SelectSearch
                  label="Gudang Produksi"
                  placeholder="Pilih Gudang Produksi"
                  onChange={(val) => setFieldValue("id_gudang", val.value)}
                  option={dataDropdown.gudang}
                  defaultValue={
                    values.id_gudang &&
                    dataDropdown.gudang.find((val) => val.value === values.id_gudang)
                  }
                  error={errors.id_gudang && touched.id_gudang && true}
                  errorText={errors.id_gudang && touched.id_gudang && errors.id_gudang}
                  loading={loadingDropdown}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default InfoFormSection;
