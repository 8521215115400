// React
import { useState } from "react";

// Components
import {
  ActionButton,
  DataStatus,
  NumberFormat,
  SelectSearch,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import { DecimalConvert, RupiahConvert } from "utilities";
import { ButtonGroup } from "react-bootstrap";

// Form
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";

// Icons
import {
  IoAddOutline,
  IoPencilOutline,
  IoTrashBinOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from "react-icons/io5";

const TabAnalisa = ({
  dropdown,
  readOnly,
  id_buaso,
  nama_buaso,
  label,
  flag,
  detail,
}) => {
  // Variables
  let no = 0;

  // Hooks
  const { values, setFieldValue } = useFormikContext();

  // States
  const [editIndex, setEditIndex] = useState(null);

  const FormSection = ({ value, isEdit }) => {
    // Hooks
    const formik = useFormik({
      initialValues: {
        id_item_buaso:
          id_buaso === 3
            ? value.id_item_buaso ?? ""
            : value.id_item_buaso ?? "",
        [`id_jobmix_${nama_buaso}`]:
          value[`id_jobmix_${nama_buaso}`] ?? undefined,
        nama_item: value.nama_item ?? "",
        qty_produksi: value.qty_produksi ? parseFloat(value.qty_produksi) : "",
        harga:
          id_buaso === 3
            ? value.biaya_penyusutan_per_jam ?? ""
            : value[`harga_satuan_${nama_buaso}`] ?? "",
        kode_satuan: value.kode_satuan ?? "",
        flag,
      },
      validationSchema: Yup.object().shape({
        id_item_buaso: Yup.string().required(),
        qty_produksi: Yup.number().min(0).required(),
      }),
      onSubmit: (value) => {
        const analisa = values[`analisa_${nama_buaso}`];
        const dataValue = {
          ...value,
          [id_buaso === "3" ? "id_item_aset" : "id_item_buaso"]:
            value.id_item_buaso,
          [id_buaso === "3"
            ? "biaya_penyusutan_per_jam"
            : `harga_satuan_${nama_buaso}`]: value.harga,
        };

        if (isEdit) {
          analisa.splice(editIndex, 1, dataValue);

          setFieldValue(`analisa_${nama_buaso}`, analisa);
          setEditIndex(null);
        } else {
          analisa.push(dataValue);

          setFieldValue(`analisa_${nama_buaso}`, analisa);
        }
      },
    });

    // Variables
    const harga = formik.values.harga !== "" ? formik.values.harga : 0;

    const qty_produksi =
      formik.values.qty_produksi !== "" ? formik.values.qty_produksi : 0;

    const dataAtribut = {
      option: dropdown[nama_buaso === "subcont" ? "subkon" : nama_buaso],
      defaultValue: formik.values.id_item_buaso
        ? dropdown[nama_buaso === "subcont" ? "subkon" : nama_buaso].find(
            (item) => item.value === formik.values.id_item_buaso
          )
        : "",
    };

    return (
      <tr>
        <Td />
        <Td>
          <SelectSearch
            noMargin
            label=""
            placeholder={`Pilih Item ${label}`}
            onChange={(val) =>
              formik.setValues({
                ...formik.values,
                id_item_buaso: val.value,
                nama_item: val.nama_item,
                harga: val.harga,
                kode_satuan: val.kode_satuan,
              })
            }
            option={dataAtribut.option}
            defaultValue={dataAtribut.defaultValue}
            error={Boolean(
              formik.errors.id_item_buaso && formik.touched.id_item_buaso
            )}
          />
        </Td>
        <Td>
          <NumberFormat
            noMargin
            label=""
            placeholder="Qty"
            name="qty_produksi"
            value={formik.values.qty_produksi}
            onChange={(value) =>
              formik.setFieldValue("qty_produksi", value ?? "")
            }
            error={Boolean(
              formik.errors.qty_produksi && formik.touched.qty_produksi
            )}
          />
        </Td>
        <Td className="text-nowrap">{formik.values.kode_satuan}</Td>
        <Td className="text-nowrap" textRight>
          {RupiahConvert(String(parseFloat(harga))).getWithComa}
        </Td>
        <Td className="text-nowrap" textRight>
          {
            RupiahConvert(
              String(parseFloat(parseFloat(qty_produksi) * parseFloat(harga)))
            ).getWithComa
          }
        </Td>
        <Td>
          {isEdit ? (
            <ButtonGroup>
              <ActionButton
                size="sm"
                variant="success"
                iconOnly
                block
                tooltip
                tooltipText="Simpan"
                onClick={formik.handleSubmit}
              >
                <IoCheckmarkOutline size="14" />
              </ActionButton>

              <ActionButton
                size="sm"
                variant="danger"
                iconOnly
                block
                tooltip
                tooltipText="Batal"
                onClick={() => setEditIndex(null)}
                className="m-0"
              >
                <IoCloseOutline size="14" />
              </ActionButton>
            </ButtonGroup>
          ) : (
            <ActionButton
              size="sm"
              variant="primary"
              iconOnly
              block
              tooltip
              tooltipText="Simpan"
              onClick={formik.handleSubmit}
            >
              <IoAddOutline size="14" />
            </ActionButton>
          )}
        </Td>
      </tr>
    );
  };

  return (
    <Table>
      <thead className="bg-light">
        <tr>
          <ThFixed>No.</ThFixed>
          <Th>Item Komponen</Th>
          <Th style={{ width: "100px" }}>Qty. Komponen</Th>
          <ThFixed>Satuan</ThFixed>
          <ThFixed>Harga Satuan</ThFixed>
          <ThFixed>Sub Total</ThFixed>
          {!readOnly && <ThFixed>Aksi</ThFixed>}
        </tr>
      </thead>
      <tbody>
        {!readOnly && <FormSection value={{}} />}
        {values[`analisa_` + nama_buaso].length > 0 ? (
          values[`analisa_` + nama_buaso].map((val, index) => {
            const harga =
              id_buaso === "3"
                ? parseFloat(val?.biaya_penyusutan_per_jam || 0)
                : parseFloat(val[`harga_satuan_${nama_buaso}`] || 0);
            const qty_produksi =
              readOnly && !detail
                ? parseFloat(val.qty_produksi ?? 0) *
                  parseFloat(values?.qty_jobmix ?? 0)
                : parseFloat(val.qty_produksi ?? 0);
            const subtotal = harga * qty_produksi;

            if (index === editIndex) {
              return <FormSection value={val} isEdit />;
            } else if (val.flag === flag) {
              no += 1;

              return (
                <tr key={index}>
                  <TdFixed>{no}</TdFixed>
                  <Td>
                    {val.nama_item
                      ? `${val.nama_item} ${
                          val.nama_varian && val.nama_varian !== ""
                            ? `[${val.nama_varian}]`
                            : ""
                        }`
                      : "-"}
                  </Td>
                  <Td>{DecimalConvert(qty_produksi ?? 0).getWithComa}</Td>
                  <Td>{val.kode_satuan ?? "-"}</Td>
                  <Td className="text-nowrap" textRight>
                    {harga
                      ? RupiahConvert(String(parseFloat(harga))).getWithComa
                      : RupiahConvert("0").getWithComa}
                  </Td>
                  <Td className="text-nowrap" textRight>
                    {subtotal
                      ? RupiahConvert(String(parseFloat(subtotal))).getWithComa
                      : RupiahConvert("0").getWithComa}
                  </Td>
                  {!readOnly && (
                    <Td>
                      <ButtonGroup>
                        <ActionButton
                          size="sm"
                          variant="success"
                          iconOnly
                          block
                          tooltip
                          tooltipText="Ubah Data"
                          onClick={() => setEditIndex(index)}
                        >
                          <IoPencilOutline size="14" />
                        </ActionButton>

                        <ActionButton
                          size="sm"
                          variant="danger"
                          iconOnly
                          block
                          tooltip
                          tooltipText="Hapus Data"
                          className="m-0"
                          onClick={() => {
                            const analisa = values[`analisa_${nama_buaso}`];
                            analisa.splice(index, 1);

                            setFieldValue(`analisa_${nama_buaso}`, analisa);
                          }}
                        >
                          <IoTrashBinOutline size="14" />
                        </ActionButton>
                      </ButtonGroup>
                    </Td>
                  )}
                </tr>
              );
            } else {
              return null;
            }
          })
        ) : (
          <tr>
            <TdFixed colSpan={readOnly ? 8 : 9}>
              <DataStatus text="Tidak Ada Data" />
            </TdFixed>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
export default TabAnalisa;
