import Logo from "assets/image/LogoTJS.jpeg"
import MenuConfig from "./MenuConfig"
import RoutesConfig from "./RoutesConfig"

export default {
  LOGO: Logo, //Logo
  MODUL: 'Produksi',
  MENU: MenuConfig, //Konfigurasi menu pada sidebar
  ROUTES: RoutesConfig //Konfigurasi routing
}
