import { useState, useEffect } from "react";
import { Modal, Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import {
  InfoItemHorizontal,
  InfoItemVertical,
  Table,
  THead,
  TBody,
  Th,
  Td,
  ThFixed,
  TdFixed,
  DataStatus,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { RealisasiProduksiApi } from "api";

const STATUS_PROGRESS = {
  "on progress": ["ON PROGRESS", "text-warning"],
  closed: ["CLOSED", "text-success"],
  pending: ["PENDING", "text-secondary"],
};

const TabItemModal = ({ dataItem = [] }) => {
  return (
    <div className="bg-white p-3 border-top-0 border">
      <Table>
        <THead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 200 }}>Item Komponen</Th>
            <Th style={{ width: 150 }}>Qty. Komponen</Th>
            <Th style={{ width: 100 }}>Satuan</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            <Th style={{ width: 150 }}>Sub Total</Th>
          </tr>
        </THead>
        <TBody>
          {dataItem && dataItem?.length > 0 ? (
            dataItem.map((val, index) => {
              const harga_satuan =
                val.harga_satuan_bahan ??
                val.harga_satuan_upah ??
                val.harga_satuan_subcont ??
                val.harga_satuan_overhead ??
                val.biaya_penyusutan_per_jam ??
                0;
              const sub_total =
                parseFloat(harga_satuan ?? 0) *
                parseFloat(val.qty_produksi ?? 0);

              return (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val?.nama_item ?? "-"}</Td>
                  <Td textRight>
                    {val?.qty_produksi
                      ? DecimalConvert(val.qty_produksi).getWithComa
                      : "-"}
                  </Td>
                  <Td>{val?.kode_satuan ?? "-"}</Td>
                  <Td textRight>
                    {harga_satuan
                      ? RupiahConvert(parseFloat(harga_satuan).toString())
                          .getWithComa
                      : "-"}
                  </Td>
                  <Td textRight>
                    {
                      RupiahConvert(parseFloat(sub_total).toString())
                        .getWithComa
                    }
                  </Td>
                </tr>
              );
            })
          ) : (
            <tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};

const ModalContent = ({ data = {} }) => {
  const [tab, setTab] = useState("bahan");
  const [tabTambahan, setTabTambahan] = useState("bahan");

  // calculate Komponen Utama dan Tambahan
  const calcSum = (data) => {
    const calc = (flag) =>
      data
        ?.filter((v) => v.flag === flag)
        ?.reduce((acc, cur) => {
          const harga_satuan =
            cur.harga_satuan_bahan ??
            cur.harga_satuan_upah ??
            cur.harga_satuan_subcont ??
            cur.harga_satuan_overhead ??
            cur.biaya_penyusutan_per_jam ??
            0;
          const sub_total =
            parseInt(harga_satuan ?? 0) * parseInt(cur.qty_produksi ?? 0);

          return acc + sub_total;
        }, 0);

    return { total_ut: calc("UT"), total_tb: calc("TB") };
  };

  const summary = [
    { buaso: "Bahan", ...calcSum(data?.analisa_bahan ?? []) },
    { buaso: "Upah", ...calcSum(data?.analisa_upah ?? []) },
    { buaso: "Alat & Mesin", ...calcSum(data?.analisa_alat_mesin ?? []) },
    { buaso: "Subkon", ...calcSum(data?.analisa_subcont ?? []) },
    { buaso: "Overhead", ...calcSum(data?.analisa_overhead ?? []) },
  ];

  const totalSummary = [...data?.analisa_bahan]
    .concat(data?.analisa_upah)
    .concat(data?.analisa_alat_mesin)
    .concat(data?.analisa_subcont)
    .concat(data?.analisa_overhead)
    .reduce((acc, cur) => {
      const harga_satuan =
        cur.harga_satuan_bahan ??
        cur.harga_satuan_upah ??
        cur.harga_satuan_subcont ??
        cur.harga_satuan_overhead ??
        cur.biaya_penyusutan_per_jam ??
        0;
      const sub_total =
        parseInt(harga_satuan ?? 0) * parseInt(cur.qty_produksi ?? 0);

      return acc + sub_total;
    }, 0);

  return (
    <>
      <Card className="p-4 mb-4">
        <Row>
          <Col md>
            <InfoItemHorizontal
              label="Tgl. Sales Order"
              text={
                data?.tgl_sales_order
                  ? DateConvert(new Date(data?.tgl_sales_order)).detail
                  : "-"
              }
            />
            <InfoItemHorizontal
              label="No. Sales Order"
              text={data?.no_sales_order ?? "-"}
            />
            <InfoItemHorizontal
              label="Barang Jadi"
              text={data?.nama_item ?? "-"}
            />
            <InfoItemHorizontal
              label="Customer"
              text={data?.nama_customer ?? "-"}
            />
          </Col>
          <Col md>
            <InfoItemHorizontal
              label="Qty. Sales Order"
              text={`${
                data?.qty_item ? DecimalConvert(data.qty_item).getWithComa : "-"
              } ${data?.kode_satuan ?? ""}`}
            />
            <InfoItemHorizontal
              label="Qty. Sedang Diproduksi"
              text={`${
                data?.qty_sedang_diproduksi
                  ? DecimalConvert(data.qty_sedang_diproduksi).getWithComa
                  : "-"
              } ${data?.kode_satuan ?? ""}`}
            />
            <InfoItemHorizontal
              label="Total Qty. Telah Direalisasi"
              text={`${
                data?.qty_produksi
                  ? DecimalConvert(data.qty_produksi).getWithComa
                  : 0
              } ${data?.kode_satuan ?? ""}`}
            />
            <InfoItemHorizontal
              label="Proyek"
              text={data?.nama_proyek ?? "-"}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6} lg>
            <InfoItemVertical
              label="Tgl. Produksi"
              text={DateConvert(new Date(data.tgl_jobmix)).detail}
            />
          </Col>
          <Col md={6} lg>
            <InfoItemVertical label="No. Produksi" text={data.no_jobmix} />
          </Col>
          <Col md={6} lg>
            <InfoItemVertical
              label="Qty. Produksi"
              text={`${DecimalConvert(data.qty_jobmix).getWithComa} ${
                data.kode_satuan
              }`}
            />
          </Col>
          <Col md={6} lg>
            <InfoItemVertical
              label="Status Progress"
              text={
                <div
                  className={
                    STATUS_PROGRESS[data?.status_progress ?? "pending"][1]
                  }
                >
                  {" "}
                  {STATUS_PROGRESS[data?.status_progress ?? "pending"][0]}{" "}
                </div>
              }
            />
          </Col>
        </Row>
      </Card>

      <div className="font-weight-bold"> Komponen Utama </div>
      <Tabs
        activeKey={tab}
        onSelect={(key) => setTab(key)}
        transition={false}
        className="bg-light border px-1 pt-1 rounded-top"
      >
        <Tab
          eventKey="bahan"
          title="Bahan"
          children={
            <TabItemModal
              dataItem={data?.analisa_bahan?.filter(
                ({ flag }) => flag === "UT"
              )}
            />
          }
        />
        <Tab
          eventKey="upah"
          title="Upah"
          children={
            <TabItemModal
              dataItem={data?.analisa_upah?.filter(({ flag }) => flag === "UT")}
            />
          }
        />
        <Tab
          eventKey="alat"
          title="Alat & Mesin"
          children={
            <TabItemModal
              dataItem={data?.analisa_alat_mesin?.filter(
                ({ flag }) => flag === "UT"
              )}
            />
          }
        />
        <Tab
          eventKey="subkon"
          title="Subkon"
          children={
            <TabItemModal
              dataItem={data?.analisa_subcont?.filter(
                ({ flag }) => flag === "UT"
              )}
            />
          }
        />
        <Tab
          eventKey="overhead"
          title="Overhead"
          children={
            <TabItemModal
              dataItem={data?.analisa_overhead?.filter(
                ({ flag }) => flag === "UT"
              )}
            />
          }
        />
      </Tabs>

      <div className="font-weight-bold mt-4"> Komponen Tambahan</div>
      <Tabs
        activeKey={tabTambahan}
        onSelect={(key) => setTabTambahan(key)}
        transition={false}
        className="bg-light border px-1 pt-1 rounded-top"
      >
        <Tab
          eventKey="bahan"
          title="Bahan"
          children={
            <TabItemModal
              dataItem={data?.analisa_bahan?.filter(
                ({ flag }) => flag === "TB"
              )}
            />
          }
        />
        <Tab
          eventKey="alat"
          title="Alat & Mesin"
          children={
            <TabItemModal
              dataItem={data?.analisa_alat_mesin?.filter(
                ({ flag }) => flag === "TB"
              )}
            />
          }
        />
        <Tab
          eventKey="upah"
          title="Upah"
          children={
            <TabItemModal
              dataItem={data?.analisa_upah?.filter(({ flag }) => flag === "TB")}
            />
          }
        />
        <Tab
          eventKey="subkon"
          title="Subkon"
          children={
            <TabItemModal
              dataItem={data?.analisa_subcont?.filter(
                ({ flag }) => flag === "TB"
              )}
            />
          }
        />
        <Tab
          eventKey="overhead"
          title="Overhead"
          children={
            <TabItemModal
              dataItem={data?.analisa_overhead?.filter(
                ({ flag }) => flag === "TB"
              )}
            />
          }
        />
      </Tabs>

      <div className="font-weight-bold mt-4"> Summary Biaya</div>
      <Table>
        <THead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 200 }}>Grup BUASO</Th>
            <Th style={{ minWidth: 200 }}>Jenis Komponen</Th>
            <Th style={{ width: 150 }}>Total</Th>
          </tr>
        </THead>
        <TBody>
          {summary?.map((val, index) => (
            <>
              <tr>
                <TdFixed rowSpan={2}>{index + 1}</TdFixed>
                <Td rowSpan={2}>{val?.buaso}</Td>
                <Td>Komponen Utama</Td>
                <TdFixed textRight>
                  {RupiahConvert(String(val?.total_ut ?? 0)).getWithComa}
                </TdFixed>
              </tr>
              <tr>
                <Td>Komponen Tambahan</Td>
                <TdFixed textRight>
                  {RupiahConvert(String(val?.total_tb ?? 0)).getWithComa}
                </TdFixed>
              </tr>
            </>
          ))}
          <tr className="font-weight-bold">
            <TdFixed colSpan={3} textRight>
              Total
            </TdFixed>
            <TdFixed textRight>
              {RupiahConvert(String(totalSummary ?? 0)).getWithComa}
            </TdFixed>
          </tr>
        </TBody>
      </Table>
    </>
  );
};

export const ModalDetailProduksi = ({ show, onHide, id }) => {
  const [dataDetail, setDataDetail] = useState({});
  const [fetching, setFetching] = useState({
    loading: false,
    error: false,
  });

  const getData = () => {
    setFetching({ loading: true, error: false });

    RealisasiProduksiApi.getDetailProduksi({ id_jobmix: id })
      .then((res) => {
        const data = res?.data?.data ?? {};
        setDataDetail(data);
        setFetching({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setFetching({
          loading: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    getData();
    id && getData();
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <b>Detail Data Produksi</b>
      </Modal.Header>
      <Modal.Body>
        {fetching.loading || fetching.error ? (
          <DataStatus
            loading={fetching.loading}
            text={fetching.loading ? "Memuat data . . ." : "Data gagal dimuat"}
          />
        ) : (
          <ModalContent data={dataDetail} />
        )}
      </Modal.Body>
    </Modal>
  );
};
