import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InfoFormSection, TableSection } from "./Section";
import { ActionButton, BackButton, DataStatus } from "components";
import { Alert } from "react-bootstrap";
import { CloseJobMixApi } from "api";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalSection } from "./Section/ModalSection";

const CloseProduksiDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState({
    data_bahan: [],
    data_upah: [],
    data_alat_mesin: [],
    data_subcont: [],
    data_overhead: [],
  });
  const [modalConfirmConfig, setModalConfirmConfig] = useState({
    show: false,
    data: {},
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [isSubmiting, setIsSubmiting] = useState(false);

  // Mengenai mapping data info
  const mappingDataInfo = (val) => ({
    tgl_produksi: val?.tgl_jobmix,
    no_produksi: val?.no_jobmix ?? "-",
    nama_item: val?.nama_item ?? "-",
    qty_produksi: val?.qty_jobmix ?? 0,
    qty_realisasi: val?.qty_realisasi ?? 0,
    tgl_sales_order: val?.tgl_sales_order,
    no_sales_order: val?.no_sales_order ?? "-",
    nama_customer: val?.nama_customer ?? "-",
    nama_proyek: val?.nama_proyek ?? "-",
    tgl_close_jobmix:
      val?.tgl_close_jobmix ?? new Date().toISOString().slice(0, 10),
    no_close_jobmix: val?.no_close_jobmix,
  });

  const mappingDataTable = (items) =>
    items.map((val) => ({
      nama_item: val?.nama_item ?? "-",
      // bahan
      qty_realisasi_bahan: val?.qty_realisasi_bahan ?? 0,
      harga_satuan_bahan: val?.harga_satuan_bahan ?? 0,
      tgl_bdp_bahan: val?.tgl_bdp_bahan ?? "-",
      no_bdp_bahan: val?.no_bdp_bahan ?? "-",

      // upah
      qty_realisasi_upah: val?.qty_realisasi_upah ?? 0,
      harga_satuan_upah: val?.harga_satuan_upah ?? 0,
      tgl_bdp_upah: val?.tgl_bdp_upah ?? "-",
      no_bdp_upah: val?.no_bdp_upah ?? "-",

      // alat mesin
      qty_realisasi_alat_mesin: val?.qty_realisasi_alat_mesin ?? 0,
      harga_satuan_alat_mesin: val?.harga_satuan_alat_mesin ?? 0,
      tgl_bdp_alat_mesin: val?.tgl_bdp_alat_mesin ?? "-",
      no_bdp_alat_mesin: val.no_bdp_alat_mesin ?? "-",

      // subcont
      qty_realisasi_subkon: val?.qty_realisasi_subkon ?? 0,
      harga_satuan_subkon: val?.harga_satuan_subkon ?? 0,
      tgl_bdp_subkon: val?.tgl_bdp_subkon ?? "-",
      no_bdp_subkon: val.no_bdp_subkon ?? "-",

      // overhead
      qty_realisasi_overhead: val?.qty_realisasi_overhead ?? 0,
      harga_satuan_overhead: val?.harga_satuan_overhead ?? 0,
      tgl_bdp_overhead: val?.tgl_bdp_overhead ?? "-",
      no_bdp_overhead: val.no_bdp_overhead ?? "-",

      // all data
      nomor_akun_debet: val?.nomor_akun_debet ?? "-",
      nomor_akun_kredit: val?.nomor_akun_kredit ?? "-",
      normal_pos_debit: val?.normal_pos_debit ?? "-",
      normal_pos_kredit: val?.normal_pos_kredit ?? "-",
      nama_akun_debit: val?.nama_akun_debit ?? "-",
      nama_akun_kredit: val?.nama_akun_kredit ?? "-",
      keterangan_jurnal: val?.keterangan_jurnal ?? "-",
      nama_satuan: val?.nama_satuan ?? "-",
      vendor: val?.nama_vendor ?? val.nama_vendor,
    }));

  // Menangani fetching data ketika halaman di render
  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    CloseJobMixApi.getSingleProduksi({
      id_jobmix: id,
    })
      .then((data) => {
        const dataDetail = data?.data?.data ?? {};
        setDataInfo(mappingDataInfo(dataDetail ?? {}));
        setDataTable({
          data_bahan: mappingDataTable(dataDetail.bahan ?? []),
          data_upah: mappingDataTable(dataDetail.upah ?? []),
          data_alat_mesin: mappingDataTable(dataDetail.alat_mesin ?? []),
          data_subcont: mappingDataTable(dataDetail.subkon ?? []),
          data_overhead: mappingDataTable(dataDetail.overhead ?? []),
        });

        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch((e) =>
        setFetchingStatus({
          loading: false,
          success: false,
        })
      );
  };

  const formInitialValues = {
    id_jobmix: id,
    tgl_close_jobmix: dataInfo.tgl_close_jobmix,
    no_close_jobmix: dataInfo.no_close_jobmix,
  };

  const FormSubmitHandler = (values) => {
    setIsSubmiting(true);

    const finalValues = {
      id_jobmix: values.id_jobmix,
      status_jobmix: "close",
      tgl_close_jobmix: values.tgl_close_jobmix,
    };
    CloseJobMixApi.save(finalValues)
      .then(() => {
        history.push("/transaksi/close-job-mix/", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          },
        });
        setModalConfirmConfig({
          show: false,
          data: {},
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal ditambah!",
        });
      })
      .finally(() => setIsSubmiting(false));
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      {fetchingStatus.loading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : fetchingStatus.success ? (
        <>
          <Formik initialValues={formInitialValues}>
            {(formik) => (
              <>
                <div className="d-flex justify-content-between mb-4">
                  <b>Detail Data Close Produksi</b>
                  <BackButton onClick={() => history.goBack()} size="sm" />
                </div>
                <InfoFormSection
                  dataInfo={dataInfo}
                  formik={formik}
                  type="detail"
                />{" "}
                <b>List Data BDP Produksi</b>
                <TableSection data={dataTable} />
                {/* <div className="d-flex justify-content-end">
                  <ActionButton
                    text="CLOSE PRODUKSI"
                    variant="danger"
                    size="sm"
                    onClick={() => setModalConfirmConfig({ show: true })}
                  />
                </div> */}
                <ModalSection
                  setModalConfirmConfig={setModalConfirmConfig}
                  modalConfirmConfig={modalConfirmConfig}
                  formik={formik}
                  dataInfo={dataInfo}
                  dataTable={dataTable}
                  FormSubmitHandler={FormSubmitHandler}
                  isSubmiting={isSubmiting}
                />
              </>
            )}
          </Formik>
        </>
      ) : (
        <DataStatus text="Data gagal dimuat!" />
      )}
    </>
  );
};

export default CloseProduksiDetail;
