import Services from "services";

class LaporanProduksi {
  get(params) {
    return Services.get("/laporan_produksi/page", { params });
  }

  getSalesOrder(params) {
    return Services.get("/laporan_produksi/sales_order", { params });
  }

  getSingle(params) {
    return Services.get("/laporan_produksi/single", { params });
  }

  getNomor(params) {
    return Services.get("/laporan_produksi/no_baru", { params });
  }

  getPabrik(params) {
    return Services.get("/laporan_produksi/card_pabrik", { params });
  }

  getDetailGudang(params) {
    return Services.get("/laporan_produksi/data_gudang", { params });
  }

  getStokBarangGudang(params) {
    return Services.get("/laporan_produksi/isi_pabrik", { params });
  }

  save(data) {
    return Services.post("/laporan_produksi", data);
  }

  dropdown(params) {
    return Services.get("/laporan_produksi/dropdown", { params });
  }
  export(params) {
    return Services.get("/laporan_produksi", { params });
  }
}

export default new LaporanProduksi();
