import Services from "services";

class RealisasiProduksiApi {
  // List Realisasi Produksi
  get(params) {
    return Services.get("realisasi_produksi/page", { params });
  }

  // Single Realisasi Produksi
  getSingle(params) {
    return Services.get("/realisasi_produksi/single", { params });
  }

  // List Produksi
  getListProduksi(params) {
    return Services.get("/realisasi_produksi/produksi_page", { params });
  }

  // Single Produksi
  getSingleProduksi(params) {
    return Services.get("/realisasi_produksi/produksi_single", { params });
  }

  getDetailProduksi(params) {
    return Services.get("/realisasi_produksi/produksi_detail", { params });
  }

  getNomor(params) {
    return Services.get("/realisasi_produksi/no_baru", { params });
  }

  getDropdown(params) {
    return Services.get("realisasi_produksi/dropdown", { params });
  }

  save(data) {
    return Services.post("/realisasi_produksi", data);
  }
}

export default new RealisasiProduksiApi();
