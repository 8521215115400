// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton,
  ExportButton,
  ActionButton,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";

// API
import { CloseJobMixApi } from "api";

// View Components
import { ModalFilter } from "./Section";

import { saveAs } from "file-saver";

const CloseProduksiList = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  const createAlert = location?.state?.alert?.variant;

  // Variables
  const paginationFromBrowserState = location?.state?.pagination;

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJobMix, setDataJobMix] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.produksi?.filter?.active,
      tgl_mulai_close_produksi:
        location?.state?.produksi?.filter?.tgl_mulai_close_produksi,
      tgl_selesai_close_produksi:
        location?.state?.produksi?.filter?.tgl_selesai_close_produksi,
      tgl_mulai_produksi: location?.state?.produksi?.filter?.tgl_mulai_produksi,
      tgl_selesai_produksi:
        location?.state?.produksi?.filter?.tgl_selesai_produksi,
      tgl_mulai_sales_order:
        location?.state?.produksi?.filter?.tgl_mulai_sales_order,
      tgl_selesai_sales_order:
        location?.state?.produksi?.filter?.tgl_selesai_sales_order,
      barang_jadi: location?.state?.produksi?.filter?.barang_jadi,
    },
    pagination: {
      page: location?.state?.produksi?.filter?.page ?? "1",
      dataLength: location?.state?.produksi?.filter?.dataLength ?? "10",
      totalPage: location?.state?.produksi?.filter?.totalPage ?? "1",
      dataCount: location?.state?.produksi?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  console.log("cek", location?.state?.alert);

  const getInitialData = () => {
    setIsPageLoading(true);

    CloseJobMixApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_mulai_close_produksi: dataFilter?.filter?.tgl_mulai_close_produksi,
      tgl_selesai_close_produksi:
        dataFilter?.filter?.tgl_selesai_close_produksi,
      tgl_mulai_produksi: dataFilter?.filter?.tgl_mulai_produksi,
      tgl_selesai_produksi: dataFilter?.filter?.tgl_selesai_produksi,
      tgl_mulai_sales_order: dataFilter?.filter?.tgl_mulai_sales_order,
      tgl_selesai_sales_order: dataFilter?.filter?.tgl_selesai_sales_order,
      barang_jadi: dataFilter?.filter?.barang_jadi,
    })
      .then((data) => {
        setDataJobMix(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };
  // const checkAlert = () => {
  //   const locationState = location.state

  //   if (locationState) {
  //     if (locationState.alert) {
  //       setAlertConfig({
  //         show: locationState.alert.show,
  //         text: locationState.alert.text,
  //         variant: locationState.alert.variant,
  //       })
  //     }
  //   }
  // }
  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(()=>{
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: "1",
            dataLength: "10",
            totalPage: "1",
            dataCount: "0",
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    )
  };

  // EXPORT DATA
  const exportData = (file) => {
    setLoadingExport(true);
    console.log(file);

    CloseJobMixApi.export({
      tipe: file,
      active: undefined,
      tgl_mulai_close_produksi: dataFilter?.filter?.tgl_mulai_close_produksi,
      tgl_selesai_close_produksi:
        dataFilter?.filter?.tgl_selesai_close_produksi,
      tgl_mulai_produksi: dataFilter?.filter?.tgl_mulai_produksi,
      tgl_selesai_produksi: dataFilter?.filter?.tgl_selesai_produksi,
      tgl_mulai_sales_order: dataFilter?.filter?.tgl_mulai_sales_order,
      tgl_selesai_sales_order: dataFilter?.filter?.tgl_selesai_sales_order,
      barang_jadi: dataFilter?.filter?.barang_jadi,
    })
      .then((res) => {
        const link = res?.data?.data;
        const parse =
          "https://" +
          link
            .split("/")
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join("/");
        saveAs(parse, "CloseProduksi");
      })
      .catch(() => {
        alert("Export Data Gagal!!");
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Close Produksi");
    if (createAlert !== undefined) {
      // setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.alert?.variant,
        text: location?.state?.alert?.text,
        show: true,
      });

      getInitialData();
      history.replace((createAlert = undefined));
    } else {
      return getInitialData();
    }

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_mulai_close_produksi,
    dataFilter?.filter?.tgl_selesai_close_produksi,
    dataFilter?.filter?.tgl_mulai_produksi,
    dataFilter?.filter?.tgl_selesai_produksi,
    dataFilter?.filter?.tgl_mulai_sales_order,
    dataFilter?.filter?.tgl_selesai_sales_order,
    dataFilter?.filter?.barang_jadi,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Close Produksi</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Item Produksi</Th>
            <Th noPadding width={100}>
              Total Qty. Telah Direalisasi
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataJobMix.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </Td>
              <Td>
                <ButtonGroup size="sm">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        "/transaksi/close-job-mix/detail/" + val.id_jobmix,
                        {
                          ...location?.state,
                          produksi: dataFilter,
                        }
                      )
                    }
                  />
                </ButtonGroup>
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_close_jobmix
                    ? DateConvert(new Date(val.tgl_close_jobmix)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_close_jobmix ?? "-"}</div>
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_jobmix
                    ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_jobmix ?? "-"}</div>
              </Td>
              <Td>
                <div>
                  <div>
                    {val.tgl_sales_order
                      ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_sales_order ?? "-"}</div>
                </div>
              </Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>
                {val.qty_realisasi
                  ? `${DecimalConvert(val.qty_realisasi).getWithComa} ${
                      val.kode_satuan ?? ""
                    }`
                  : "-"}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataJobMix || dataJobMix.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>List Data Close Produksi</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
            <DropdownButton
              className="ml-3"
              variant="warning text-white"
              title={<span className="text-white mr-3">EXPORT</span>}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/close-job-mix/produksi", {
                ...location?.state,
                produksi: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default CloseProduksiList;
