// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { ButtonGroup, Dropdown, Spinner } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { DateConvert, TableNumber } from "utilities";

// API
import { LaporanProduksiApi } from "api";

// View Components
import { ModalFilter } from "./Section";

// View Functions
import { generateStatusApproval } from "../functions";
import FileSaver from "file-saver";

const LaporanListProduksi = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const paginationFromBrowserState = location?.state?.pagination;

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJobMix, setDataJobMix] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.produksi?.filter?.active,
      tgl_produksi_mulai: location?.state?.produksi?.filter?.tgl_produksi_mulai,
      tgl_produksi_selesai:
        location?.state?.produksi?.filter?.tgl_produksi_selesai,
      tgl_sales_order_mulai:
        location?.state?.produksi?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai:
        location?.state?.produksi?.filter?.tgl_sales_order_selesai,
      customer: location?.state?.produksi?.filter?.customer,
      barang_jadi: location?.state?.produksi?.filter?.barang_jadi,
      id_unit_produksi: location?.state?.produksi?.filter?.id_unit_produksi,
    },
    pagination: {
      page: location?.state?.produksi?.filter?.page ?? "1",
      dataLength: location?.state?.produksi?.filter?.dataLength ?? "10",
      totalPage: location?.state?.produksi?.filter?.totalPage ?? "1",
      dataCount: location?.state?.produksi?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    LaporanProduksiApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_produksi_mulai: dataFilter?.filter?.tgl_produksi_mulai,
      tgl_produksi_selesai: dataFilter?.filter?.tgl_produksi_selesai,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      customer: dataFilter?.filter?.customer,
      barang_jadi: dataFilter?.filter?.barang_jadi,
      id_unit_produksi: dataFilter?.filter?.id_unit_produksi,
    })
      .then((data) => {
        setDataJobMix(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };
  // const checkAlert = () => {
  //   const locationState = location.state

  //   if (locationState) {
  //     if (locationState.alert) {
  //       setAlertConfig({
  //         show: locationState.alert.show,
  //         text: locationState.alert.text,
  //         variant: locationState.alert.variant,
  //       })
  //     }
  //   }
  // }
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    const paramData = dataFilter.filter;
    LaporanProduksiApi.export({
      tipe,
      ...paramData,
    })
      .then((res) => {
        const data = res.data.data;
        // const urlFile =
        //   window.location.protocol === "http"
        //     ? data
        //     : data.replace("http", "https");
        const urlFile = data;
        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Laporan Produksi");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_produksi_mulai,
    dataFilter?.filter?.tgl_produksi_selesai,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.barang_jadi,
    dataFilter?.filter?.id_unit_produksi,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Unit Produksi</Th>
            <Th>Customer</Th>
            <Th>Item Barang</Th>
            <Th noPadding width={70}>
              Qty. Sales Order
            </Th>
            <Th noPadding width={70}>
              Qty. Produksi
            </Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataJobMix.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_jobmix
                    ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_jobmix ?? "-"}</div>
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_sales_order
                    ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_sales_order ?? "-"}</div>
              </Td>
              <Td>{val.nama_unit_produksi ?? "-"}</Td>
              <Td>{val.nama_customer ?? "-"}</Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>
                {val.qty_item
                  ? `${parseFloat(val.qty_item)} ${val.kode_satuan ?? ""}`
                  : "-"}
              </Td>
              <Td>
                {val.qty_jobmix
                  ? `${parseFloat(val.qty_jobmix)} ${val.kode_satuan ?? ""}`
                  : "-"}
              </Td>
              <Td>
                <ReadButton
                  onClick={() =>
                    history.push(
                      "/laporan/laporan-produksi/detail/" + val.id_jobmix,
                      {
                        ...location?.state,
                        produksi: dataFilter,
                      }
                    )
                  }
                />
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataJobMix || dataJobMix.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>List Data Produksi</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            {/* <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            /> */}
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
            {/* Export Button */}
            <Dropdown>
              <Dropdown.Toggle
                disabled={loadingExport}
                className="ml-2 mb-2 px-3 btn btn-warning text-white"
              >
                {loadingExport ? (
                  <Spinner animation="border" variant="white" size="sm" />
                ) : (
                  "Export Data"
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // disabled={!dataFilter?.filter.active}
                  onClick={() =>
                    // dataFilter?.filter?.active &&
                    exportData("excel")
                  }
                >
                  Excel (.xlsx)
                </Dropdown.Item>
                <Dropdown.Item
                  // disabled={!dataFilter?.filter.active}
                  onClick={() =>
                    // dataFilter?.filter?.active &&
                    exportData("pdf")
                  }
                >
                  PDF (.pdf)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          {/* <CreateButton
            onClick={() =>
              history.push("/transaksi/job-mix/sales-order", {
                ...location?.state,
                produksi: dataFilter,
              })
            }
          /> */}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default LaporanListProduksi;
