import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  ButtonBack,
  ButtonCreate,
} from "components2";

import PermintaanAsetApi from "../__ProduksiAsetApi__";
import {
  DatePicker,
  InfoItemVertical,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import { TabKomponenProduksiAset } from "./TabKomponenProduksiAset";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateConvert, RupiahConvert } from "utilities";
import TabGambar from "./TabGambar";
import TabFile from "./TabFile";

const InfoProduksiAset = () => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="Tgl. Produksi Aset"
          text={
            values?.tgl_permintaan_aset
              ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
              : "-"
          }
        />
      </Col>
      <Col>
        <InfoItemVertical
          label="No. Produksi Aset"
          text={values?.no_permintaan_aset ?? "-"}
        />
      </Col>
    </Row>
  );
};

const SummeryBuaso = () => {
  const { values } = useFormikContext();

  const getSubTotal = (data) => {
    return data?.reduce((acc, curr) => {
      const subTotal =
        parseFloat(curr.qty ?? 0) * parseFloat(curr.harga_satuan ?? 0);

      return acc + subTotal;
    }, 0);
  };

  const mappingDataSummary = [
    { grup_buaso: "Bahan", sub_total: getSubTotal(values.bahan) },
    { grup_buaso: "Upah", sub_total: getSubTotal(values.upah) },
    { grup_buaso: "Alat Mesin", sub_total: getSubTotal(values.alatMesin) },
    { grup_buaso: "Subkon", sub_total: getSubTotal(values.subkon) },
    { grup_buaso: "Overhead", sub_total: getSubTotal(values.overhead) },
  ];

  const getTotal = mappingDataSummary?.reduce((acc, curr) => {
    return acc + curr.sub_total;
  }, 0);

  return (
    <Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <Th>Grup BUASO</Th>
          <Th>Sub Total</Th>
        </Tr>
      </THead>
      <TBody>
        {mappingDataSummary?.map((val, ind) => (
          <Tr>
            <TdFixed>{ind + 1}</TdFixed>
            <Td>{val?.grup_buaso ?? "-"}</Td>
            <Td className="text-right">
              {
                RupiahConvert(parseFloat(val?.sub_total ?? 0).toString())
                  .getWithComa
              }
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td className="text-right font-weight-bold" colSpan={2}>
            Total
          </Td>
          <Td className="text-right font-weight-bold">
            {RupiahConvert(parseFloat(getTotal ?? 0).toString()).getWithComa}
          </Td>
        </Tr>
      </TBody>
    </Table>
  );
};

export const FormProduksiAsetContent = ({ action }) => {
  const { values, handleSubmit, setValues, errors, touched } =
    useFormikContext();
  const history = useHistory();

  const { isFetching: loadingKode } = useQuery(
    ["maintenanceRequest", "kode", values?.tgl_produksi_aset],
    () =>
      PermintaanAsetApi.generateNumber({
        tanggal: values?.tgl_produksi_aset,
      }).then((val) =>
        setValues({ ...values, no_produksi_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_produksi_aset && action === "create",
    }
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === "create"
            ? "Tambah Data Produksi Aset"
            : action === "update"
            ? "Ubah Data Produksi Aset"
            : "Detail Data Produksi Aset"}
        </small>

        <ButtonBack
          size="sm"
          onClick={() => history.push("/transaksi/produksi-aset")}
        />
      </div>
      <Card>
        <Card.Body>
          <>
            <Row>
              <Col lg="6">
                <InfoItemHorizontal
                  label="Tgl. Permintaan Aset"
                  text={
                    values?.tgl_permintaan_aset
                      ? DateConvert(new Date(values?.tgl_permintaan_aset))
                          .detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Permintaan Aset"
                  text={values?.no_permintaan_aset ?? "-"}
                />
              </Col>
              <Col lg="6">
                <InfoItemHorizontal
                  label="Grup Aset"
                  text={values?.nama_grup_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Kategori Aset"
                  text={values?.nama_kategori_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Jenis Aset"
                  text={values?.nama_jenis_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Item Aset Yang Diminta"
                  text={values?.nama_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Keperluan"
                  text={values?.keperluan ?? "-"}
                />
              </Col>
            </Row>
            <Card className="mt-3">
              <Tab.Container defaultActiveKey="tabGambar">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabFile">File</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Tab.Content>
                  <Tab.Pane eventKey="tabGambar">
                    <div className="m-2">
                      <TabGambar readOnly />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabFile">
                    <div className="m-2">
                      <TabFile readOnly />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>
            <hr />
            {action === "detail" ? (
              <InfoProduksiAset />
            ) : (
              <Row>
                <Col lg="6" md="6">
                  <DatePicker
                    label="Tgl. Produksi Aset"
                    selected={
                      values?.tgl_produksi_aset
                        ? new Date(values?.tgl_produksi_aset)
                        : null
                    }
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tgl_produksi_aset: DateConvert(new Date(val)).default,
                      })
                    }
                    error={
                      errors.tgl_produksi_aset &&
                      touched.tgl_produksi_aset &&
                      true
                    }
                    errorText={
                      errors.tgl_produksi_aset &&
                      touched.tgl_produksi_aset &&
                      errors.tgl_produksi_aset
                    }
                  />
                </Col>
                <Col lg="6" md="6">
                  <Input
                    label="No. Produksi Aset"
                    disabled
                    placeholder="Pilih tanggal terlebih dahulu!!!"
                    loading={loadingKode}
                    value={values?.no_produksi_aset}
                  />
                </Col>
              </Row>
            )}
          </>

          <hr />
          <TabKomponenProduksiAset action={action} />
          <div className="mt-3">
            <small className="font-weight-bold">Summary</small>
            <SummeryBuaso />
          </div>
        </Card.Body>
      </Card>

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === "update" ? "success" : "primary"}
            onClick={handleSubmit}
          />
        </div>
      )}
    </Fragment>
  );
};
