export const formInitialValues = (data) => {
  return {
    tgl_permintaan_produksi: '',
    id_jobmix: data?.id_jobmix ?? undefined,
    id_gudang_produksi: data?.id_gudang ?? undefined,
    nama_gudang_produksi: data?.nama_gudang ?? '',
    no_jobmix: data?.no_jobmix ?? '',
    bahan:
      data?.bahan?.map((val) => ({ ...val, harga: val.harga_satuan_bahan })) ??
      [],
    upah:
      data?.upah?.map((val) => ({
        ...val,
        harga: val.harga_satuan_upah,
      })) ?? [],
    subkon:
      data?.subcont?.map((val) => ({
        ...val,
        harga: val.harga_satuan_subcont,
      })) ?? [],
    overhead:
      data?.overhead?.map((val) => ({
        ...val,
        harga: val.harga_satuan_overhead,
      })) ?? [],
    alat_mesin:
      data?.alat_mesin?.map((val) => ({
        ...val,
        id_item_buaso: val.id_item_aset,
        harga: val.biaya_penyusutan_per_jam,
      })) ?? [],
  };
};
