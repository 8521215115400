const mappingQty = (value, label, qty_jobmix = 0) => {
  return value[label].map((val) => {
    return {
      ...val,
      // qty_produksi: val.flag === "UT" ? parseFloat(val.base_qty * qty_jobmix).toFixed(2) : val.qty_produksi,
    };
  });
};

export default mappingQty;
