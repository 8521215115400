import { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import Cleave from "cleave.js/react";
import {
  DataStatus,
  Table,
  DeleteButton,
  SelectSearch,
  ActionButton,
  THead,
  ThFixed,
  TBody,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import ReactNumberFormat from "components/Input/ReactNumberFormat";

const Th = (props) => (
  <th
    {...props}
    className={`${props.className} align-middle`}
    style={{ ...props.style, padding: 0, fontSize: "14px" }}
  >
    {props.children}
  </th>
);

const Td = (props) => (
  <td
    {...props}
    className={`${props.className} p-1`}
    style={{ ...props.style, fontSize: "13px" }}
  >
    {props.children}
  </td>
);

export const TabWaste = ({
  dataWaste,
  setDataWaste,
  dataHistory,
  dropdownWaste,
  dropdownGudang,
  action = "CREATE",
}) => {
  const [values, setValues] = useState({
    id_item_buaso: "",
    nama_item: "",
    kode_item: "",
    nama_satuan: "",
    qty_realisasi_waste: "",
    id_gudang_tujuan: "",
    nama_gudang_tujuan: "",
    harga_satuan: "",
  });

  const submitData = () => {
    if (
      values?.id_item_buaso &&
      Boolean(parseInt(values?.qty_realisasi_waste) > 0)
    ) {
      setDataWaste((prev) => [...prev, values]);
      setValues({
        id_item_buaso: "",
        nama_item: "",
        kode_item: "",
        nama_satuan: "",
        qty_realisasi_waste: "",
        id_gudang_tujuan: "",
        nama_gudang_tujuan: "",
        harga_satuan: "",
      });
    }
  };

  const deleteData = (index) =>
    setDataWaste(dataWaste.filter((val, id) => id !== index));

  return (
    <div className="bg-white p-3 border-top-0 border">
      <div className="p-2">
        <b>List Realisasi Item Sisa Produksi</b>
      </div>

      {/* Table Realisasi Sisa Produksi */}
      <Table>
        <thead>
          <tr>
            <Th className="py-2 text-center" style={{ width: 40 }}>
              No.
            </Th>
            <Th className="p-2 text-center" style={{ width: 400 }}>
              Item Sisa Produksi
            </Th>
            {action === "CREATE" && (
              <Th className="p-0 text-center" style={{ width: 70 }}>
                Satuan
              </Th>
            )}
            <Th className="p-1 text-center" style={{ width: 200 }}>
              Harga Satuan
            </Th>
            <Th className="p-1 text-center" style={{ width: 70 }}>
              Qty. Realisasi Sisa Produksi
            </Th>
            <Th className="p-1 text-center" style={{ width: 300 }}>
              Gudang Tujuan
            </Th>
            {action === "CREATE" && (
              <ThFixed className="p-0 text-center">Aksi</ThFixed>
            )}
          </tr>
        </thead>
        <tbody>
          {action === "CREATE" && (
            <tr>
              <Td></Td>
              <Td>
                <SelectSearch
                  noMargin
                  key={values.id_item_buaso}
                  placeholder="Pilih Item"
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_item_buaso: val.value,
                      nama_item: val.label,
                      kode_item: val.kode_item,
                      nama_satuan: val.nama_satuan,
                    })
                  }
                  option={dropdownWaste}
                  defaultValue={
                    values.id_item_buaso !== ""
                      ? { value: values.id_item_buaso, label: values.nama_item }
                      : ""
                  }
                />
              </Td>
              <Td>{values.nama_satuan ?? ""}</Td>
              <Td>
                <ReactNumberFormat
                  value={parseFloat(values.harga_satuan)}
                  name="harga_satuan"
                  onChange={(e) => {
                    const update = new Promise((resolve) => {
                      setValues({
                        ...values,
                        harga_satuan: e,
                      });
                      resolve();
                    });

                    // update.then(() =>
                    //   document.getElementById(`qty_waste`).focus()
                    // );
                  }}
                />
              </Td>
              <Td>
                <ReactNumberFormat
                  value={parseFloat(values.qty_realisasi_waste)}
                  name="qty_waste"
                  onChange={(e) => {
                    const update = new Promise((resolve) => {
                      setValues({
                        ...values,
                        qty_realisasi_waste: e,
                      });
                      resolve();
                    });

                    // update.then(() =>
                    //   document.getElementById(`qty_waste`).focus()
                    // );
                  }}
                />
                {/* <Cleave
                  id="qty_waste"
                  className="form-control form-control-sm"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                  }}
                  value={values.qty_realisasi_waste}
                  onChange={(e) => {
                    const update = new Promise((resolve) => {
                      setValues({
                        ...values,
                        qty_realisasi_waste: e.target.rawValue,
                      });
                      resolve();
                    });

                    update.then(() =>
                      console.log(document.getElementById(`qty_waste`).focus())
                    );
                  }}
                /> */}
              </Td>
              <Td>
                <SelectSearch
                  noMargin
                  key={values.id_gudang_tujuan}
                  placeholder="Pilih Item"
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_gudang_tujuan: val.id_gudang,
                      nama_gudang_tujuan: val.nama_gudang,
                    })
                  }
                  option={dropdownGudang}
                  defaultValue={
                    values.id_gudang_tujuan !== ""
                      ? {
                          value: values.id_gudang_tujuan,
                          label: values.nama_gudang_tujuan,
                        }
                      : ""
                  }
                />
              </Td>
              <Td className="text-center">
                <ActionButton
                  className="btn btn-block"
                  type="submit"
                  variant="primary"
                  onClick={submitData}
                  size="sm"
                >
                  <IoAddOutline size={18} />
                </ActionButton>
              </Td>
            </tr>
          )}
          {dataWaste.length > 0 ? (
            dataWaste.map((val, index) => {
              return (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.nama_item}</Td>
                  {action === "CREATE" && <Td>{val.nama_satuan}</Td>}
                  <Td className="text-right">
                    {DecimalConvert(val.qty_realisasi_waste ?? 0).getWithComa}
                    {action === "DETAIL" && ` ${val.nama_satuan}`}
                  </Td>
                  <Td>
                    {
                      RupiahConvert(
                        parseFloat(val.harga_satuan ?? 0).toString()
                      ).getWithComa
                    }
                  </Td>
                  <Td>{val.nama_gudang_tujuan ?? "-"}</Td>
                  {action === "CREATE" && (
                    <Td className="text-center">
                      <DeleteButton
                        className="btn btn-block"
                        size="sm"
                        onClick={() => deleteData(index)}
                      />
                    </Td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <Td colSpan={action === "CREATE" ? 6 : 5}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* History Realisasi */}
      {action === "CREATE" && (
        <>
          <div className="p-2 mt-4">
            <b>History Realisasi Item Sisa Produksi</b>
          </div>
          <Table>
            <THead>
              <tr>
                <ThFixed>No.</ThFixed>
                <Th className="py-2 text-center" style={{ width: 150 }}>
                  Informasi Realisasi Produksi
                </Th>
                <Th className="py-2 text-center" style={{ minWidth: 200 }}>
                  Item Sisa Produksi
                </Th>
                <Th className="py-2 text-center" style={{ width: 150 }}>
                  Qty. Realisasi Sisa Produksi
                </Th>
              </tr>
            </THead>
            <TBody>
              {dataHistory && dataHistory?.length > 0 ? (
                dataHistory.map((val, index) => (
                  <tr key={index}>
                    <td classname="text-center">{index + 1}</td>
                    <Td>
                      <div classname="d-block">
                        {val?.tgl_realisasi_produksi
                          ? DateConvert(new Date(val.tgl_realisasi_produksi))
                              .defaultDMY
                          : "-"}
                      </div>
                      <div classname="d-block">
                        {val?.no_realisasi_produksi ?? "-"}
                      </div>
                    </Td>
                    <Td>{val?.nama_item ?? "-"}</Td>
                    <Td>
                      <div className="text-right">
                        {val?.qty_realisasi_waste
                          ? DecimalConvert(val.qty_realisasi_waste).getWithComa
                          : "-"}
                        {` ${val?.nama_satuan ?? "-"}`}
                      </div>
                    </Td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10}>
                    <DataStatus text="Tidak ada data" />
                  </td>
                </tr>
              )}
            </TBody>
          </Table>
        </>
      )}
    </div>
  );
};
