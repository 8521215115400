import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Card, Row, Col, Nav } from "react-bootstrap";
import { DatePicker, Input, BackButton, DataStatus, ActionButton, PopUpAlert } from "components";
import { DateConvert } from "utilities";
import { RealisasiJobMixApi } from "api";
import axios from "axios";
import {
  TableBahan,
  TableUpah,
  TableSubkon,
  TableAlatMesin,
  TableOverhead,
  TableWaste,
} from "./Tabs";

// Cleave
import Cleave from "cleave.js/react";

const RealisasiJobMix = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const routerState = location.state;
  const { id_jobmix, tipe } = useParams();
  const [rawDataRealisasi, setRawDataRealisasi] = useState({}); //State untuk menyimpan data yang didapatkan dari backend
  const [dataBahan, setDataBahan] = useState([]);
  const [dataAlatMesin, setDataAlatMesin] = useState([]);
  const [dataOverhead, setDataOverhead] = useState([]);
  const [dataSubkon, setDataSubkon] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataWaste, setDataWaste] = useState([]);
  const [dataTanggal, setDataTanggal] = useState({
    tanggal: "",
    nomor: "",
  });
  const [dataQty, setDataQty] = useState("1");
  const [loadingTable, setloadingTable] = useState(true);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [dataAtribut, setDataAtribut] = useState({
    waste: [],
  });

  const mappingData = (data, qty, qty_jobmix, type) => {
    console.log(qty);
    console.log(qty_jobmix);
    if (type === "bahan") {
      return data.map((val) => {
        console.log(val.qty_produksi);
        return {
          ...val,
          qty_produksi_baru:
            val.qty_produksi && qty_jobmix && parseFloat(val.qty_produksi) * parseFloat(qty_jobmix),
          qty_realisasi_bahan:
            tipe === "detail"
              ? val.qty_realisasi_bahan
              : val.qty_produksi && qty && parseFloat(val.qty_produksi) * parseFloat(qty),
        };
      });
    } else if (type === "upah") {
      return data.map((val) => {
        return {
          ...val,
          qty_produksi_baru:
            val.qty_produksi && qty_jobmix && parseFloat(val.qty_produksi) * parseFloat(qty_jobmix),
          qty_realisasi_upah:
            tipe === "detail"
              ? val.qty_realisasi_upah
              : val.qty_produksi && qty && parseFloat(val.qty_produksi) * parseFloat(qty),
        };
      });
    } else if (type === "alat_mesin") {
      return data.map((val) => {
        return {
          ...val,
          qty_produksi_baru:
            val.qty_produksi && qty_jobmix && parseFloat(val.qty_produksi) * parseFloat(qty_jobmix),
          qty_realisasi_alat_mesin:
            tipe === "detail"
              ? val.qty_realisasi_alat_mesin
              : val.qty_produksi && qty && parseFloat(val.qty_produksi) * parseFloat(qty),
        };
      });
    } else if (type === "overhead") {
      return data.map((val) => {
        return {
          ...val,
          qty_produksi_baru:
            val.qty_produksi && qty_jobmix && parseFloat(val.qty_produksi) * parseFloat(qty_jobmix),
          qty_realisasi_overhead:
            tipe === "detail"
              ? val.qty_realisasi_overhead
              : val.qty_produksi && qty && parseFloat(val.qty_produksi) * parseFloat(qty),
        };
      });
    } else if (type === "subkon") {
      return data.map((val) => {
        return {
          ...val,
          qty_produksi_baru:
            val.qty_produksi && qty_jobmix && parseFloat(val.qty_produksi) * parseFloat(qty_jobmix),
          qty_realisasi_subkon:
            tipe === "detail"
              ? val.qty_realisasi_subkon
              : val.qty_produksi && qty && parseFloat(val.qty_produksi) * parseFloat(qty),
        };
      });
    } else if (type === "waste") {
      return data.map((val) => {
        return {
          ...val,
          qty_realisasi_waste:
            tipe === "detail"
              ? val.qty_realisasi_waste
              : val.qty_produksi && qty && parseFloat(val.qty_produksi) * parseFloat(qty),
        };
      });
    }
  };

  // Menangani fetching data ketika halaman di render
  const getInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    if (tipe === "realisasi") {
      axios
        .all([
          RealisasiJobMixApi.getSingleJobMix({ id_jobmix }),
          RealisasiJobMixApi.getNomor({ tanggal: new Date() }),
          RealisasiJobMixApi.dropdown({ tipe: "waste" }),
        ])
        .then(
          axios.spread((res, nomor, waste) => {
            setRawDataRealisasi(res.data.data);

            setDataBahan(
              mappingData(res.data.data.analisa_bahan, dataQty, res.data.data.qty_jobmix, "bahan")
            );
            setDataUpah(
              mappingData(res.data.data.analisa_upah, dataQty, res.data.data.qty_jobmix, "upah")
            );
            setDataAlatMesin(
              mappingData(
                res.data.data.analisa_alat_mesin,
                dataQty,
                res.data.data.qty_jobmix,
                "alat_mesin"
              )
            );
            setDataOverhead(
              mappingData(
                res.data.data.analisa_overhead,
                dataQty,
                res.data.data.qty_jobmix,
                "overhead"
              )
            );
            setDataSubkon(
              mappingData(
                res.data.data.analisa_subcont,
                dataQty,
                res.data.data.qty_jobmix,
                "subkon"
              )
            );
            setFetchingStatus({
              loading: false,
              success: true,
            });
            setDataTanggal({
              tanggal: new Date(),
              nomor: nomor.data.data,
            });
            setDataAtribut({
              waste: waste.data.data,
            });
          })
        )
        .catch(() => {
          setFetchingStatus({
            loading: false,
            success: false,
          });
        });
    } else {
      RealisasiJobMixApi.single({ id_jobmix_realisasi: id_jobmix })
        .then((res) => {
          setRawDataRealisasi(res.data.data);
          setDataBahan(
            mappingData(res.data.data.bahan, dataQty, res.data.data.qty_jobmix, "bahan")
          );
          setDataUpah(mappingData(res.data.data.upah, dataQty, res.data.data.qty_jobmix, "upah"));
          setDataAlatMesin(
            mappingData(res.data.data.alat_mesin, dataQty, res.data.data.qty_jobmix, "alat_mesin")
          );
          setDataOverhead(
            mappingData(res.data.data.overhead, dataQty, res.data.data.qty_jobmix, "overhead")
          );
          setDataSubkon(
            mappingData(res.data.data.subkon, dataQty, res.data.data.qty_jobmix, "subkon")
          );
          setDataWaste(
            mappingData(res.data.data.waste, dataQty, res.data.data.qty_jobmix, "waste")
          );
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setFetchingStatus({
            loading: false,
            success: false,
          });
        });
    }
  };

  useEffect(() => {
    setNavbarTitle(`${tipe} Produksi`);
    getInitialData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setloadingTable(true);
    document.getElementById("qty") && document.getElementById("qty").focus();

    const update = new Promise((resolve) => {
      setDataBahan(mappingData(dataBahan, dataQty, rawDataRealisasi.qty_jobmix, "bahan"));
      setDataUpah(mappingData(dataUpah, dataQty, rawDataRealisasi.qty_jobmix, "upah"));
      setDataAlatMesin(
        mappingData(dataAlatMesin, dataQty, rawDataRealisasi.qty_jobmix, "alat_mesin")
      );
      setDataOverhead(mappingData(dataOverhead, dataQty, rawDataRealisasi.qty_jobmix, "overhead"));
      setDataSubkon(mappingData(dataSubkon, dataQty, rawDataRealisasi.qty_jobmix, "subkon"));
      resolve();
    });

    update.then(() => {
      setloadingTable(false);
      document.getElementById("qty") && document.getElementById("qty").focus();
    });

    // eslint-disable-next-line
  }, [dataQty]);

  const InfoSection = () => {
    const [loadingTanggal, setLoadingTanggal] = useState(false);
    const [dataRealisasi, setDataRealisasi] = useState({}); //State untuk menyimpan data yang telah di mapping dari backend

    // Mapping data untuk menyesuaikan response dari backend agar sesuai dengan data yang diperlukan pada komponen
    const mappingDataInfo = () =>
      setDataRealisasi({
        ...rawDataRealisasi,
        tgl_sales_order: rawDataRealisasi.tgl_sales_order
          ? DateConvert(new Date(rawDataRealisasi.tgl_sales_order)).detail
          : "-",
        no_sales_order: rawDataRealisasi.no_sales_order ?? "-",
        tgl_jobmix: rawDataRealisasi.tgl_jobmix
          ? DateConvert(new Date(rawDataRealisasi.tgl_jobmix)).detail
          : "-",
        no_jobmix: rawDataRealisasi.no_jobmix ?? "-",
        customer: rawDataRealisasi.nama_customer ?? "-",
        tgl_selesai: rawDataRealisasi.tgl_selesai_jobmix
          ? DateConvert(new Date(rawDataRealisasi.tgl_selesai_jobmix)).detail
          : "-",
        kode_item: 
          tipe === "realisasi"
              ? rawDataRealisasi.kode_item ?? "-"
              : rawDataRealisasi.kode_barang ?? "-",
        nama_item:
          tipe === "realisasi"
            ? rawDataRealisasi.nama_item ?? "-"
            : rawDataRealisasi.nama_barang ?? "-",
        qty_item:
          tipe === "realisasi"
            ? parseFloat(rawDataRealisasi.qty_item).toPrecision() ?? "-"
            : parseFloat(rawDataRealisasi.qty_sales_order).toPrecision() ?? "-",
        qty_telah_diproduksi: parseFloat(rawDataRealisasi.qty_produksi).toPrecision() ?? "-",
        qty_jobmix: parseFloat(rawDataRealisasi.qty_jobmix).toPrecision() ?? "-",
        qty_telah_direalisasi: parseFloat(rawDataRealisasi.qty_realisasi ?? 0).toPrecision() ?? "-",
        nama_pabrik: rawDataRealisasi.nama_pabrik ?? "-",
        nama_satuan: rawDataRealisasi.kode_satuan,
      });

    useEffect(() => {
      mappingDataInfo();
    }, []);

    const InfoItems = ({ label, value }) => (
      <tr style={{ fontSize: 14 }}>
        <td>{label}</td>
        <td className="pl-3 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    const getNomorHandler = (date) => {
      setLoadingTanggal(true);

      RealisasiJobMixApi.getNomor({ tanggal: date })
        .then((res) => {
          const nomorBaru = res.data.data;

          setDataTanggal({
            ...dataTanggal,
            tanggal: date,
            nomor: nomorBaru,
          });
        })
        .catch(() => {
          setDataTanggal({
            ...dataTanggal,
            tanggal: "",
            nomor: "",
          });
        })
        .finally(() => {
          setLoadingTanggal(false);
        });
    };

    return (
      <>
        <Row>
          <Col md>
            <table>
              <tbody>
                <InfoItems label="Tgl. Produksi" value={dataRealisasi.tgl_jobmix} />
                <InfoItems label="No. Produksi" value={dataRealisasi.no_jobmix} />
                <InfoItems label="Kode Item Produksi" value={dataRealisasi.kode_item} />
                <InfoItems label="Item Produksi" value={dataRealisasi.nama_item} />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table>
              <tbody>
                <InfoItems
                  label="Qty. Produksi"
                  value={`${dataRealisasi.qty_jobmix} ${dataRealisasi.nama_satuan}`}
                />
                <InfoItems
                  label="Total Qty. Telah Direalisasi"
                  value={`${dataRealisasi.qty_telah_direalisasi} ${dataRealisasi.nama_satuan}`}
                />
                <InfoItems
                  label="Gudang Produksi"
                  value={dataRealisasi.nama_gudang ?? '-'}
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col sm="4">
            {tipe === "realisasi" ? (
              <DatePicker
                label="Tanggal Realisasi Produksi"
                placeholderText={loadingTanggal ? "Memuat tanggal . . . " : "Pilih tanggal"}
                selected={
                  loadingTanggal ? "" : dataTanggal.tanggal ? new Date(dataTanggal.tanggal) : ""
                }
                onChange={(date) => getNomorHandler(DateConvert(date).default)}
              />
            ) : (
              <>
                <div>Tanggal Realisasi Produksi</div>
                <strong>
                  {rawDataRealisasi.tgl_jobmix_realisasi
                    ? DateConvert(new Date(rawDataRealisasi.tgl_jobmix_realisasi)).detail
                    : "-"}
                </strong>
              </>
            )}
          </Col>
          <Col sm="4">
            {tipe === "realisasi" ? (
              <Input
                readOnly
                label="Nomor Realisasi Produksi"
                placeholder="Pilih tanggal untuk menentukan nomor"
                value={loadingTanggal ? "Memuat nomor . . ." : dataTanggal.nomor}
              />
            ) : (
              <>
                <div>No. Realisasi Produksi</div>
                <strong>{rawDataRealisasi.no_jobmix_realisasi ?? "-"}</strong>
              </>
            )}
          </Col>
          <Col sm="4">
            {tipe === "realisasi" ? (
              <>
                <small>Qty. Realisasi Produksi</small>
                <Cleave
                  id="qty"
                  placeholder="Masukan Qty. Realisasi Produksi"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                  }}
                  name="qty"
                  value={dataQty}
                  onChange={(e) => setDataQty(e.target.rawValue)}
                  className="form-control form-control-sm"
                />
              </>
            ) : (
              // <Input
              // label=""
              //   id="qty"
              //   placeholder="Masukan Qty. Realisasi Produksi"
              //   type="number"
              //   min="0"
              //   value={dataQty}
              //   onChange={(e) => }
              // />
              <>
                <div>Qty. Realisasi Produksi</div>
                <strong>{rawDataRealisasi.qty_jobmix_realisasi ?? "-"}</strong>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const TableSection = () => {
    const [tabs, setTabs] = useState("bahan"); //State untuk menampung tabs yang aktif
    const [dataBahanTabs, setDataBahanTabs] = useState(dataBahan);
    const [dataAlatMesinTabs, setDataAlatMesinTabs] = useState(dataAlatMesin);
    const [dataOverheadTabs, setDataOverheadTabs] = useState(dataOverhead);
    const [dataSubkonTabs, setDataSubkonTabs] = useState(dataSubkon);
    const [dataUpahTabs, setDataUpahTabs] = useState(dataUpah);
    const [dataWasteTabs, setDataWasteTabs] = useState(dataWaste);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      title: "",
      text: "",
      type: "",
    });
    const [loading, setLoading] = useState(false);

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: "bahan",
        label: "Bahan",
        component: () => (
          <TableBahan dataBahan={dataBahanTabs} setDataBahan={setDataBahanTabs} tipe={tipe} />
        ),
      },
      {
        tab: "upah",
        label: "Upah",
        component: () => (
          <TableUpah dataUpah={dataUpahTabs} setDataUpah={setDataUpahTabs} tipe={tipe} />
        ),
      },
      {
        tab: "alatMesin",
        label: "Alat Mesin",
        component: () => (
          <TableAlatMesin
            dataAlatMesin={dataAlatMesinTabs}
            setDataAlatMesin={setDataAlatMesinTabs}
            tipe={tipe}
          />
        ),
      },
      {
        tab: "subkon",
        label: "Subkon",
        component: () => (
          <TableSubkon dataSubkon={dataSubkonTabs} setDataSubkon={setDataSubkonTabs} tipe={tipe} />
        ),
      },
      {
        tab: "overhead",
        label: "Overhead",
        component: () => (
          <TableOverhead
            dataOverhead={dataOverheadTabs}
            setDataOverhead={setDataOverheadTabs}
            tipe={tipe}
          />
        ),
      },
      {
        tab: "waste",
        label: "Waste",
        component: () => (
          <TableWaste
            dataWaste={dataWasteTabs}
            setDataWaste={setDataWasteTabs}
            dataAtribut={dataAtribut}
            tipe={tipe}
          />
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);

      // eslint-disable-next-line
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    const submitHandler = () => {
      if (dataQty === "" && dataTanggal.tanggal === "") {
        setAlertConfig({
          show: true,
          title: "Gagal",
          text: "Qty / Tanggal Realisasi Produksi kosong",
          type: "error",
        });
      } else {
        setLoading(true);

        RealisasiJobMixApi.save({
          tgl_jobmix_realisasi: dataTanggal.tanggal,
          qty_jobmix_realisasi: dataQty,
          id_jobmix_master: id_jobmix,
          bahan: dataBahanTabs,
          upah: dataUpahTabs,
          alat_mesin: dataAlatMesinTabs,
          overhead: dataOverheadTabs,
          subkon: dataSubkonTabs,
          waste: dataWasteTabs,
        })
          .then(() =>
            setAlertConfig({
              show: true,
              title: "Berhasil",
              text: "Tambah Realisasi Produksi berhasil",
              type: "success",
            })
          )
          .catch(() =>
            setAlertConfig({
              show: true,
              title: "Gagal",
              text: "Tambah Realisasi Produksi gagal",
              type: "error",
            })
          )
          .finally(() => setLoading(false));
      }
    };

    return (
      <>
        <PopUpAlert
          show={alertConfig.show}
          onConfirm={() => {
            if (alertConfig.type === "success") {
              history.push("/transaksi/realisasi-job-mix", {
                pagination: {
                  dataCount: routerState?.pagination?.dataCount,
                  dataLength: routerState?.pagination?.dataLength,
                  page: 1,
                  totalPage: routerState?.pagination?.totalPage,
                },
              });
            } else {
              setAlertConfig({
                ...alertConfig,
                show: false,
              });
            }
          }}
          onHide={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
          title={alertConfig.title}
          text={alertConfig.text}
          type={alertConfig.type}
        />
        <Card>
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} idJobmix={rawDataRealisasi.id_jobmix} />
            )}
          </Card.Body>
          {tipe === "realisasi" && (
            <Card.Footer className="text-right">
              <ActionButton text="Simpan" loading={loading} onClick={submitHandler} />
            </Card.Footer>
          )}
        </Card>
      </>
    );
  };

  if (tipe !== "realisasi" && tipe !== "detail") {
    <DataStatus text="Data gagal dimuat" />;
  } else {
    return (
      <>
        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b className="text-capitalize">{tipe} Produksi</b>
            <BackButton
              onClick={() => {
                if (tipe === "realisasi") {
                  history.push("/transaksi/realisasi-job-mix/list-job-mix", { ...routerState });
                } else {
                  history.push("/transaksi/realisasi-job-mix", { ...routerState });
                }
              }}
            />
          </Card.Header>
          <Card.Body>
            {fetchingStatus.loading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : fetchingStatus.success ? (
              <InfoSection />
            ) : (
              <DataStatus text="Data gagal dimuat!" />
            )}
          </Card.Body>
        </Card>
        {!fetchingStatus.loading && fetchingStatus.success && !loadingTable ? (
          <TableSection />
        ) : (
          <DataStatus loading={true} text="Memuat data . . ." />
        )}
      </>
    );
  }
};

export default RealisasiJobMix;
