// React
import { Fragment, useEffect, useState } from 'react';

// Router
import { useLocation, useParams, useHistory } from 'react-router-dom';

// API
import axios from 'axios';
import { JobMixApi } from 'api';

// Components
import { ActionButton, BackButton, DataStatus, PopUpAlert } from 'components';
import { Card, Form, Nav, Tab } from 'react-bootstrap';
import { DateConvert } from 'utilities';

// Form
import { Formik } from 'formik';
import * as Yup from 'yup';

// View Components
import {
  InfoFormSection,
  ProduksiSection,
  TabAnalisa,
  SummarySection,
  Divider,
} from './components';

// View Functions
import { dataSummary, mappingFlag, totalDataSummary } from './functions';

const TambahJobMix = ({ setNavbarTitle }) => {
  // Hooks
  const { id } = useParams();
  const isSO = Boolean(id);
  const location = useLocation();
  const history = useHistory();

  // Variables
  const routerState = location.state;

  // States
  const [page, setPage] = useState({
    loading: true,
    status: true,
  });
  const [data, setData] = useState({});
  const [dropdown, setDropdown] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: '',
    title: '',
    text: '',
    hide: () => setAlertConfig({ ...alertConfig, show: false }),
  });

  useEffect(() => {
    setNavbarTitle('Produksi');

    axios
      .all([
        JobMixApi.dropdown({ tipe: 'bahan' }),
        JobMixApi.dropdown({ tipe: 'upah' }),
        JobMixApi.dropdown({ tipe: 'aset' }),
        JobMixApi.dropdown({ tipe: 'subkon' }),
        JobMixApi.dropdown({ tipe: 'overhead' }),
        isSO && JobMixApi.getSingle({ id_sales_order_detail: id }),
      ])
      .then(
        axios.spread((bahan, upah, alat_mesin, subkon, overhead, single) => {
          if (isSO) {
            const dataSingle = single.data.data;

            setData({
              ...dataSingle,
              analisa_bahan: mappingFlag(dataSingle, 'analisa_bahan'),
              analisa_upah: mappingFlag(dataSingle, 'analisa_upah'),
              analisa_alat_mesin: mappingFlag(dataSingle, 'analisa_alat_mesin'),
              analisa_subcont: mappingFlag(dataSingle, 'analisa_subcont'),
              analisa_overhead: mappingFlag(dataSingle, 'analisa_overhead'),
            });
          }

          setDropdown({
            bahan: bahan.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
            upah: upah.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
            alat_mesin: alat_mesin.data.data.map((val) => {
              return {
                ...val,
                id_item_buaso: val.id_item_aset,
                value: val.id_item_aset,
                label: val.nama_item,
              };
            }),
            subkon: subkon.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
            overhead: overhead.data.data.map((val) => {
              return {
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              };
            }),
          });

          setPage({
            loading: false,
            status: true,
          });
        }),
      )
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });

    // eslint-disable-next-line
  }, []);

  if (page.loading || !page.status) {
    return (
      <DataStatus
        loading={page.loading}
        text={
          !page.status
            ? 'Data gagal dimuat, tidak dapat menampilkan data'
            : 'Memuat data...'
        }
      />
    );
  } else {
    return (
      <Fragment>
        <Formik
          enableReinitialize
          initialValues={{
            id_sales_order: data.id_sales_order ?? undefined,
            id_sales_order_detail: data.id_sales_order_detail ?? undefined,
            tgl_mulai_jobmix: DateConvert(new Date()).default,
            tgl_jobmix: '',
            nomor: '',
            qty_jobmix: null,
            id_item_buaso: isSO ? undefined : '',
            is_spk: data.is_spk ?? null,
            id_gudang: data.id_gudang ?? '',
            kode_satuan: isSO ? data.kode_satuan : '',
            analisa_bahan: data.analisa_bahan ?? [],
            analisa_upah: data.analisa_upah ?? [],
            analisa_alat_mesin: data.analisa_alat_mesin ?? [],
            analisa_subcont: data.analisa_subcont ?? [],
            analisa_overhead: data.analisa_overhead ?? [],
            id_proyek: data.id_proyek ?? '',
            qty_max_bisa_diproduksi: data.qty_max_bisa_diproduksi ?? 0,
          }}
          validationSchema={Yup.object().shape({
            tgl_jobmix: Yup.string().required('Tanggal Produksi wajib diisi'),
            id_gudang: Yup.string().required('Pilih Gudang Produksi'),
            id_item_buaso: isSO
              ? Yup.string().nullable()
              : Yup.string().required('Item Barang wajib diisi'),
            qty_jobmix: isSO
              ? Yup.number()
                  .typeError('Qty. Produksi wajib diisi')
                  .required('Qty. Produksi wajib diisi')
                  .max(
                    parseFloat(data?.qty_max_bisa_diproduksi),
                    `Qty Tidak boleh lebih dari ${parseFloat(
                      data.qty_max_bisa_diproduksi ?? 0,
                    )}`,
                  )
                  .min(0, 'Qty Tidak boleh kurang dari 0')
                  .nullable()
              : Yup.number().typeError('Qty. Produksi wajib diisi').nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const finalValues = {
              ...values,
              analisa_bahan: values.analisa_bahan.map((val) => ({
                ...val,
                qty_produksi:
                  val.flag === 'UT'
                    ? parseFloat(val.qty_produksi * values.qty_jobmix).toFixed(
                        2,
                      )
                    : val.qty_produksi,
              })),
              analisa_upah: values.analisa_upah.map((val) => ({
                ...val,
                qty_produksi:
                  val.flag === 'UT'
                    ? parseFloat(val.qty_produksi * values.qty_jobmix).toFixed(
                        2,
                      )
                    : val.qty_produksi,
              })),
              analisa_alat_mesin: values.analisa_alat_mesin.map((val) => ({
                ...val,
                qty_produksi:
                  val.flag === 'UT'
                    ? parseFloat(val.qty_produksi * values.qty_jobmix).toFixed(
                        2,
                      )
                    : val.qty_produksi,
              })),
              analisa_subcont: values.analisa_subcont.map((val) => ({
                ...val,
                qty_produksi:
                  val.flag === 'UT'
                    ? parseFloat(val.qty_produksi * values.qty_jobmix).toFixed(
                        2,
                      )
                    : val.qty_produksi,
              })),
              analisa_overhead: values.analisa_overhead.map((val) => ({
                ...val,
                qty_produksi:
                  val.flag === 'UT'
                    ? parseFloat(val.qty_produksi * values.qty_jobmix).toFixed(
                        2,
                      )
                    : val.qty_produksi,
              })),
            };

            JobMixApi.save(finalValues)
              .then(() => {
                setAlertConfig({
                  ...alertConfig,
                  show: true,
                  type: 'success',
                  title: 'Tambah Produksi Berhasil',
                  text: 'Data berhasil ditambah!',
                  hide: () =>
                    history.push('/transaksi/job-mix/sales-order', {
                      ...routerState,
                      alert: {
                        show: true,
                        text: 'Tambah Data Berhasil',
                        variant: 'primtoFixedary',
                      },
                    }),
                });
              })
              .catch(() => {
                setAlertConfig({
                  ...alertConfig,
                  show: true,
                  type: 'danger',
                  title: 'Tambah Produksi Gagal',
                  text: 'Data gagal ditambah!',
                  hide: () =>
                    setAlertConfig({
                      ...alertConfig,
                      show: false,
                    }),
                });
              })
              .finally(() => setSubmitting(false));
          }}
        >
          {({ handleSubmit, isSubmitting, values, errors }) => {
            // Variables
            const summary = dataSummary(values);
            const total = totalDataSummary(summary);

            return (
              <Form onSubmit={handleSubmit}>
                <Divider>
                  <InfoFormSection data={data} isCreate noSO={!isSO} />
                </Divider>

                <Divider>
                  <ProduksiSection title="Komponen Utama">
                    <Card>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="analisa-1"
                      >
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-3">
                                Alat & Mesin
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="1"
                              nama_buaso="bahan"
                              label="Bahan"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-2">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="2"
                              nama_buaso="upah"
                              label="upah"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-3">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="3"
                              nama_buaso="alat_mesin"
                              label="Alat & Mesin"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-4">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="4"
                              nama_buaso="subcont"
                              label="Subkon"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-5">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="5"
                              nama_buaso="overhead"
                              label="Overhead"
                              flag="UT"
                              readOnly
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </ProduksiSection>
                </Divider>

                <Divider>
                  <ProduksiSection title="Komponen Tambahan">
                    <Card>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="analisa-1"
                      >
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-3">
                                Alat & Mesin
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="1"
                              nama_buaso="bahan"
                              label="Bahan"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-2">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="2"
                              nama_buaso="upah"
                              label="upah"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-3">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="3"
                              nama_buaso="alat_mesin"
                              label="Alat & Mesin"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-4">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="4"
                              nama_buaso="subcont"
                              label="Subkon"
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-5">
                            <TabAnalisa
                              dropdown={dropdown}
                              id_buaso="5"
                              nama_buaso="overhead"
                              label="Overhead"
                              flag="TB"
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </ProduksiSection>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>

                <div className="d-flex justify-content-end align-items-center mt-3">
                  <ActionButton
                    variant="primary"
                    text="Simpan"
                    type="submit"
                    loading={isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>

        <PopUpAlert
          show={alertConfig.show}
          onConfirm={alertConfig.hide}
          onHide={alertConfig.hide}
          title={alertConfig.title}
          text={alertConfig.text}
          type={alertConfig.type}
        />
      </Fragment>
    );
  }
};

export default TambahJobMix;
