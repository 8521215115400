import { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { ActionButton, BackButton, DataStatus } from "components";
import { RealisasiProduksiApi } from "api";
import { InfoSection, TabItem, TabWaste } from "./Comps";
import { AlertAlreadyStockOpname } from "components/Alert";

export const RealisasiProduksiCreate = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const routerState = location.state;
  const [tab, setTab] = useState("bahan");
  const [dataDetail, setDataDetail] = useState([]);
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataAlat, setDataAlat] = useState([]);
  const [dataSubkon, setDataSubkon] = useState([]);
  const [dataOverhead, setDataOverhead] = useState([]);
  const [dataWaste, setDataWaste] = useState([]);
  const [dropdownWaste, setDropdownWaste] = useState([]);
  const [dropdownGudang, setDropdownGudang] = useState([]);
  const [dataHistory, setDataHistory] = useState({});
  const [fetching, setFetching] = useState({
    loading: true,
    error: false,
  });

  const formInitialValues = {
    id_jobmix: id,
    tgl_realisasi_produksi: "",
    no_realisasi_produksi: "",
    qty_realisasi_produksi:
      parseFloat(dataDetail.qty_jobmix ?? 0) -
      parseFloat(dataDetail.qty_realisasi ?? 0),
  };

  const formValidationSchema = Yup.object().shape({
    tgl_realisasi_produksi: Yup.string().required(
      "Tgl. realisasi tidak boleh kosong"
    ),
    no_realisasi_produksi: Yup.string().required(
      "No. realisasi tidak boleh kosong, pilih tgl untuk menentukan nomor"
    ),
    qty_realisasi_produksi: Yup.string().required(
      "Qty. realisasi tidak boleh kosong"
    ),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      bahan: dataBahan
        .filter((val) => val.qty_realisasi > 0)
        .map((val) => ({
          id_item_buaso: val.id_item_buaso,
          id_transfer_produksi_bahan: val.id,
          qty_realisasi_bahan: val.qty_realisasi,
        })),
      upah: dataUpah
        .filter((val) => val.qty_realisasi > 0)
        .map((val) => ({
          id_transfer_produksi_upah: val.id,
          qty_realisasi_upah: val.qty_realisasi,
        })),
      alat_mesin: dataAlat
        .filter((val) => val.qty_realisasi > 0)
        .map((val) => ({
          id_transfer_produksi_alat_mesin: val.id,
          qty_realisasi_alat_mesin: val.qty_realisasi,
        })),
      subkon: dataSubkon
        .filter((val) => val.qty_realisasi > 0)
        .map((val) => ({
          id_transfer_produksi_subkon: val.id,
          qty_realisasi_subkon: val.qty_realisasi,
        })),
      overhead: dataOverhead
        .filter((val) => val.qty_realisasi > 0)
        .map((val) => ({
          id_transfer_produksi_overhead: val.id,
          qty_realisasi_overhead: val.qty_realisasi,
        })),
      waste: dataWaste
        ?.filter((val) => val.qty_realisasi_waste > 0)
        .map((val) => ({
          id_item_buaso: val.id_item_buaso,
          qty_realisasi_waste: val.qty_realisasi_waste,
          id_gudang_tujuan: val.id_gudang_tujuan,
          harga_satuan: val.harga_satuan,
        })),
    };

    RealisasiProduksiApi.save(finalValues)
      .then(() =>
        history.push("/transaksi/realisasi-produksi/list-produksi", {
          alert: {
            show: true,
            variant: "primary",
            text: "Tambah data berhasil!",
          },
          produksi: {
            page: routerState?.produksi?.page ?? "1",
            dataLength: routerState?.produksi?.dataLength ?? "10",
            totalPage: routerState?.produksi?.totalPage ?? "1",
            dataCount: routerState?.produksi?.dataCount ?? "0",
          },
        })
      )
      .catch(() =>
        history.push("/transaksi/realisasi-produksi/list-produksi", {
          alert: { show: true, variant: "danger", text: "Tambah data gagal!" },
        })
      );
  };

  const mapDataItem = (dataItem = [], type) =>
    dataItem
      ? dataItem.map((val) => ({
          id:
            val?.id_transfer_produksi_bahan ??
            val.id_transfer_produksi_upah ??
            val.id_transfer_produksi_alat_mesin ??
            val.id_transfer_produksi_subkon ??
            val.id_transfer_produksi_overhead,
          tgl_transfer:
            val?.tgl_transfer_produksi_bahan ??
            val.tgl_transfer_produksi_upah ??
            val.tgl_transfer_produksi_alat_mesin ??
            val.tgl_transfer_produksi_subkon ??
            val.tgl_transfer_produksi_overhead,
          no_transfer:
            val?.no_transfer_produksi_bahan ??
            val.no_transfer_produksi_upah ??
            val.no_transfer_produksi_alat_mesin ??
            val.no_transfer_produksi_subkon ??
            val.no_transfer_produksi_overhead,
          nama_item: val?.nama_item_buaso ?? val?.nama_item,
          nama_vendor: val?.nama_vendor,
          satuan: val?.nama_satuan,
          qty_transfer: val?.qty_transfer,
          qty_telah_ditransfer: parseFloat(val?.qty_telah_ditransfer),
          qty_realisasi:
            type === "overhead"
              ? parseFloat(val?.qty_telah_ditransfer)
              : parseFloat(val.qty_transfer) -
                parseFloat(val.qty_telah_direalisasi ?? 0),
          qty_terealisasi: val?.qty_telah_direalisasi ?? 0,
          id_item_buaso: val?.id_item_buaso,
        }))
      : [];

  const mapDataHistory = (dataItem = []) =>
    dataItem
      ? dataItem.map((val) => ({
          id:
            val?.id_realisasi_produksi_bahan ??
            val.id_realisasi_produksi_upah ??
            val.id_realisasi_produksi_alat_mesin ??
            val.id_realisasi_produksi_subkon ??
            val.id_realisasi_produksi_overhead,
          tgl_realisasi: val?.tgl_realisasi_produksi,
          no_realisasi: val?.no_realisasi_produksi,
          tgl_transfer:
            val?.tgl_transfer_produksi_bahan ??
            val.tgl_transfer_produksi_upah ??
            val.tgl_transfer_produksi_alat_mesin ??
            val.tgl_transfer_produksi_subkon ??
            val.tgl_transfer_produksi_overhead,
          no_transfer:
            val?.no_transfer_produksi_bahan ??
            val.no_transfer_produksi_upah ??
            val.no_transfer_produksi_alat_mesin ??
            val.no_transfer_produksi_subkon ??
            val.no_transfer_produksi_overhead,
          nama_item: val?.nama_item,
          nama_vendor: val?.nama_vendor,
          satuan: val?.nama_satuan,
          qty_realisasi:
            val?.qty_realisasi_bahan ??
            val.qty_realisasi_upah ??
            val.qty_realisasi_alat_mesin ??
            val.qty_realisasi_subkon ??
            val.qty_realisasi_overhead,
        }))
      : [];

  const getInitialData = () =>
    Axios.all([RealisasiProduksiApi.getSingleProduksi({ id_jobmix: id })])
      .then(
        Axios.spread((data) => {
          const getDataDetail = data?.data?.data ?? {};

          setDataDetail(getDataDetail);
          setDataBahan(mapDataItem(getDataDetail?.bahan));
          setDataUpah(mapDataItem(getDataDetail?.upah));
          setDataAlat(mapDataItem(getDataDetail?.alat_mesin));
          setDataSubkon(mapDataItem(getDataDetail?.subkon));
          setDataOverhead(mapDataItem(getDataDetail?.overhead), "overhead");
          setDataHistory({
            bahan: mapDataHistory(getDataDetail?.histori_bahan),
            upah: mapDataHistory(getDataDetail?.histori_upah),
            alat: mapDataHistory(getDataDetail?.histori_alat_mesin),
            subkon: mapDataHistory(getDataDetail?.histori_subkon),
            overhead: mapDataHistory(getDataDetail?.histori_overhead),
            waste: getDataDetail?.histori_waste,
          });
          setFetching({
            loading: false,
            error: false,
          });
        })
      )
      .catch(() => {
        setFetching({
          loading: false,
          error: true,
        });
      });

  // Fetch dropdown waste
  const getDropdown = () => {
    Axios.all([
      RealisasiProduksiApi.getDropdown({ tipe: "item_waste" }),
      RealisasiProduksiApi.getDropdown({ tipe: "gudang" }),
    ])
      .then(
        Axios.spread((data, gudang) => {
          const mapWaste = data?.data?.data?.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: v.nama_item,
          }));
          const mapGudangWaste = gudang?.data?.data?.map((v) => ({
            ...v,
            value: v.id_gudang,
            label: v.nama_gudang,
          }));
          setDropdownWaste(mapWaste);
          setDropdownGudang(mapGudangWaste);
        })
      )
      .catch(() => window.alert("Gagal mengambil data dropdown"));
  };

  useEffect(() => {
    setNavbarTitle("Realisasi Produksi");
    getInitialData();
    getDropdown();
  }, []);

  if (fetching.loading || fetching.error) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-end">
          <b className="px-2">Informasi Produksi</b>
          <BackButton size="sm" onClick={history.goBack} />
        </div>
        <DataStatus
          loading={fetching.loading}
          text={fetching.loading ? "Memuat data . . ." : "Data gagal dimuat"}
        />
      </>
    );
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ isSubmitting, handleSubmit, values }) => (
        <>
          {/* Title */}
          <div className="d-flex justify-content-between align-items-end">
            <b className="px-2">Informasi Produksi</b>
            <BackButton size="sm" onClick={history.goBack} />
          </div>

          {/* Info Section */}
          <div className="mt-2 p-3 bg-white rounded border">
            <InfoSection
              setDataOverhead={setDataOverhead}
              action="CREATE"
              data={dataDetail}
            />
          </div>

          {/* Tabs Section */}
          <div className="mt-4 mb-1">
            <b className="px-2">Data Realisasi Produksi</b>
          </div>
          <Tabs
            activeKey={tab}
            onSelect={(key) => setTab(key)}
            transition={false}
          >
            <Tab
              eventKey="bahan"
              title="Realisasi Bahan"
              children={
                <TabItem
                  type="Bahan"
                  dataRealisasi={dataBahan}
                  dataHistory={dataHistory?.bahan}
                  setDataRealisasi={setDataBahan}
                />
              }
            />
            <Tab
              eventKey="upah"
              title="Realisasi Upah"
              children={
                <TabItem
                  type="Upah"
                  dataRealisasi={dataUpah}
                  dataHistory={dataHistory?.upah}
                  setDataRealisasi={setDataUpah}
                />
              }
            />
            <Tab
              eventKey="alat"
              title="Realisasi Alat & Mesin"
              children={
                <TabItem
                  type="Alat & Mesin"
                  dataRealisasi={dataAlat}
                  dataHistory={dataHistory?.alat}
                  setDataRealisasi={setDataAlat}
                />
              }
            />
            <Tab
              eventKey="subkon"
              title="Realisasi Subkon"
              children={
                <TabItem
                  type="Subkon"
                  dataRealisasi={dataSubkon}
                  dataHistory={dataHistory?.subkon}
                  setDataRealisasi={setDataSubkon}
                />
              }
            />
            <Tab
              eventKey="overhead"
              title="Realisasi Overhead"
              children={
                <TabItem
                  type="Overhead"
                  dataRealisasi={dataOverhead}
                  dataHistory={dataHistory?.overhead}
                  setDataRealisasi={setDataOverhead}
                />
              }
            />
            <Tab
              eventKey="waste"
              title="Realisasi Sisa Produksi"
              children={
                <TabWaste
                  dataWaste={dataWaste}
                  dataHistory={dataHistory?.waste}
                  setDataWaste={setDataWaste}
                  dropdownWaste={dropdownWaste}
                  dropdownGudang={dropdownGudang}
                />
              }
            />
          </Tabs>

          <div className="mt-3">
            <AlertAlreadyStockOpname tanggal={values?.tgl_realisasi_produksi} />
          </div>

          <div className="text-right mt-3 mb-5">
            <ActionButton
              text="Simpan"
              className="px-4"
              loading={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </Formik>
  );
};
