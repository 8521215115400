import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  ButtonBack,
  ButtonCreate,
  ButtonDetail,
} from "components2";

import PermintaanAsetApi from "../__CloseProduksiAsetApi__";
import { DatePicker, InfoItemVertical } from "components";
import { TabKomponenProduksiAset } from "./TabKomponenProduksiAset";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateConvert, RupiahConvert } from "utilities";
import TabGambar from "./TabGambar";
import TabFile from "./TabFile";
import { ModalRegisterItemAset } from "./ModalRegisterItemAset";
import {
  formInitialValuesRegisterAset,
  formSubmitValueMapper,
} from "../__CloseProduksiAsetUtils__";
import { AlertInformasi } from "./AlertInformasi";
import CloseProduksiAsetApi from "../__CloseProduksiAsetApi__";

const InfoProduksiAset = () => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="Tgl. Close Produksi Aset"
          text={
            values?.tgl_close_produksi_aset
              ? DateConvert(new Date(values?.tgl_close_produksi_aset)).detail
              : "-"
          }
        />
      </Col>
      <Col>
        <InfoItemVertical
          label="No. Close Produksi Aset"
          text={values?.no_close_produksi_aset ?? "-"}
        />
      </Col>
    </Row>
  );
};

export const FormProduksiAsetContent = ({ action }) => {
  const [modalRegisterAsetConfig, setModalRegisterAsetConfig] = useState({
    show: false,
    id: "",
  });
  const { values, handleSubmit, setValues, errors, touched } =
    useFormikContext();
  const history = useHistory();

  const { isFetching: loadingKode } = useQuery(
    ["maintenanceRequest", "kode", values?.tgl_close_produksi_aset],
    () =>
      PermintaanAsetApi.generateNumber({
        tanggal: values?.tgl_close_produksi_aset,
      }).then((val) =>
        setValues({ ...values, no_close_produksi_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_close_produksi_aset && action === "create",
    }
  );

  const closeProduksiAset = useMutation((data) =>
    CloseProduksiAsetApi.create(data)
  );

  return (
    <Fragment>
      {action !== "confirm" && (
        <div className="d-flex justify-content-between mb-2">
          <small className="font-weight-bold">
            {action === "create"
              ? "Tambah Data Close Produksi Aset"
              : action === "update"
              ? "Ubah Data Close Produksi Aset"
              : "Detail Data Close Produksi Aset"}
          </small>

          <ButtonBack
            size="sm"
            onClick={() => history.push("/transaksi/close-produksi-aset")}
          />
        </div>
      )}
      <Card>
        <Card.Body>
          <>
            <Row>
              <Col lg="6">
                <InfoItemHorizontal
                  label="Tgl. Permintaan Aset"
                  text={
                    values?.tgl_permintaan_aset
                      ? DateConvert(new Date(values?.tgl_permintaan_aset))
                          .detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Permintaan Aset"
                  text={values?.no_permintaan_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Tgl. Produksi Aset"
                  text={
                    values?.tgl_produksi_aset
                      ? DateConvert(new Date(values?.tgl_produksi_aset)).detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Produksi Aset"
                  text={values?.no_produksi_aset ?? "-"}
                />
              </Col>
              <Col lg="6">
                <InfoItemHorizontal
                  label="Grup Aset"
                  text={values?.nama_grup_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Kategori Aset"
                  text={values?.nama_kategori_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Jenis Aset"
                  text={values?.nama_jenis_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Item Aset Yang Diminta"
                  text={values?.nama_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Keperluan"
                  text={values?.keperluan ?? "-"}
                />
              </Col>
            </Row>
            <Card className="mt-3">
              <Tab.Container defaultActiveKey="tabGambar">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabFile">File</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Tab.Content>
                  <Tab.Pane eventKey="tabGambar">
                    <div className="m-2">
                      <TabGambar readOnly />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabFile">
                    <div className="m-2">
                      <TabFile readOnly />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>
            {action !== "confirm" && (
              <InfoItemVertical
                className="mt-3"
                label="Detail Aset Yang Diproduksi"
                text={
                  <ButtonDetail
                    icon
                    onClick={() =>
                      setModalRegisterAsetConfig({
                        show: true,
                        id: values?.id_item_yang_terproduksi,
                      })
                    }
                  />
                }
              />
            )}
            <hr />
            {action === "detail" || action === "confirm" ? (
              <InfoProduksiAset />
            ) : (
              <Row>
                <Col lg="6" md="6">
                  <DatePicker
                    label="Tgl. Close Produksi Aset"
                    selected={
                      values?.tgl_close_produksi_aset
                        ? new Date(values?.tgl_close_produksi_aset)
                        : null
                    }
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tgl_close_produksi_aset: DateConvert(new Date(val))
                          .default,
                      })
                    }
                    error={
                      errors.tgl_close_produksi_aset &&
                      touched.tgl_close_produksi_aset &&
                      true
                    }
                    errorText={
                      errors.tgl_close_produksi_aset &&
                      touched.tgl_close_produksi_aset &&
                      errors.tgl_close_produksi_aset
                    }
                  />
                </Col>
                <Col lg="6" md="6">
                  <Input
                    label="No. Close Produksi Aset"
                    disabled
                    placeholder="Pilih tanggal terlebih dahulu!!!"
                    loading={loadingKode}
                    value={values?.no_close_produksi_aset}
                  />
                </Col>
              </Row>
            )}
          </>

          <hr />
          <TabKomponenProduksiAset action={action} />
        </Card.Body>
      </Card>
      {action !== "detail" && <AlertInformasi />}

      {modalRegisterAsetConfig.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesRegisterAset()}
        >
          <ModalRegisterItemAset
            setModalConfig={setModalRegisterAsetConfig}
            modalConfig={modalRegisterAsetConfig}
            action="detail"
          />
        </Formik>
      )}

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          {action === "confirm" ? (
            <ButtonCreate
              text="Close Produksi Aset"
              variant="danger"
              tooltipText="closed"
              onClick={() => {
                closeProduksiAset
                  .mutateAsync(formSubmitValueMapper(values))
                  .then(() =>
                    history.push("/transaksi/close-produksi-aset", {
                      alert: {
                        variant: "primary",
                        show: true,
                        text: "Data berhasil disimpan!!!",
                      },
                    })
                  )
                  .catch(
                    (err) => {}
                    // setAlertConfig({
                    //   variant: "danger",
                    //   show: true,
                    //   text: "Data gagal disimpan!!!",
                    // })
                  );
              }}
            />
          ) : (
            <ButtonCreate
              text="Close Produksi Aset"
              variant="danger"
              tooltipText="closed"
              onClick={handleSubmit}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};
