export const defaultColors = [
  'rgb(0,135,255)',
  'rgb(15,184,128)',
  'rgb(255,216,102)',
  'rgb(255,51,51)',
  'rgb(0,255,255)',
  'rgb(255,128,0)',
  'rgb(221, 160, 221)',
  'rgb(176, 224, 230)',
  'rgb(144, 238, 144)',
  'rgb(255, 228, 181)',
  'rgb(255, 192, 203)',
  'rgb(175, 238, 238)',
  'rgb(255, 218, 185)',
  'rgb(230, 230, 250)',
  'rgb(152, 251, 152)',
  'rgb(255, 240, 245)',
  'rgb(255, 250, 205)',
  'rgb(221, 211, 255)',
  'rgb(176, 224, 230)',
  'rgb(255, 228, 196)',
  'rgb(240, 230, 140)',
];

export const generateMonthYearRange = ({
  startDate,
  endDate,
  formatMonth = 'long',
}) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const lastDate = new Date(end.getFullYear(), end.getMonth() + 1, 0); // last date form endDate month year

  const monthYearRange = [];

  let currentDate = new Date(start);

  while (currentDate <= lastDate) {
    const month = currentDate.toLocaleString('defafult', {
      month: formatMonth,
    });
    const year = currentDate.getFullYear();
    monthYearRange.push(`${month} ${year}`);

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return monthYearRange;
};

export const calculatePercentage = (total, value) => {
  if (!total || !value) return 0;

  return Math.round((value / total) * 100);
};
