import { ButtonBack, ButtonCancel } from "@bhawanadevteam/react-core";
import { InfoItemVertical } from "components";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

export const ModalDetail = ({ setModalConfig, modalConfig, tab }) => {
  return (
    <Modal
      show={modalConfig?.show}
      onHide={() => setModalConfig({ ...modalConfig, data: {}, show: false })}
    >
      <Modal.Header closeButton>Detail Permintaan Produksi Aset</Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Produksi Aset"
              text={
                DateConvert(new Date(modalConfig?.data?.tgl_produksi_aset))
                  .detail
              }
            />
            <InfoItemVertical
              label="Tgl. Permintaan Produksi Aset"
              text={
                DateConvert(
                  new Date(
                    modalConfig?.data?.[`tgl_permintaan_produksi_aset_${tab}`]
                  )
                ).detail
              }
            />
            <InfoItemVertical
              label="Kode Item"
              text={modalConfig?.data?.kode_item ?? "-"}
            />
            <InfoItemVertical
              label="Qty. Permintaan"
              text={`${
                DecimalConvert(
                  parseFloat(modalConfig?.data?.qty_permintaan_produksi ?? 0)
                ).getWithComa
              } ${modalConfig?.data?.nama_satuan ?? "-"}`}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Produksi Aset"
              text={modalConfig?.data?.no_produksi_aset ?? "-"}
            />
            <InfoItemVertical
              label="No. Permintaan Produksi Aset"
              text={
                modalConfig?.data?.[`no_permintaan_produksi_aset_${tab}`] ?? "-"
              }
            />
            <InfoItemVertical
              label="Nama Item"
              text={modalConfig?.data?.nama_item ?? "-"}
            />
            <InfoItemVertical
              label="Qty. Telah Ditransfer"
              text={`${
                DecimalConvert(parseFloat(modalConfig?.data?.qty_transfer ?? 0))
                  .getWithComa
              } ${modalConfig?.data?.nama_satuan ?? "-"}`}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonCancel text="Tutup" tooltipText="Tutup" />
      </Modal.Footer>
    </Modal>
  );
};
