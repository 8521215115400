import { JobMixApi } from "api";
import {
  ActionButton,
  Alert,
  DataStatus,
  InfoItemHorizontal,
} from "components";
import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const ModalAdendum = ({ setModalConfig, modalConfig }) => {
  const [dataAdendum, setDataAdendum] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "success",
    show: false,
    text: "",
  });

  const getDetailAdendum = () => {
    setModalLoading(true);
    JobMixApi.getDetailAdendum({ id_sales_order: modalConfig.id })
      .then(({ data }) => setDataAdendum(data.data))
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setModalLoading(false);
      });
  };

  useEffect(() => {
    getDetailAdendum();
    return () => {};
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
        size="lg"
      >
        <Modal.Header closeButton>Detail SPK</Modal.Header>
        <Modal.Body>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
          />
          {modalLoading ? (
            <DataStatus loading text="Memuat data..." />
          ) : (
            <Row>
              <Col lg="6">
                <InfoItemHorizontal
                  width={120}
                  label="Tgl. SPK"
                  text={
                    DateConvert(new Date(dataAdendum.tgl_spk))?.detail ?? "-"
                  }
                />
                <InfoItemHorizontal
                  width={120}
                  label="No. SPK"
                  text={dataAdendum.no_spk ?? "-"}
                />
                <InfoItemHorizontal
                  width={120}
                  label="Tgl. SOSPK"
                  text={
                    DateConvert(new Date(dataAdendum?.tgl_sales_order_spk))
                      ?.detail ?? "-"
                  }
                />
                <InfoItemHorizontal
                  width={120}
                  label="No. SOSPK"
                  text={dataAdendum.no_sales_order_spk ?? "-"}
                />
              </Col>
              <Col lg="6">
                <InfoItemHorizontal
                  width={120}
                  label="Tgl. Adendum SPK"
                  text={
                    DateConvert(new Date(dataAdendum.tgl_spk_adendum))
                      ?.detail ?? "-"
                  }
                />
                <InfoItemHorizontal
                  width={120}
                  label="No. Adendum SPK"
                  text={dataAdendum.no_spk_adendum ?? "-"}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            size="sm"
            text="Kembali"
            variant="outline-secondary"
            onClick={() => setModalConfig({ ...modalConfig, show: false })}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};
