// Form
import { JobMixDesignApi } from "api"

// Components
import { useFormikContext } from "formik"

// API
import { SelectSearch } from "components"

const SalinAnalisa = ({ dropdown }) => {
	// Hooks
	const { setFieldValue } = useFormikContext()

	const onSalinAnalisaChange = value => {
    JobMixDesignApi.single_analisa({ 
      id_item_atribut: value.value 
    }).then(res => {
      setFieldValue('analisa', res.data.data)
    }).catch(() => alert('Data gagal dimuat'))
  }

	return (
		<SelectSearch
			label="Salin data analisa barang jadi"
			placeholder="Pilih untuk salin data analisa barang jadi"
			onChange={val => onSalinAnalisaChange(val)}
			option={dropdown.barang_jadi}
		/>
	)
}

export default SalinAnalisa