const getStatusProsesProduksi = (value) => {
	switch (value) {
		case "Close":
			return {
				variant: "outline-secondary",
				label: "CLOSE",
				level: 2
			}
		case "Open":
			return {
				variant: "outline-warning",
				label: "OPEN",
				level: 3
			}
		default:
			return {
				variant: "outline-warning",
				label: "OPEN",
				level: 1
			}
	}
}

export default getStatusProsesProduksi