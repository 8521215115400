import {
  DataStatus,
  NumberFormat,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

export const TableKomponenProduksi = ({ action, tab, section }) => {
  const { values, setValues } = useFormikContext();
  const checkTab = tab === "alatMesin" ? "alat mesin" : tab;

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-capitalize">
              Informasi {section} {checkTab} Produksi Aset
            </ThFixed>
            <Th className="text-capitalize">Item {checkTab}</Th>
            {tab === "upah" || tab === "subkon" ? <Th>Vendor</Th> : ""}
            <Th>Satuan</Th>
            {section === "transfer" && <Th>Qty. Transfer</Th>}
            <Th>Qty. Realisasi</Th>
          </Tr>
        </Thead>
        <TBody>
          {values?.[section]?.[tab]?.length > 0 ? (
            values?.[section]?.[tab]?.map((val, ind) => {
              return (
                <Tr key={ind}>
                  <Td>{ind + 1}</Td>
                  <Td>
                    {section === "transfer" ? (
                      <>
                        <div>
                          {val?.tgl_transfer_produksi_aset
                            ? DateConvert(
                                new Date(val?.tgl_transfer_produksi_aset)
                              ).custom
                            : "-"}
                        </div>
                        <div>{val?.no_transfer_produksi_aset ?? ""}</div>
                      </>
                    ) : (
                      <>
                        <div>
                          {val?.tgl_realisasi_produksi_aset
                            ? DateConvert(
                                new Date(val?.tgl_realisasi_produksi_aset)
                              ).custom
                            : "-"}
                        </div>
                        <div>{val?.no_realisasi_produksi_aset ?? ""}</div>
                      </>
                    )}
                  </Td>
                  <Td>{val.nama_item ?? "-"}</Td>
                  {tab === "upah" || tab === "subkon" ? (
                    <Td>{val.nama_vendor ?? "-"}</Td>
                  ) : (
                    ""
                  )}
                  <Td>{val.nama_satuan ?? "-"}</Td>
                  {section === "transfer" && (
                    <Td width={150} className="text-right">
                      {
                        DecimalConvert(parseFloat(val.qty_transfer ?? 0))
                          .getWithComa
                      }
                    </Td>
                  )}
                  <Td width={150} className="text-right">
                    {section === "realisasi" ? (
                      DecimalConvert(parseFloat(val.qty_realisasi ?? 0))
                        .getWithComa
                    ) : (
                      <NumberFormat
                        placeholder="Masukan Qty. Realisasi"
                        value={parseFloat(val?.qty_realisasi ?? 0)}
                        className="text-right"
                        onChange={(e) => {
                          const max = parseFloat(val?.qty_ter_realisasi ?? 0);

                          if (max < e) {
                            setValues({
                              ...values,
                              transfer: {
                                ...values?.transfer,
                                [tab]: values?.transfer?.[tab]?.map((res, i) =>
                                  i === ind
                                    ? {
                                        ...res,
                                        qty_realisasi: val?.qty_realisasi,
                                      }
                                    : res
                                ),
                              },
                            });
                          } else {
                            setValues({
                              ...values,
                              transfer: {
                                ...values?.transfer,
                                [tab]: values?.transfer?.[tab]?.map((res, i) =>
                                  i === ind ? { ...res, qty_realisasi: e } : res
                                ),
                              },
                            });
                          }
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
