import { DataStatus, Table } from 'components'

// Cleave
import Cleave from "cleave.js/react"

const TableUpah = ({ dataUpah, setDataUpah, tipe }) => {
	const Th = props => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        padding: 0,
        fontSize: "14px"
      }}
    >
      {props.children}
    </th>
  )

  const Td = props => (
    <td
      {...props}
      className={`${props.className} p-1`}
      style={{
        ...props.style,
        fontSize: "13px"
      }}
    >
      {props.children}
    </td>
  )

	const updateQty = (value, index) => {
    const data = dataUpah.find((val, id) => id === index)
    data.qty_realisasi_upah = value
    
		const update = new Promise((resolve) => {
			setDataUpah(dataUpah.map((val, id) => id === index ? data : val))
			resolve()
		})

		update
			.then(() => document.getElementById(`upah-${index}`).focus())
  }

	return (
		<>
			<div className="pb-2">
        <b className="p-1">List Item Upah</b>
      </div>
			<Table>
				<thead className="bg-light">
					<tr>
						<Th className="py-2 text-center" style={{ width: 40 }}>No.</Th>
						<Th className="p-2 text-center" style={{ width: 80 }}>Kode Upah</Th>
						<Th className="p-2 text-center" style={{ width: 400 }}>Item Upah</Th>
						<Th className="p-1 text-center" style={{ width: 70 }}>Qty. Produksi</Th>
						<Th className="p-0 text-center" style={{ width: 70 }}>Qty. Realisasi Upah</Th>
					</tr>
				</thead>
				<tbody>
					{dataUpah.length > 0
						? dataUpah.map((val, index) => {
							return (
								<tr key={index}>
									<Td className="text-center">{index + 1}</Td>
									<Td>{val.kode_item}</Td>
									<Td>{val.nama_item}</Td>
									<Td className="text-right">{val.qty_produksi_baru} {val.kode_satuan}</Td>
									<Td>
										{tipe === 'realisasi' ? (
											<Cleave
												id={`upah-${index}`}
												options={{
													numeral: true,
													numeralThousandsGroupStyle: "thousand"
												}}
												value={val.qty_realisasi_upah}
												onChange={e => updateQty(e.target.rawValue, index)}
												className="form-control form-control-sm"
											/>
										) : (
											<div className='text-right'>
												{val.qty_realisasi_upah} {val.kode_satuan}
											</div>
										)}
									</Td>
								</tr>
						)
						})
						: (
							<tr>
								<Td colSpan={6}>
									<DataStatus text="Tidak ada data" />
								</Td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</>
	)
}

export default TableUpah