export const formInitialValues = (data) => {
  return Object({
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    no_produksi_aset: data?.no_produksi_aset ?? "",
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_aset ?? "",
    keperluan: data?.keperluan ?? "",
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    bahan: data?.bahan ?? [],
    upah: data?.upah ?? [],
    alatMesin: data?.alat_mesin ?? [],
    subkon: data?.subkon ?? [],
    overhead: data?.overhead ?? [],
  });
};
