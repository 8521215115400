import { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  SelectSearch,
  ApprovalStatusButton,
  Alert,
  NumberFormat,
  Table,
  Tr,
  ThFixed,
  Th,
  TBody,
  DataStatus,
  TdFixed,
  Td,
} from "components";
import { DateConvert, DecimalConvert } from "utilities";
import { PermintaanProduksiApi } from "api";
import { getTotalHistoryQtyPermintaan } from "../Utils";
import Thead from "components/Table/THead";
import { useHistory, useParams } from "react-router-dom";

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, "");
  const convert = newValue.substring(0, 10);

  return convert;
};

const ModalDetail = ({ data = {}, removeStatus = false }) => {
  return (
    <>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Tgl. Produksi"
            text={
              data?.tgl_produksi
                ? DateConvert(new Date(data.tgl_produksi)).detail
                : "-"
            }
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="No. Produksi"
            text={data?.no_produksi ?? "-"}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Tgl. Permintaan Produksi"
            text={
              data?.tgl_permintaan_produksi
                ? DateConvert(new Date(data.tgl_permintaan_produksi)).detail
                : "-"
            }
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="No. Permintaan Produksi"
            text={data?.no_permintaan_produksi ?? "-"}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Kode Item Permintaan"
            text={data?.kode_item ?? "-"}
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="Item Permintaan"
            text={data?.nama_item ?? "-"}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Qty. Permintaan Produksi"
            text={`${
              DecimalConvert(data?.qty_permintaan_produksi ?? 0).getWithComa
            } ${data?.satuan}`}
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="Qty. Telah Ditransfer"
            text={`${
              DecimalConvert(data?.qty_telah_ditransfer ?? 0).getWithComa
            } ${data?.satuan}`}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical label="Proyek" text={data?.nama_proyek ?? "-"} />
        </Col>
      </Row>
      {!removeStatus && (
        <Row>
          <Col md>
            <InfoItemVertical
              label="Status Permintaan Produksi"
              text={
                <div>
                  <ApprovalStatusButton
                    size="sm"
                    style={{ width: 100 }}
                    variant={
                      data?.status_permintaan === "batal"
                        ? "outline-danger"
                        : data?.status_permintaan === "diproses"
                        ? "outline-warning"
                        : "outline-success"
                    }>
                    {data?.status_permintaan === "batal"
                      ? "BATAL"
                      : data?.status_permintaan === "diproses"
                      ? "DIPROSES"
                      : "SELESAI"}
                  </ApprovalStatusButton>
                </div>
              }
            />
          </Col>
          <Col md></Col>
        </Row>
      )}
    </>
  );
};

const ModalConfirm = ({ data = {} }) => {
  return (
    <>
      <ModalDetail data={data} removeStatus />
      <div className="mt-3" />
      <Alert
        show={true}
        variant="danger"
        text='Data permintaan produksi akan bertatus "Batal" dan tidak bisa dibuatkan transfer produksi.'
      />
    </>
  );
};

const ModalForm = ({
  type,
  data = [],
  dataHistory = [],
  generateName,
  dataInfo = [],
}) => {
  const { values, errors, touched, setValues, setFieldValue } =
    useFormikContext();
  const [dataItemBuaso, setDataItemBuaso] = useState([]);
  const [loadingNomor, setLoadingNomor] = useState(true);
  const [loadingItem, setLoadingItem] = useState(true);

  const generateNomorHandler = (date) => {
    setLoadingNomor(true);

    PermintaanProduksiApi.getNomor({
      buaso: type,
      tanggal: date,
    })
      .then((res) => {
        setFieldValue(`no_permintaan_produksi_${type}`, res?.data?.data);
      })
      .catch(() => {
        setValues({
          ...values,
          [`tgl_permintaan_produksi_${type}`]: "",
          [`no_permintaan_produksi_${type}`]: "",
        });
      })
      .finally(() => {
        setLoadingNomor(false);
      });
  };

  const getDataItemBuaso = () => {
    setLoadingItem(true);

    const fetch = () =>
      type === "alat_mesin"
        ? PermintaanProduksiApi.getDropdown({ tipe: "item_aset" })
        : PermintaanProduksiApi.getDropdown({ tipe: "item_buaso_permintaan" });

    fetch()
      .then((res) => {
        const data = res?.data?.data
          ? res.data.data.map((val) => ({
              value:
                type === "alat_mesin" ? val?.id_item_aset : val.id_item_buaso,
              label:
                type === "alat_mesin"
                  ? val?.nama_item ?? "-"
                  : val.nama_item ?? "-",
            }))
          : [];

        setDataItemBuaso(data);
      })
      .catch(() => {
        setDataItemBuaso([]);
      })
      .finally(() => {
        setLoadingItem(false);
      });
  };

  useEffect(() => {
    values?.[`tgl_permintaan_produksi_${type}`] &&
      generateNomorHandler(values?.[`tgl_permintaan_produksi_${type}`]);
    getDataItemBuaso();
  }, []);

  return (
    <>
      <DatePicker
        label="Tgl. Permintaan Produksi"
        placeholder="Pilih tgl. permintaan produksi"
        selected={
          values?.[`tgl_permintaan_produksi_${type}`]
            ? new Date(values?.[`tgl_permintaan_produksi_${type}`])
            : undefined
        }
        onChange={(date) => {
          const parseDate = DateConvert(new Date(date)).default;
          setFieldValue(`tgl_permintaan_produksi_${type}`, parseDate);
          generateNomorHandler(parseDate);
        }}
        error={
          !!(
            errors?.[`tgl_permintaan_produksi_${type}`] &&
            touched?.[`tgl_permintaan_produksi_${type}`]
          )
        }
        errorText={
          !!(
            errors?.[`tgl_permintaan_produksi_${type}`] &&
            touched?.[`tgl_permintaan_produksi_${type}`]
          ) && errors?.[`tgl_permintaan_produksi_${type}`]
        }
      />
      <Table responsive>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ width: 100 }} className="p-1">
              Kode {generateName}
            </Th>
            <Th style={{ minWidth: 250 }} className="p-1">
              Item {generateName}
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Qty. Produksi
            </Th>
          </Tr>
        </Thead>
        <TBody>
          {data && data?.length > 0 ? (
            data.map((e, i) => {
              const qtyProduksi = e?.qty_produksi;

              return (
                <Tr key={i}>
                  <TdFixed>{i + 1}</TdFixed>
                  <Td>{e?.kode_item ?? "-"}</Td>
                  <Td>{e?.nama_item ?? "-"}</Td>
                  <Td textRight>
                    {type === "overhead"
                      ? e?.qty_produksi
                        ? DecimalConvert(qtyProduksi).getWithComa
                        : "-"
                      : DecimalConvert(parseFloat(e?.qty_produksi))
                          .getWithComa}{" "}
                    {e?.kode_satuan ?? ""}
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const ModalPermintaanOverhead = ({
  show,
  onHide,
  data = [],
  dataInfo = [],
  dataHistory = [],
  type = "",
  action,
  onCreateDataSuccess,
  onCreateDataError,
}) => {
  const { id } = useParams();
  const history = useHistory();

  console.log("bang", data);
  const generateName =
    type === "bahan"
      ? "Bahan"
      : type === "upah"
      ? "Upah"
      : type === "alat_mesin"
      ? "Alat & Mesin"
      : type === "subkon"
      ? "Subkon"
      : type === "overhead"
      ? "Overhead"
      : "";

  const formInitialValues = {
    [`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]:
      data?.id_item_buaso ?? data?.id_item_aset,
    [`tgl_permintaan_produksi_${type}`]: "",
    [`no_permintaan_produksi_${type}`]: "",
    id_jobmix: data?.id_jobmix,
    qty_permintaan_produksi: parseFloat(data?.qty_produksi ?? 0),
  };

  const formValidationSchema = Yup.object().shape({
    // [`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]:
    //   Yup.string().required("Item permintaan tidak boleh kosong"),
    [`tgl_permintaan_produksi_${type}`]: Yup.string().required(
      "Tgl permintaan tidak boleh kosong"
    ),
  });

  const handleRetry = async (ind, tgl) => {
    console.log("bruh", data[ind]);
    const finalValues = {
      // ...dataSaving,
      ...data[ind],
      qty_permintaan_produksi: data[ind]?.qty_produksi,
      tgl_permintaan_produksi_overhead: tgl,
    };

    await createPost(finalValues, ind, tgl);
  };

  const createPost = async (post, ind, tgl) => {
    try {
      // code to create the post using post data
      await PermintaanProduksiApi.saveOverhead(post).then((val) =>
        console.log(val)
      );
    } catch (err) {
      handleRetry(ind, tgl);
    }
  };

  const formSubmitHandler = (values) => {
    Promise.all(
      data.map((val, ind) => {
        const finalvalues = {
          ...values,
          ...val,
          qty_permintaan_produksi: val.qty_produksi,
        };
        createPost(finalvalues, ind, values.tgl_permintaan_produksi_overhead);
      })
    )
      .then(() => {
        history.goBack();
      })
      .finally(() => onHide());
    // Promise.all(
    //   data?.map((val) => {
    //     const finalValues = {
    //       ...val,
    //       ...values,
    //       id_jobmix: id,
    //       id_item_buaso: val.id_item_buaso,
    //       qty_permintaan_produksi: val.qty_produksi,
    //     };
    //     return PermintaanProduksiApi.saveOverhead(finalValues)
    //       .then((res) => {
    //         console.log(res);
    //         return {
    //           no_ref: val?.no_permintaan_produksi_overhead,
    //           ...res?.data,
    //         };
    //       })
    //       .catch((err) => ({
    //         no_ref: val?.no_permintaan_produksi_overhead,
    //         ...err?.response?.data,
    //       }));
    //   })
    // )
    //   .then((res) => {
    //     console.log("res", res);
    //     onCreateDataSuccess();
    //   })
    //   .finally(() => onHide());
    // saveData()
    //   .then(() => {
    //     onCreateDataSuccess();
    //   })
    //   .catch(() => {
    //     onCreateDataError();
    //   })
    //   .finally(() => {
    //     onHide();
    //   });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b>
          {action === "form"
            ? "Tambah Data Permintaan Produksi"
            : action === "detail"
            ? "Detail Data Permintaan Produksi"
            : action === "confirm" && "Konfirmasi"}
        </b>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={
          action === "confirm" ? undefined : formValidationSchema
        }
        onSubmit={formSubmitHandler}>
        {({ isSubmitting, handleSubmit }) => (
          <>
            <Modal.Body>
              {action === "form" ? (
                <ModalForm
                  type={type}
                  data={data}
                  dataInfo={dataInfo}
                  generateName={generateName}
                  dataHistory={dataHistory}
                />
              ) : action === "confirm" ? (
                <ModalConfirm
                  type={type}
                  data={data}
                  generateName={generateName}
                />
              ) : (
                action === "detail" && (
                  <ModalDetail
                    type={type}
                    data={data}
                    generateName={generateName}
                  />
                )
              )}
            </Modal.Body>
            <Modal.Footer>
              <ActionButton
                variant="outline-secondary"
                text={action === "form" ? "Batal" : "Kembali"}
                onClick={onHide}
              />
              {action === "detail" ? (
                ""
              ) : (
                <ActionButton
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  variant={action === "confirm" ? "danger" : "primary"}
                  text={action === "confirm" ? "Batalkan" : "Simpan"}
                />
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
