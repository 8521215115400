import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  ButtonBack,
  ButtonCreate,
  ButtonDetail,
} from "components2";
import { DatePicker, InfoItemVertical } from "components";
import { TabKomponenProduksiAset } from "./TabKomponenProduksiAset";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import TabGambar from "./TabGambar";
import TabFile from "./TabFile";
import RealisasiProduksiAsetApi from "../__RealisasiProduksiAsetApi__";
import { formInitialValuesRegisterAset } from "../__RealisasiProduksiAsetUtils__";
import { ModalRegisterItemAset } from "./ModalRegisterItemAset";
import { AlertAlreadyStockOpname } from "components/Alert";

const InfoProduksiAset = () => {
  const { values } = useFormikContext();
  const [modalRegisterAsetConfig, setModalRegisterAsetConfig] = useState({
    show: false,
    id: "",
  });

  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="Detail Aset Yang Diproduksi"
          text={
            <ButtonDetail
              icon
              disabled={!values?.id_item_yang_terproduksi}
              variant="primary"
              onClick={() =>
                setModalRegisterAsetConfig({
                  show: true,
                  id: values?.id_item_yang_terproduksi,
                })
              }
            />
          }
        />
      </Col>
      {modalRegisterAsetConfig.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesRegisterAset()}
        >
          <ModalRegisterItemAset
            setModalConfig={setModalRegisterAsetConfig}
            modalConfig={modalRegisterAsetConfig}
            action="detail"
          />
        </Formik>
      )}
    </Row>
  );
};

export const FormProduksiAsetContent = ({ action }) => {
  const { values, handleSubmit, handleChange, setValues, errors, touched } =
    useFormikContext();
  const history = useHistory();

  const { isFetching: loadingKode } = useQuery(
    ["maintenanceRequest", "kode", values?.tgl_realisasi_produksi_aset],
    () =>
      RealisasiProduksiAsetApi.generateNumber({
        tanggal: values?.tgl_realisasi_produksi_aset,
      }).then((val) =>
        setValues({ ...values, no_realisasi_produksi_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_realisasi_produksi_aset && action === "create",
    }
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === "create"
            ? "Tambah Data Produksi Aset"
            : action === "update"
            ? "Ubah Data Produksi Aset"
            : "Detail Data Produksi Aset"}
        </small>

        <ButtonBack
          size="sm"
          onClick={() => history.push("/transaksi/realisasi-produksi-aset")}
        />
      </div>
      <Card className="mb-3">
        <Card.Body>
          <>
            <Row>
              <Col lg="6">
                <InfoItemHorizontal
                  label="Tgl. Permintaan Aset"
                  text={
                    values?.tgl_permintaan_aset
                      ? DateConvert(new Date(values?.tgl_permintaan_aset))
                          .detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Permintaan Aset"
                  text={values?.no_permintaan_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Tgl. Produksi Aset"
                  text={
                    values?.tgl_produksi_aset
                      ? DateConvert(new Date(values?.tgl_produksi_aset)).detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Produksi Aset"
                  text={values?.no_produksi_aset ?? "-"}
                />
                {action === "detail" && (
                  <InfoItemHorizontal
                    label="Progress Realisasi s/d Saat Ini"
                    text={`${
                      DecimalConvert(parseFloat(values?.progress_saat_ini ?? 0))
                        .getWithComa
                    } %`}
                  />
                )}
              </Col>
              <Col lg="6">
                <InfoItemHorizontal
                  label="Grup Aset"
                  text={values?.nama_grup_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Kategori Aset"
                  text={values?.nama_kategori_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Jenis Aset"
                  text={values?.nama_jenis_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Item Aset Yang Diminta"
                  text={values?.nama_aset ?? "-"}
                />
                <InfoItemHorizontal
                  label="Keperluan"
                  text={values?.keperluan ?? "-"}
                />
              </Col>
            </Row>
            <Card className="mt-3">
              <Tab.Container defaultActiveKey="tabGambar">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabFile">File</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Tab.Content>
                  <Tab.Pane eventKey="tabGambar">
                    <div className="m-2">
                      <TabGambar readOnly />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabFile">
                    <div className="m-2">
                      <TabFile readOnly />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>
            <hr />
            {action === "detail" ? (
              <InfoProduksiAset />
            ) : (
              <Row>
                <Col lg="3" md="6">
                  <DatePicker
                    label="Tgl. Realisasi Produksi Aset"
                    selected={
                      values?.tgl_realisasi_produksi_aset
                        ? new Date(values?.tgl_realisasi_produksi_aset)
                        : null
                    }
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tgl_realisasi_produksi_aset: DateConvert(new Date(val))
                          .default,
                      })
                    }
                    error={
                      errors.tgl_realisasi_produksi_aset &&
                      touched.tgl_realisasi_produksi_aset &&
                      true
                    }
                    errorText={
                      errors.tgl_realisasi_produksi_aset &&
                      touched.tgl_realisasi_produksi_aset &&
                      errors.tgl_realisasi_produksi_aset
                    }
                  />
                </Col>
                <Col lg="3" md="6">
                  <Input
                    label="No. Realisasi Produksi Aset"
                    disabled
                    placeholder="Pilih tanggal terlebih dahulu!!!"
                    loading={loadingKode}
                    value={values?.no_realisasi_produksi_aset}
                  />
                </Col>
                <Col lg="3" md="6">
                  <Input
                    className="text-right"
                    label="Progress Realisasi s/d Saat Ini (%)"
                    disabled
                    value={
                      DecimalConvert(parseFloat(values?.progress_saat_ini ?? 0))
                        .getWithComa
                    }
                  />
                </Col>
                <Col lg="3" md="6">
                  <Input
                    label="Progress (%)"
                    className="text-right"
                    name="progress"
                    type="number"
                    value={
                      DecimalConvert(parseFloat(values?.progress ?? 0))
                        .getWithComa
                    }
                    onChange={handleChange}
                    error={errors.progress && touched.progress && true}
                    errorText={
                      errors.progress && touched.progress && errors.progress
                    }
                  />
                </Col>
              </Row>
            )}
          </>

          <hr />
          {action !== "detail" && (
            <TabKomponenProduksiAset
              action={action}
              section="transfer"
              title="Data Realisasi Produksi Aset"
            />
          )}
          <TabKomponenProduksiAset
            action={action}
            section="realisasi"
            title="History Realisasi Produksi Aset"
          />
        </Card.Body>
      </Card>

      <AlertAlreadyStockOpname tanggal={values?.tgl_realisasi_produksi_aset} />

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === "update" ? "success" : "primary"}
            onClick={handleSubmit}
          />
        </div>
      )}
    </Fragment>
  );
};
