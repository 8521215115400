import { DateConvert } from 'utilities';

export const formSubmitMapper = (data) => ({
  tgl_permintaan_produksi: DateConvert(new Date(data?.tgl_permintaan_produksi))
    .default,
  id_jobmix: data?.id_jobmix,
  no_jobmix: data?.no_jobmix,
  id_gudang_produksi: data?.id_gudang_produksi,
  permintaan_bahan: data?.bahan?.map((val) => ({
    id_item_buaso: val?.id_item_buaso,
    id_gudang: val?.id_gudang,
    qty_permintaan_produksi: val?.qty_produksi,
    id_satuan: val?.id_satuan,
  })),
  permintaan_upah: data?.upah?.map((val) => ({
    id_item_buaso: val?.id_item_buaso,
    id_gudang: val?.id_gudang,
    qty_permintaan_produksi: val?.qty_produksi,
    id_satuan: val?.id_satuan,
  })),
  permintaan_subkon: data?.subkon?.map((val) => ({
    id_item_buaso: val?.id_item_buaso,
    id_gudang: val?.id_gudang,
    qty_permintaan_produksi: val?.qty_produksi,
    id_satuan: val?.id_satuan,
  })),
  permintaan_overhead: data?.overhead?.map((val) => ({
    id_item_buaso: val?.id_item_buaso,
    id_gudang: val?.id_gudang,
    qty_permintaan_produksi: val?.qty_produksi,
    id_satuan: val?.id_satuan,
  })),
  permintaan_alat_mesin: data?.alat_mesin?.map((val) => ({
    id_item_aset: val?.id_item_buaso,
    id_gudang: val?.id_gudang,
    qty_permintaan_produksi: val?.qty_produksi,
    id_satuan: val?.id_satuan,
  })),
});
