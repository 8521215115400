// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DataStatus,
  UpdateButton,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  DeleteButton,
  DeleteModal,
  PopUpAlert,
  Switch,
  Select,
} from "components";
import { PageNumber } from "utilities";
import { Row, Col, ButtonGroup } from "react-bootstrap";

// API
import { PabrikApi } from "api";

const ListPabrik = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationFromBrowserState = location?.state?.pagination;

  // Title
  const title = "Pabrik";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });

  // STATE SEARCHING
  // const [searchKey, setSearchKey] = useState("");
  const [searchTime, setSearchTime] = useState(null);

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(paginationFromBrowserState?.page ?? 1);
  const [totalPage, setTotalPage] = useState(paginationFromBrowserState?.totalPage ?? 1);
  const [dataLength, setDataLength] = useState(paginationFromBrowserState?.dataLength ?? 10);
  const [dataCount, setDataCount] = useState(paginationFromBrowserState?.dataCount ?? 0);
  const [jenisPabrik, setJenisPabrik] = useState("");

  // ALERT STATE
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    // data: false,
    variant: "primary",
    text: "",
  });
  const [alertConfigPopUp, setAlertConfigPopUp] = useState({
    show: false,
    type: "success",
    text: "",
  });

  // DELETE DATA
  const [deleteData, setDeleteData] = useState({
    show: false,
    data: {
      id_item_buaso: "",
    },
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    const value = {
      page: page,
      dataLength: dataLength,
      q: searchConfig.key,
    };
    if (jenisPabrik !== "") {
      value.jenis_pabrik = jenisPabrik;
    }

    PabrikApi.page(value)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .finally(() => {
        // if (searchKey !== "") {
        //   setAlertConfigSearch({
        //     data: true,
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        // }
        setIsLoading(false);
      });
  };

  // SEARCH DATA SERBER
  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value
        });
        setAlertConfigPopUp({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    )

  }

  // fungsi show/ hide
  const changeDataStatus = (status, id_pabrik) => {
    setIsLoading(true);
    setAlertConfigPopUp({
      ...alertConfigPopUp,
      show: false,
    });

    const value = { id_pabrik };

    const onLoadedSuccess = () => {
      setAlertConfigPopUp({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
    };

    const onLoadedFailed = () => {
      setAlertConfigPopUp({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
    };

    status === true
      ? PabrikApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : PabrikApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPage(paginationFromBrowserState.page);
        setDataLength(paginationFromBrowserState.dataLength);
        setTotalPage(paginationFromBrowserState.totalPage);
        setDataCount(paginationFromBrowserState.dataCount);

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    checkPaginationHandler().finally(() => getData());
  }, [setNavbarTitle, jenisPabrik]);

  useEffect(() => {
    getData();
  }, [page, dataLength, searchConfig, jenisPabrik]);

  // Modal Hapus
  const HapusModal = () => {
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      PabrikApi.delete({ id_pabrik: deleteData.data.id_pabrik })
        .then(() =>
          setAlertConfigPopUp({
            show: true,
            type: "success",
            text: "Hapus data berhasil!",
          })
        )
        .catch(() =>
          setAlertConfigPopUp({
            show: true,
            type: "danger",
            text: "Hapus data gagal!",
          })
        )
        .finally(() => {
          // menutup modal
          setDeleteData({
            ...deleteData,
            show: false,
          });
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={deleteData.show}
        onHide={() =>
          setDeleteData({
            ...deleteData,
            show: false,
          })
        }
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Pabrik : {deleteData.data.kode_pabrik}</div>
        <div>Nama Pabrik : {deleteData.data.nama_pabrik}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Pabrik</ThFixed>
              <Th>Nama Pabrik</Th>
              <Th>Jenis Pabrik</Th>
              <Th>Lokasi Pabrik</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed className="align-middle">
                    <div className="d-flex justify-content-center">
                      <ButtonGroup>
                        <ReadButton
                          size="sm"
                          onClick={() =>
                            history.push(`/master/pabrik/detail/${val.id_pabrik}`, {
                              pagination: {
                                page: page,
                                totalPage: totalPage,
                                dataCount: dataCount,
                                dataLength: dataLength,
                              },
                            })
                          }
                        />
                        <UpdateButton
                          size="sm"
                          onClick={() =>
                            history.push(`/master/pabrik/ubah/${val.id_pabrik}`, {
                              pagination: {
                                page: page,
                                totalPage: totalPage,
                                dataCount: dataCount,
                                dataLength: dataLength,
                              },
                            })
                          }
                        />
                        <DeleteButton
                          size="sm"
                          onClick={() =>
                            setDeleteData({
                              show: true,
                              data: val,
                            })
                          }
                        />
                      </ButtonGroup>

                      <Switch
                        id={toString(index + 1)}
                        checked={val.is_hidden === false ? true : false}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_pabrik)}
                      />
                    </div>
                  </TdFixed>
                  <TdFixed className="align-middle">{val.kode_pabrik}</TdFixed>
                  <Td className="align-middle">{val.nama_pabrik}</Td>
                  <Td className="align-middle">{val.jenis_pabrik}</Td>
                  <Td className="align-middle">
                    {`${val.alamat_pabrik && val.alamat_pabrik + ","} ${
                      val.nama_desa && val.nama_desa + ","
                    } ${val.nama_kecamatan && val.nama_kecamatan + ","} ${
                      val.nama_kecamatan && val.nama_kecamatan + ","
                    } ${val.nama_kabupaten}`}
                  </Td>
                  <Td className="align-middle">{val.keterangan_pabrik}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataPage={
            dataCount <= 10
              ? data.length
              : data.map(
                  (res, index) => index === data.length - 1 && PageNumber(page, dataLength, index)
                )
          }
          dataNumber={data.map((res, index) => index === 0 && PageNumber(page, dataLength, index))}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={onInputSearchChange}
                // onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center">
            {/* Filter Jenis Pabrik */}
            <div className="mr-2">
              <Select
                defaultValue={jenisPabrik}
                onChange={(e) => setJenisPabrik(e.target.value)}
                size="sm"
                noMargin
              >
                <option value="">Semua Data</option>
                <option value="Mobile Factory">Mobile Factory</option>
                <option value="Fixed Factory">Fixed Factory</option>
              </Select>
            </div>

            {/* Button Tambah */}
            <CreateButton
              onClick={() =>
                history.push("/master/pabrik/tambah", {
                  pagination: {
                    page: page,
                    totalPage: totalPage,
                    dataCount: dataCount,
                    dataLength: dataLength,
                  },
                })
              }
            />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() =>
          setAlertConfigSearch({
            ...alertConfigSearch,
            data: false,
          })
        }
      />
      <PopUpAlert
        show={alertConfigPopUp.show}
        type={alertConfigPopUp.type}
        title={alertConfigPopUp.text}
        confirmButtonVariant={alertConfigPopUp.type}
        onHide={() =>
          setAlertConfigPopUp({
            ...alertConfigPopUp,
            show: false,
          })
        }
        onConfirm={() =>
          setAlertConfigPopUp({
            ...alertConfigPopUp,
            show: false,
          })
        }
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <HapusModal />
    </CRUDLayout>
  );
};

export default ListPabrik;
