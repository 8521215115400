export const formInitialValues = (data, action) => {
  return Object({
    id_item_yang_terproduksi: data?.id_item_yang_terproduksi ?? undefined,
    tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset ?? undefined,
    no_realisasi_produksi_aset: data?.no_realisasi_produksi_aset ?? "",
    tgl_permintaan_produksi_aset:
      data?.tgl_permintaan_produksi_aset ?? undefined,
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    no_produksi_aset: data?.no_produksi_aset ?? "",
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_aset ?? "",
    keperluan: data?.keperluan ?? "",
    progress_saat_ini: data?.progress ?? "",
    progress: 0,
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    transfer: {
      bahan:
        data?.transfer?.bahan?.length > 0
          ? data?.transfer?.bahan?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_bahan ?? undefined,
              id_item: val?.id_item_bahan ?? undefined,
              tgl_transfer_produksi_aset:
                val?.tgl_transfer_produksi_aset_bahan ?? "",
              no_transfer_produksi_aset:
                val?.no_transfer_produksi_aset_bahan ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              qty_ter_realisasi: val?.qty_realisasi ?? 0,
              id_satuan: val?.id_satuan ?? undefined,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : [],
      upah:
        data?.transfer?.upah?.length > 0
          ? data?.transfer?.upah?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_upah ?? undefined,
              id_item: val?.id_item_upah ?? undefined,
              tgl_transfer_produksi_aset:
                val?.tgl_transfer_produksi_aset_upah ?? "",
              no_transfer_produksi_aset:
                val?.no_transfer_produksi_aset_upah ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              qty_ter_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_vendor: val?.nama_vendor ?? "",
            }))
          : [],
      alatMesin:
        data?.transfer?.alat_mesin?.length > 0
          ? data?.transfer?.alat_mesin?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_alat_mesin ?? undefined,
              id_item: val?.id_item_alat_mesin ?? undefined,
              tgl_transfer_produksi_aset:
                val?.tgl_transfer_produksi_aset_alat_mesin ?? "",
              no_transfer_produksi_aset:
                val?.no_transfer_produksi_aset_alat_mesin ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              qty_ter_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : [],
      subkon:
        data?.transfer?.subkon?.length > 0
          ? data?.transfer?.subkon?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_subkon ?? undefined,
              id_item: val?.id_item_subkon ?? undefined,
              tgl_transfer_produksi_aset:
                val?.tgl_transfer_produksi_aset_subkon ?? "",
              no_transfer_produksi_aset:
                val?.no_transfer_produksi_aset_subkon ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              qty_ter_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_vendor: val?.nama_vendor ?? "",
            }))
          : [],
      overhead:
        data?.transfer?.overhead?.length > 0
          ? data?.transfer?.overhead?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_overhead ?? undefined,
              id_item: val?.id_item_overhead ?? undefined,
              tgl_transfer_produksi_aset:
                val?.tgl_transfer_produksi_aset_overhead ?? "",
              no_transfer_produksi_aset:
                val?.no_transfer_produksi_aset_overhead ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              qty_ter_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : [],
      sisaProduksi: [],
    },
    realisasi: {
      bahan:
        action === "detail"
          ? data?.bahan?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_bahan ?? undefined,
              id_item: val?.id_item_bahan ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              id_satuan: val?.id_satuan ?? undefined,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : data?.realisasi?.bahan?.length > 0
          ? data?.realisasi?.bahan?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_bahan ?? undefined,
              id_item: val?.id_item_bahan ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              id_satuan: val?.id_satuan ?? undefined,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : [],
      upah:
        action === "detail"
          ? data?.upah?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_upah ?? undefined,
              id_item: val?.id_item_upah ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_vendor: val?.nama_vendor ?? "",
            }))
          : data?.realisasi?.upah?.length > 0
          ? data?.realisasi?.upah?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_upah ?? undefined,
              id_item: val?.id_item_upah ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_vendor: val?.nama_vendor ?? "",
            }))
          : [],
      alatMesin:
        action === "detail"
          ? data?.alat_mesin?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_alat_mesin ?? undefined,
              id_item: val?.id_item_alat_mesin ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : data?.realisasi?.alat_mesin?.length > 0
          ? data?.realisasi?.alat_mesin?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_alat_mesin ?? undefined,
              id_item: val?.id_item_alat_mesin ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : [],
      subkon:
        action == "detail"
          ? data?.subkon?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_subkon ?? undefined,
              id_item: val?.id_item_subkon ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_vendor: val?.nama_vendor ?? "",
            }))
          : data?.realisasi?.subkon?.length > 0
          ? data?.realisasi?.subkon?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_subkon ?? undefined,
              id_item: val?.id_item_subkon ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_vendor: val?.nama_vendor ?? "",
            }))
          : [],
      overhead:
        action === "detail"
          ? data?.overhead?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_overhead ?? undefined,
              id_item: val?.id_item_overhead ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : data?.realisasi?.overhead?.length > 0
          ? data?.realisasi?.overhead?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_overhead ?? undefined,
              id_item: val?.id_item_overhead ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
            }))
          : [],
      sisaProduksi:
        action === "detail"
          ? data?.sisa_produksi?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_overhead ?? undefined,
              id_item: val?.id_item_overhead ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_gudang: val?.nama_gudang ?? "",
              id_satuan: val?.id_satuan ?? "",
              id_gudang: val?.id_gudang ?? "",
            }))
          : data?.realisasi?.sisa_produksi?.length > 0
          ? data?.realisasi?.sisa_produksi?.map((val) => ({
              id_transfer_produksi_aset:
                val?.id_transfer_produksi_aset_overhead ?? undefined,
              id_item: val?.id_item_overhead ?? undefined,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? "",
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_transfer: val?.qty_transfer ?? 0,
              qty: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nama_gudang: val?.nama_gudang ?? "",
              id_satuan: val?.id_satuan ?? "",
              id_gudang: val?.id_gudang ?? "",
            }))
          : [],
    },
  });
};

export const formInitialValuesRegisterAset = (data) => {
  return Object({
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? "",
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    tgl_produksi_aset: data?.tgl_produksi_aset ?? "",
    tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset ?? undefined,
    tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset ?? "",
    keperluan: data?.keperluan ?? "",
    nama_entitas_aset: data?.nama_entitas_aset ?? "",
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_unit_organisasi: data?.nama_unit_organisasi ?? "",
    id_entitas_aset: data?.id_entitas_aset ?? undefined,
    id_grup_aset: data?.id_grup_aset ?? undefined,
    id_kategori_aset: data?.id_kategori_aset ?? undefined,
    id_jenis_aset: data?.id_jenis_aset ?? undefined,
    id_unit_organisasi: data?.id_unit_organisasi ?? undefined,
    nama_item_aset: data?.nama_item_aset ?? "",
    harga_perolehan: data?.harga_perolehan ?? 0,
    id_penerimaan_aset: data?.id_penerimaan_aset ?? undefined,
    id_kondisi_aset: data?.id_kondisi_aset ?? undefined,
    nama_kondisi_aset: data?.nama_kondisi_aset ?? "",
    id_karyawan: data?.id_karyawan ?? undefined,
    nama_karyawan: data?.nama_karyawan ?? "",
    id_provinsi: data?.id_provinsi ?? undefined,
    nama_provinsi: data?.nama_provinsi ?? "",
    id_kabupaten: data?.id_kabupaten ?? undefined,
    nama_kabupaten: data?.nama_kabupaten ?? "",
    id_kecamatan: data?.id_kecamatan ?? undefined,
    nama_kecamatan: data?.nama_kecamatan ?? "",
    id_desa: data?.id_desa ?? undefined,
    nama_desa: data?.nama_desa ?? "",
    detail_lokasi: data?.detail_lokasi ?? "",
    kode_item_aset: data?.kode_item_aset ?? "",
    bulan_perolehan: data?.bulan_perolehan ?? undefined,
    tahun_perolehan: data?.tahun_perolehan ?? undefined,
    id_satuan_pakai: data?.id_satuan_pakai ?? undefined,
    nama_satuan_pakai: data?.nama_satuan_pakai ?? "",
    id_pabrikan: data?.id_pabrikan ?? undefined,
    nama_pabrikan: data?.nama_pabrikan ?? "",
    no_sertifikat: data?.no_sertifikat ?? "",
    plat_nomor: data?.plat_nomor ?? "",
    no_seri: data?.no_seri ?? "",
    disewa: data?.disewa ?? false,
    produksi: data?.produksi ?? false,
    keterangan: data?.keterangan ?? "",
    qr_code: data?.qr_code ?? "",
    barcode: data?.barcode ?? "",
  });
};
