// React
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

// Component
import { Card, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  Th,
  TBody,
  DeleteButton,
  TdFixed,
  Td,
  SelectSearch,
  PopUpAlert,
  DataStatus,
  THead,
  Tr,
  ThFixed,
  ActionButton,
} from "components";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { PabrikApi } from "api";

// Icon
import { IoAddOutline } from "react-icons/io5";

const CRUDPabrik = ({ setNavbarTitle }) => {
  //  VARIABLE
  const title = "Pabrik";
  const history = useHistory();
  const location = useLocation();
  const routerState = location.state;
  const { tipe, id_pabrik } = useParams();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE MENAMPUNG DATA
  const [data, setData] = useState({
    id_pabrik: "",
    kode_pabrik: "",
    nama_pabrik: "",
    jenis_pabrik: "",
    keterangan_pabrik: "",
    id_provinsi: "",
    id_kabupaten: "",
    id_kecamatan: "",
    id_desa: "",
    alamat_pabrik: "",
    id_unit_produksi: "",
  });
  const [dataAnalisa, setDataAnalisa] = useState([]);
  const [dataAtribut, setDataAtribut] = useState({
    jenis_pabrik: ["Mobile Factory", "Fixed Factory"],
    provinsi: [],
    kabupaten: [],
    kecamatan: [],
    desa: [],
    gudang: [],
    unit_produksi: [],
    no_baru: "",
  });
  const [loadingAtribut, setLoadingAtribut] = useState({
    provinsi: false,
    kabupaten: false,
    kecamatan: false,
    desa: false,
    gudang: false,
    unit_produksi: false,
  });

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: "success",
    text: "",
    id_pabrik: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getAtribut = (data) => {
    // setIsLoading(true)
    setLoadingAtribut({
      provinsi: true,
      kabupaten: true,
      kecamatan: true,
      desa: true,
      gudang: true,
      unit_produksi: true,
    });

    Axios.all([
      PabrikApi.no_baru(),
      PabrikApi.mapping_pabrik_gudang_dropdown({ tipe: "gudang" }),
      PabrikApi.dropdown({ tipe: "provinsi" }),
      PabrikApi.dropdown({ tipe: "unit_produksi" }),
      data.id_provinsi &&
        PabrikApi.dropdown({
          tipe: "kabupaten",
          id_provinsi: data.id_provinsi,
        }),
      data.id_kabupaten &&
        PabrikApi.dropdown({
          tipe: "kecamatan",
          id_kabupaten: data.id_kabupaten,
        }),
      data.id_kecamatan &&
        PabrikApi.dropdown({
          tipe: "desa",
          id_kecamatan: data.id_kecamatan,
        }),
    ])
      .then(
        Axios.spread((no_baru, gudang, provinsi, unit_produksi, kabupaten, kecamatan, desa) => {
          setDataAtribut({
            ...dataAtribut,
            provinsi: provinsi.data.data,
            kabupaten: data.id_provinsi ? kabupaten.data.data : [],
            kecamatan: data.id_kabupaten ? kecamatan.data.data : [],
            desa: data.id_kecamatan ? desa.data.data : [],
            no_baru: no_baru.data.data,
            gudang: gudang.data.data,
            unit_produksi: unit_produksi.data.data,
          });
        })
      )
      .finally(() => {
        setLoadingAtribut({
          provinsi: false,
          kabupaten: false,
          kecamatan: false,
          desa: false,
          gudang: false,
          unit_produksi: false,
        });

        setIsLoading(false);
      });
  };

  const getAnalisa = () => {
    PabrikApi.mapping_pabrik_gudang({ id_pabrik }).then((res) => setDataAnalisa(res.data.data));
  };

  const getKabupaten = (value, values, setValues) => {
    setValues({
      ...values,
      id_provinsi: value,
      id_kabupaten: "",
      id_kecamatan: "",
      id_desa: "",
    });

    setLoadingAtribut({
      ...loadingAtribut,
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    PabrikApi.dropdown({
      tipe: "kabupaten",
      id_provinsi: value,
    })
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          kabupaten: res.data.data,
          kecamatan: [],
          desa: [],
        })
      )
      .catch(() =>
        setDataAtribut({
          ...dataAtribut,
          kabupaten: [],
          kecamatan: [],
          desa: [],
        })
      )
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getKecamatan = (value, values, setValues) => {
    setValues({
      ...values,
      id_kabupaten: value,
      id_kecamatan: "",
      id_desa: "",
    });

    setLoadingAtribut({
      ...loadingAtribut,
      kecamatan: true,
      desa: true,
    });

    PabrikApi.dropdown({
      tipe: "kecamatan",
      id_kabupaten: value,
    })
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          kecamatan: res.data.data,
          desa: [],
        })
      )
      .catch(() =>
        setDataAtribut({
          ...dataAtribut,
          kecamatan: [],
          desa: [],
        })
      )
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDesa = (value, values, setValues) => {
    setValues({
      ...values,
      id_kecamatan: value,
      id_desa: "",
    });

    setLoadingAtribut({
      ...loadingAtribut,
      desa: true,
    });

    PabrikApi.dropdown({
      tipe: "desa",
      id_kecamatan: value,
    })
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          desa: res.data.data,
        })
      )
      .catch(() =>
        setDataAtribut({
          ...dataAtribut,
          desa: [],
        })
      )
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          desa: false,
        })
      );
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    setIsLoading(true);

    if (tipe !== "tambah") {
      PabrikApi.single({ id_pabrik })
        .then((res) => {
          setData(res.data.data);
          getAnalisa();
          getAtribut(res.data.data);
        })
        .finally(() => setIsLoading(false));
    } else {
      getAtribut(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // INITIAL VALUES FORM TAMBAH DATA
  const formInitialValues = {
    nama_pabrik: tipe === "tambah" ? "" : data?.nama_pabrik,
    jenis_pabrik: tipe === "tambah" ? "" : data?.jenis_pabrik,
    keterangan_pabrik: tipe === "tambah" ? "" : data?.keterangan_pabrik,
    id_provinsi: tipe === "tambah" ? "" : data?.id_provinsi,
    id_kabupaten: tipe === "tambah" ? "" : data?.id_kabupaten,
    id_kecamatan: tipe === "tambah" ? "" : data?.id_kecamatan,
    id_desa: tipe === "tambah" ? "" : data?.id_desa,
    alamat_pabrik: tipe === "tambah" ? "" : data?.alamat_pabrik,
    id_unit_produksi: tipe === "tambah" ? "" : data?.id_unit_produksi,
  };
  if (tipe === "ubah") {
    formInitialValues.id_pabrik = data?.id_pabrik;
  }

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    nama_pabrik: Yup.string()
      .required("Nama Pabrik Jadi wajib diisi")
      .test("checkNama", "Nama Pabrik Jadi telah didaftarkan", (nama_pabrik) =>
        data?.nama_pabrik === nama_pabrik
          ? true
          : PabrikApi.single({ nama_pabrik })
              .then(() => false)
              .catch(() => true)
      ),
    jenis_pabrik: Yup.string().required("Jenis Pabrik wajib diisi"),
    id_provinsi: Yup.string().required("Provinsi wajib diisi"),
    id_kabupaten: Yup.string().required("Kabupaten wajib diisi"),
    id_kecamatan: Yup.string().required("Kecamatan wajib diisi"),
    id_desa: Yup.string().required("Desa wajib diisi"),
    alamat_pabrik: Yup.string().required("Alamat Pabrik wajib diisi"),
    id_unit_produksi: Yup.string().required("Unit Produksi wajib diisi"),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (tipe === "tambah") {
      PabrikApi.create(values)
        .then((res) => {
          setAlertConfig({
            show: true,
            type: "success",
            text: "Tambah data berhasil!",
            id_pabrik: res.data.data.id_pabrik,
          });
        })
        .catch(() => {
          setAlertConfig({
            ...alertConfig,
            show: true,
            type: "danger",
            text: "Tambah data gagal!",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else if (tipe === "ubah") {
      PabrikApi.update(values)
        .then(() => {
          setAlertConfig({
            show: true,
            type: "success",
            text: "Ubah data berhasil!",
            id_pabrik: "",
          });
        })
        .catch(() => {
          setAlertConfig({
            ...alertConfig,
            show: true,
            type: "danger",
            text: "Ubah data gagal!",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const deleteAnalisa = (id_mapping_pabrik_gudang) => {
    PabrikApi.mapping_pabrik_gudang_delete({ id_mapping_pabrik_gudang }).then(() => {
      const items = dataAnalisa.filter(
        (val) => val.id_mapping_pabrik_gudang !== id_mapping_pabrik_gudang
      );
      setDataAnalisa(items);
    });
  };

  const formSubmitHandlerAnalisa = (values, { setSubmitting }) => {
    PabrikApi.mapping_pabrik_gudang_store(values)
      .then(() => getAnalisa())
      .finally(() => setSubmitting(false));
  };

  const DataAnalisa = () => {
    const formInitialValuesAnalisa = {
      id_pabrik,
      id_gudang: "",
    };

    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchemaAnalisa = Yup.object().shape({
      id_gudang: Yup.string().required(" "),
    });

    return (
      <>
        {tipe !== "tambah" && (
          <Formik
            initialValues={formInitialValuesAnalisa}
            validationSchema={formValidationSchemaAnalisa}
            onSubmit={formSubmitHandlerAnalisa}
          >
            {({ values, errors, touched, isSubmitting, handleSubmit, setValues }) => (
              <form onSubmit={handleSubmit}>
                <CRUDLayout.Table>
                  <THead className="bg-light">
                    <Tr className="text-center">
                      <ThFixed>No</ThFixed>
                      <Th>Kode Gudang</Th>
                      <Th>Gudang</Th>
                      <Th>Unit Organisasi</Th>
                      <Th>Penanggung Jawab</Th>
                      <Th>Keterangan</Th>
                      {tipe !== "detail" && <ThFixed>Aksi</ThFixed>}
                    </Tr>
                  </THead>
                  <TBody>
                    {tipe === "ubah" && (
                      <Tr>
                        <Td></Td>
                        <Td></Td>
                        <Td noPadding>
                          <SelectSearch
                            noMargin
                            label=""
                            placeholder="Pilih Gudang"
                            onChange={(val) =>
                              setValues({
                                ...values,
                                id_gudang: val.value,
                              })
                            }
                            option={dataAtribut.gudang.map((val) => {
                              return {
                                value: val.id_gudang,
                                label: val.nama_gudang,
                              };
                            })}
                            error={errors.id_gudang && touched.id_gudang && true}
                            errorText={errors.id_gudang}
                            loading={loadingAtribut.gudang}
                          />
                        </Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td noPadding className="text-center">
                          <ActionButton
                            type="submit"
                            variant="primary"
                            loading={isSubmitting}
                            size="sm"
                            iconOnly
                          >
                            <IoAddOutline size={18} />
                          </ActionButton>
                        </Td>
                      </Tr>
                    )}

                    {dataAnalisa.length > 0 ? (
                      dataAnalisa.map((val, index) => {
                        return (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td className="align-middle">{val.kode_gudang}</Td>
                            <Td className="align-middle">{val.nama_gudang}</Td>
                            <Td className="align-middle">{val.nama_unit_organisasi}</Td>
                            <Td className="align-middle">{val.nama_penanggung_jawab}</Td>
                            <Td className="align-middle">{val.keterangan_gudang}</Td>
                            {tipe !== "detail" && (
                              <TdFixed className="align-middle">
                                <DeleteButton
                                  size="sm"
                                  onClick={() => deleteAnalisa(val.id_mapping_pabrik_gudang)}
                                />
                              </TdFixed>
                            )}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={tipe === "detail" ? 6 : 7}
                          className="align-middle text-center"
                        >
                          <DataStatus text="Tidak Ada Data" />
                        </Td>
                      </Tr>
                    )}
                  </TBody>
                </CRUDLayout.Table>
              </form>
            )}
          </Formik>
        )}
      </>
    );
  };

  return (
    // MAIN CRUD LAYOUT
    <CRUDLayout>
      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <>
          {/* Alert */}
          <PopUpAlert
            show={alertConfig.show}
            type={alertConfig.type}
            title={alertConfig.text}
            confirmButtonVariant={alertConfig.type}
            onHide={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
            onConfirm={() => {
              setAlertConfig({
                ...alertConfig,
                show: false,
              });

              if (alertConfig.type === "success" && tipe === "tambah") {
                history.push(`/master/pabrik/ubah/${alertConfig.id_pabrik}`);
              }
            }}
          />

          <Card className="pb-3 mt-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <b className="text-capitalize">{`${tipe} ${title}`}</b>
              <ActionButton
                size="sm"
                onClick={() => history.push("/master/pabrik", { ...routerState })}
                variant="outline-dark"
                text="Kembali ke Tabel"
              />
            </Card.Header>
            <Card.Body>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  setValues,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md>
                        <Input
                          label="Kode Pabrik"
                          placeholder="Kode Pabrik"
                          type="text"
                          readOnly={true}
                          value={tipe === "tambah" ? dataAtribut.no_baru : data?.kode_pabrik}
                        />

                        <Input
                          label="Nama Pabrik"
                          placeholder="Nama Pabrik"
                          type="text"
                          name="nama_pabrik"
                          value={values.nama_pabrik}
                          onChange={handleChange}
                          error={errors.nama_pabrik && touched.nama_pabrik && true}
                          errorText={errors.nama_pabrik}
                          readOnly={tipe === "detail" ? true : false}
                        />

                        <SelectSearch
                          label="Jenis Pabrik"
                          placeholder="Pilih Jenis Pabrik"
                          onChange={(val) =>
                            setValues({
                              ...values,
                              jenis_pabrik: val.value,
                            })
                          }
                          option={dataAtribut.jenis_pabrik.map((val) => {
                            return {
                              value: val,
                              label: val,
                            };
                          })}
                          defaultValue={
                            values.jenis_pabrik
                              ? dataAtribut.jenis_pabrik.map((val) => {
                                  if (val === values.jenis_pabrik) {
                                    return {
                                      label: val,
                                      value: val,
                                    };
                                  }
                                })
                              : ""
                          }
                          error={errors.jenis_pabrik && touched.jenis_pabrik && true}
                          errorText={
                            errors.jenis_pabrik && touched.jenis_pabrik && errors.jenis_pabrik
                          }
                          isDisabled={tipe === "detail" ? true : false}
                        />

                        <SelectSearch
                          label="Unit Produksi"
                          placeholder="Pilih Unit Produksi"
                          onChange={(val) =>
                            setValues({
                              ...values,
                              id_unit_produksi: val.value,
                            })
                          }
                          option={dataAtribut.unit_produksi.map((val) => {
                            return {
                              label: val.nama_unit_produksi,
                              value: val.id_unit_produksi,
                            };
                          })}
                          defaultValue={
                            values.id_unit_produksi
                              ? dataAtribut.unit_produksi.map((val) => {
                                  if (val.id_unit_produksi === values.id_unit_produksi) {
                                    return {
                                      label: val.nama_unit_produksi,
                                      value: val.id_unit_produksi,
                                    };
                                  }
                                })
                              : ""
                          }
                          error={errors.id_unit_produksi && touched.id_unit_produksi && true}
                          errorText={
                            errors.id_unit_produksi &&
                            touched.id_unit_produksi &&
                            errors.id_unit_produksi
                          }
                          isDisabled={tipe === "detail" ? true : false}
                          loading={loadingAtribut.unit_produksi}
                        />

                        <Input
                          type="text"
                          label="Keterangan"
                          placeholder="Keterangan"
                          name="keterangan_pabrik"
                          value={values.keterangan_pabrik}
                          onChange={handleChange}
                          error={errors.keterangan_pabrik && touched.keterangan_pabrik && true}
                          errorText={errors.keterangan_pabrik}
                          readOnly={tipe === "detail" ? true : false}
                        />
                      </Col>

                      <Col md>
                        <SelectSearch
                          label="Provinsi"
                          placeholder="Pilih Provinsi"
                          onChange={(val) => getKabupaten(val.value, values, setValues)}
                          option={dataAtribut.provinsi.map((val) => {
                            return {
                              value: val.id_provinsi,
                              label: val.nama_provinsi,
                            };
                          })}
                          defaultValue={
                            values.id_provinsi
                              ? dataAtribut.provinsi.map((val) => {
                                  if (val.id_provinsi === values.id_provinsi) {
                                    return {
                                      label: val.nama_provinsi,
                                      value: val.id_provinsi,
                                    };
                                  }
                                })
                              : ""
                          }
                          error={errors.id_provinsi && touched.id_provinsi && true}
                          errorText={
                            errors.id_provinsi && touched.id_provinsi && errors.id_provinsi
                          }
                          loading={loadingAtribut.provinsi}
                          isDisabled={tipe === "detail" ? true : false}
                        />

                        <SelectSearch
                          label="Kabupaten"
                          placeholder="Pilih Kabupaten"
                          onChange={(val) => getKecamatan(val.value, values, setValues)}
                          option={dataAtribut.kabupaten.map((val) => {
                            return {
                              value: val.id_kabupaten,
                              label: val.nama_kabupaten,
                            };
                          })}
                          defaultValue={
                            values.id_kabupaten
                              ? dataAtribut.kabupaten.map((val) => {
                                  if (val.id_kabupaten === values.id_kabupaten) {
                                    return {
                                      label: val.nama_kabupaten,
                                      value: val.id_kabupaten,
                                    };
                                  }
                                })
                              : ""
                          }
                          error={errors.id_kabupaten && touched.id_kabupaten && true}
                          errorText={
                            errors.id_kabupaten && touched.id_kabupaten && errors.id_kabupaten
                          }
                          loading={loadingAtribut.kabupaten}
                          isDisabled={tipe === "detail" ? true : false}
                        />

                        <SelectSearch
                          label="Kecamatan"
                          placeholder="Pilih Kecamatan"
                          onChange={(val) => getDesa(val.value, values, setValues)}
                          option={dataAtribut.kecamatan.map((val) => {
                            return {
                              value: val.id_kecamatan,
                              label: val.nama_kecamatan,
                            };
                          })}
                          defaultValue={
                            values.id_kecamatan
                              ? dataAtribut.kecamatan.map((val) => {
                                  if (val.id_kecamatan === values.id_kecamatan) {
                                    return {
                                      label: val.nama_kecamatan,
                                      value: val.id_kecamatan,
                                    };
                                  }
                                })
                              : ""
                          }
                          error={errors.id_kecamatan && touched.id_kecamatan && true}
                          errorText={
                            errors.id_kecamatan && touched.id_kecamatan && errors.id_kecamatan
                          }
                          loading={loadingAtribut.kecamatan}
                          isDisabled={tipe === "detail" ? true : false}
                        />

                        <SelectSearch
                          label="Desa"
                          placeholder="Pilih Desa"
                          onChange={(val) =>
                            setValues({
                              ...values,
                              id_desa: val.value,
                            })
                          }
                          option={dataAtribut.desa.map((val) => {
                            return {
                              value: val.id_desa,
                              label: val.nama_desa,
                            };
                          })}
                          defaultValue={
                            values.id_desa
                              ? dataAtribut.desa.map((val) => {
                                  if (val.id_desa === values.id_desa) {
                                    return {
                                      label: val.nama_desa,
                                      value: val.id_desa,
                                    };
                                  }
                                })
                              : ""
                          }
                          error={errors.id_desa && touched.id_desa && true}
                          errorText={errors.id_desa && touched.id_desa && errors.id_desa}
                          loading={loadingAtribut.desa}
                          isDisabled={tipe === "detail" ? true : false}
                        />

                        <Input
                          label="Alamat"
                          placeholder="Alamat"
                          type="text"
                          name="alamat_pabrik"
                          value={values.alamat_pabrik}
                          onChange={handleChange}
                          error={errors.alamat_pabrik && touched.alamat_pabrik && true}
                          errorText={errors.alamat_pabrik}
                          readOnly={tipe === "detail" ? true : false}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end">
                      <ActionButton
                        type="submit"
                        variant="primary"
                        text={`Simpan ${tipe === "tambah" ? "& Lanjut ke List Gudang" : ""}`}
                        className="mt-2 px-4"
                        loading={isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>

              {tipe !== "tambah" && (
                <>
                  <hr />
                  <h6>List Gudang Dalam Area Pabrik</h6>
                  <DataAnalisa />
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </CRUDLayout>
  );
};

export default CRUDPabrik;
