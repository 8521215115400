import React from "react";
import { DataStatus, Table, ThFixed } from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

const TabSubcont = ({ dataSubcont }) => {
  const TableSubcont = () => {
    const getGrup = (ref) => {
      if (ref === "RHW") return "Hardwood";
      if (ref === "RPW") return "Plywood";
      if (ref === "RFS") return "Factory Supply";
      if (ref === "RPF") return "Penunjang Finishing";
      if (ref === "RLC") return "Labour Cost";
      if (ref === "RLF") return "Labour Cost Finishing";
      if (ref === "RSF") return "Subkon Finishing";
      if (ref === "RMP") return "Machine Process";
      if (ref === "ROP") return "Biaya Overhead";
      return "-";
    };
    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          padding: 0,
          fontSize: "14px",
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={`${props.className} p-1`}
        style={{
          ...props.style,
          fontSize: "13px",
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <div className="pb-2">
          <b className="p-1">List BDP Subcont</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed className="py-2 text-center">No.</ThFixed>
              <ThFixed className="p-2 text-center">
                Informasi BDP Produksi
              </ThFixed>
              <Th className="p-2 text-center" style={{ width: 400 }}>
                Item Subcont
              </Th>
              <Th className="p-2 text-center" style={{ width: 200 }}>
                Vendor
              </Th>
              <Th className="p-2 text-center">Qty.</Th>
              <Th className="p-2 text-center">Harga Satuan</Th>
              <Th className="p-2 text-center">Jumlah</Th>
              <Th className="p-1 text-center">Akun COA</Th>
              <Th className="p-1 text-center">Keterangan Jurnal</Th>
            </tr>
          </thead>
          <tbody>
            {dataSubcont.length > 0 ? (
              dataSubcont.map((val, index) => {
                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td className="text-nowrap">
                      <div>
                        {val.tgl_bdp_subkon
                          ? DateConvert(new Date(val.tgl_bdp_subkon)).defaultDMY
                          : "-"}
                      </div>
                      <div>{val.no_bdp_subkon ?? "-"}</div>
                    </Td>
                    <Td>{val.nama_item ?? "-"}</Td>
                    <Td>{val.vendor ?? "-"}</Td>
                    <Td className="text-right text-nowrap">
                      {DecimalConvert(val?.qty_realisasi_subkon ?? 0)
                        .getWithComa +
                        " " +
                        val.nama_satuan}
                    </Td>
                    <Td className="text-right text-nowrap">
                      {
                        RupiahConvert(
                          parseFloat(val?.harga_satuan_subkon ?? 0).toString()
                        ).getWithComa
                      }
                    </Td>
                    <Td className="text-right text-nowrap">
                      {
                        RupiahConvert(
                          parseFloat(
                            parseFloat(val?.harga_satuan_subkon) *
                              parseFloat(val?.qty_realisasi_subkon ?? 0)
                          ).toString()
                        ).getWithComa
                      }
                    </Td>
                    <Td className="text-nowrap">
                      <div>
                        {val?.normal_pos_debit ?? "-"}:{" "}
                        {val?.nomor_akun_debet ?? "-"} -{" "}
                        {val?.nama_akun_debit ?? "-"}
                      </div>
                      <div>
                        {val?.normal_pos_kredit ?? "-"}:{" "}
                        {val?.nomor_akun_kredit ?? "-"} -{" "}
                        {val?.nama_akun_kredit ?? "-"}
                      </div>
                    </Td>
                    <Td>{val?.keterangan_jurnal ?? "-"}</Td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  // Menampilkan konten halaman saat data berhasil diperoleh
  return <TableSubcont />;
};

export default TabSubcont;
