// React
import React, { useState, useEffect } from "react"

// Router
import { useHistory, useLocation } from "react-router-dom"

// Components
import { ButtonGroup } from "react-bootstrap"
import {
  CRUDLayout, Alert, DataStatus, ReadButton, Table, InputSearch, 
  Pagination, THead, TBody, Tr, ThFixed, Td, FilterButton, Th, 
  TdFixed, ActionButton, BackButton
} from "components"
import { PageNumber as TableNumber } from "utilities"

// API
import { JobMixDesignApi } from "api"

// View Components
import { ModalFilter } from "./components"

// Icons
import { IoAddOutline } from "react-icons/io5"

const ListItemBarang = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory()
  const location = useLocation()

  // Variables
  const filter = location?.state?.pagination
  const title = "Job Mix Design"

  // States
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [data, setData] = useState([])
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    active: filter?.item?.active,
    page: filter?.item?.page ?? 1,
    per_page: filter?.item?.per_page ?? 10,
    q: filter?.item?.q ?? "",
    jenis_barang: filter?.item?.jenis_barang,
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)

  const getInitialData = () => {
    const filterData = {...dataFilter}
    delete filterData.active

    setIsPageLoading(true)

    JobMixDesignApi.list_item_barang(filterData).then(res => {
      setData(res?.data?.data ?? [])
      setTotalPage(res.data.total_page)
      setTotalData(res.data.data_count)
    }).catch(() => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })
    }).finally(() => setIsPageLoading(false))
  }

  const dismissAlert = () => {
    let state = { ...history.location.state }
    delete state.alert
    history.replace({ ...history.location, state })

    setAlertConfig({
      ...alertConfig,
      show: false,
    })
  }

  useEffect(() => {
    setNavbarTitle(title)

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [setNavbarTitle])

  useEffect(() => {
    getInitialData()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [dataFilter])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th>Item Barang</Th>
            <Th>Satuan Pakai</Th>
            <Th>Kelompok Barang</Th>
            <Th>Jenis Barang</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(dataFilter.page, dataFilter.per_page, index)}</TdFixed>
              <TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>{val.nama_item ?? "-"} [{val.nama_varian ?? "-"}]</Td>
              <Td>{val.nama_satuan_pakai ?? "-"}</Td>
              <Td>{val.nama_kelompok ?? "-"}</Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
              <Td className="align-middle">
                <ButtonGroup>
                  <ReadButton
                    size="sm"
                    tooltipText="Detail Item Barang"
                    onClick={() => history.push(`/master/job-mix-design/list-item-barang/detail/${val.id_item_buaso}`, {
                      pagination: {
                        ...filter,
                        item: dataFilter
                      },
                    })}
                  />

                  <ActionButton
                    size='sm'
                    tooltip
                    tooltipText="Buat Job Mix Design"
                    className='mx-0'
                    onClick={() => history.push(`/master/job-mix-design/tambah/${val.id_item_buaso}`, {
                      pagination: {
                        ...filter,
                        item: dataFilter
                      },
                    })}
                  >
                    <IoAddOutline size={12} />
                  </ActionButton>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    )

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <b>{`List Item Barang Yang Siap Dibuatkan ${title}`}</b>
        <DataTable />
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10 ? data.length : data.map((_res, index) => {
              if (index === data.length - 1) {
                return TableNumber(dataFilter.page, dataFilter.per_page, index)
              } else {
                return null
              }
            })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index)
            } else {
              return null
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1
            })
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setDataFilter({
            ...dataFilter,
            page: selected + 1
          })}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={dataFilter.q} onChange={e => setDataFilter({
              ...dataFilter,
              q: e.target.value
            })} />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <ActionButton
            className='mr-1'
            onClick={() => history.push(`/master/job-mix-design/tambah`, {
              pagination: {
                ...filter,
                item: dataFilter
              },
            })}
          >
            TAMBAH BARANG JADI BARU
          </ActionButton>

          <BackButton onClick={() => history.push(`/master/job-mix-design`, {
            pagination: {
              ...filter,
              item: dataFilter
            },
          })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <div style={{ minHeight: '500px' }}>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => dismissAlert()}
        />

        {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

        {modalFilter && (
          <ModalFilter
            show={modalFilter}
            setShow={setModalFilter}
            data={dataFilter}
            setData={setDataFilter}
          />
        )}
      </div>
    </CRUDLayout>
  )
}

export default ListItemBarang
