// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  BackButton,
  FilterButton,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";

// Icons
import { IoAddOutline, IoDocumentOutline } from "react-icons/io5";

// API
import { JobMixApi } from "api";
import { ModalFilter } from "./Section";
import { ModalAdendum } from "../components";

const ListSalesOrderJobMix = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const routerState = location.state;
  console.log(routerState);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSalesOrder, setDataSalesOrder] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.soJobmix?.filter?.active,
      tgl_sales_order_mulai:
        location?.state?.soJobmix?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai:
        location?.state?.soJobmix?.filter?.tgl_sales_order_selesai,
      customer: location?.state?.soJobmix?.filter?.customer,
      barang_jadi: location?.state?.soJobmix?.filter?.barang_jadi,
    },
    pagination: {
      page: location?.state?.soJobmix?.pagination?.page ?? "1",
      dataLength: location?.state?.soJobmix?.pagination?.dataLength ?? "10",
      totalPage: location?.state?.soJobmix?.pagination?.totalPage ?? "1",
      dataCount: location?.state?.soJobmix?.pagination?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalAdendumConfig, setModalAdendumConfig] = useState({
    show: false,
    id: undefined,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    JobMixApi.getSalesOrder({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      customer: dataFilter?.filter?.customer,
      barang_jadi: dataFilter?.filter?.barang_jadi,
    })
      .then((data) => {
        setDataSalesOrder(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Produksi");

    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.barang_jadi,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Customer</Th>
            <Th>Barang Jadi</Th>
            <Th noPadding width={80}>
              Qty Sales Order
            </Th>
            <Th noPadding width={80}>
              Qty Sedang Diproduksi
            </Th>
            <Th noPadding width={80}>
              Qty Telah Direalisasi
            </Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataSalesOrder.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </Td>
              <Td>
                <div>
                  {val.tgl_sales_order
                    ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_sales_order ?? "-"}</div>
              </Td>
              <Td>{val.nama_customer ?? "-"}</Td>
              <Td>{val.nama_barang ?? "-"}</Td>
              <Td>
                {`${DecimalConvert(val.qty_item ?? 0).getWithComa} ${
                  val.kode_satuan ?? ""
                }`}
              </Td>
              <Td>
                {`${
                  DecimalConvert(val.qty_sedang_diproduksi ?? 0).getWithComa
                } ${val.kode_satuan ?? ""}`}
              </Td>
              <Td>
                {`${DecimalConvert(val.qty_direalisasi ?? 0).getWithComa} ${
                  val.kode_satuan ?? ""
                }`}
              </Td>
              <Td>
                {val.telah_diadendum ? (
                  <ActionButton
                    variant="danger"
                    size="sm"
                    tooltip
                    tooltipText="SPK Sudah Diadendum"
                    onClick={() =>
                      setModalAdendumConfig({
                        ...modalAdendumConfig,
                        show: true,
                        id: val.id_sales_order,
                      })
                    }
                  >
                    <IoDocumentOutline />
                  </ActionButton>
                ) : (
                  <ActionButton
                    size="sm"
                    tooltip
                    tooltipText="Register Produksi"
                    onClick={() =>
                      history.push(
                        "/transaksi/job-mix/tambah/" +
                          val.id_sales_order_detail,
                        {
                          dataSalesOrder: {
                            id_sales_order: val.id_sales_order,
                            no_sales_order: val.no_sales_order,
                            id_item: val.id_item_atribut,
                            nama_item: val.nama_barang,
                            qty: val.qty_item,
                            customer: val.nama_customer,
                            is_spk: val.is_spk,
                          },
                          ...location?.state,
                          soJobmix: dataFilter,
                        }
                      )
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataSalesOrder || dataSalesOrder.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>List Sales Order Yang Siap Dibuatkan Produksi</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            className="mr-2"
            onClick={() =>
              history.push("/transaksi/job-mix", {
                ...routerState.pagination,
                ...location?.state,
                soJobmix: dataFilter,
              })
            }
          />

          <ActionButton
            variant="primary"
            text="BUAT PRODUKSI TANPA SO"
            onClick={() =>
              history.push("/transaksi/job-mix/tambah", {
                ...routerState.pagination,
                ...location?.state,
                soJobmix: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
      {modalAdendumConfig.show && (
        <ModalAdendum
          setModalConfig={setModalAdendumConfig}
          modalConfig={modalAdendumConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default ListSalesOrderJobMix;
