import React, { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import { Alert, CRUDLayout } from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from "components2";
import { ModalFilter, TableList } from "./__PermintaanProduksiAsetComps__";
import PermintaanProduksiAsetApi from "./__PermintaanProduksiAsetApi__";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";

export const PermintaanProduksiAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const [filter, setFilter] = useState({
    q: "",
    tgl_penerimaan_aset_mulai: undefined,
    tgl_penerimaan_aset_selesai: undefined,
    tgl_purchase_order_aset_mulai: undefined,
    tgl_purchase_order_selesai: undefined,
    id_vendor: undefined,
    status_approval: undefined,
    active: false,
  });

  const [paginationConfig, setPaginationConfig] = useState({
    bahan: {
      page: 1,
      per_page: 10,
    },
    upah: {
      page: 1,
      per_page: 10,
    },
    alatMesin: {
      page: 1,
      per_page: 10,
    },
    subkon: {
      page: 1,
      per_page: 10,
    },
    overhead: {
      page: 1,
      per_page: 10,
    },
  });

  const [alertPerTabConfig, setAlertPerTabConfig] = useState({
    bahan: {
      show: false,
      text: "",
      variant: "primary",
    },
    upah: {
      show: false,
      text: "",
      variant: "primary",
    },
    alatMesin: {
      show: false,
      text: "",
      variant: "primary",
    },
    subkon: {
      show: false,
      text: "",
      variant: "primary",
    },
    overhead: {
      show: false,
      text: "",
      variant: "primary",
    },
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getTabBahan = useQuery(["tabBahan", "list", filter], () =>
    PermintaanProduksiAsetApi.getList({
      ...filter,
      ...paginationConfig?.bahan,
      id_buaso: 1,
    }).catch(() =>
      setAlertPerTabConfig({
        ...alertPerTabConfig,
        bahan: {
          show: true,
          variant: "danger",
          text: "Data Permintaan Produksi Bahan Gagal Dimuat !!!",
        },
      })
    )
  );

  const getTabUpah = useQuery(["tabUpah", "list", filter], () =>
    PermintaanProduksiAsetApi.getList({
      ...filter,
      ...paginationConfig?.upah,
      id_buaso: 2,
    }).catch(() =>
      setAlertPerTabConfig({
        ...alertPerTabConfig,
        upah: {
          show: true,
          variant: "danger",
          text: "Data Permintaan Produksi Upah Gagal Dimuat !!!",
        },
      })
    )
  );

  const getTabAlatMesin = useQuery(["tabAlatMesin", "list", filter], () =>
    PermintaanProduksiAsetApi.getList({
      ...filter,
      ...paginationConfig?.alatMesin,
      id_buaso: 3,
    }).catch(() =>
      setAlertPerTabConfig({
        ...alertPerTabConfig,
        alatMesin: {
          show: true,
          variant: "danger",
          text: "Data Permintaan Alat & Mesin Gagal Dimuat !!!",
        },
      })
    )
  );

  const getTabSubkon = useQuery(["tabSubkon", "list", filter], () =>
    PermintaanProduksiAsetApi.getList({
      ...filter,
      ...paginationConfig?.subkon,
      id_buaso: 4,
    }).catch(() =>
      setAlertPerTabConfig({
        ...alertPerTabConfig,
        subkon: {
          show: true,
          variant: "danger",
          text: "Data Permintaan Subkon Gagal Dimuat !!!",
        },
      })
    )
  );

  const getTabOverhead = useQuery(["tabOverhead", "list", filter], () =>
    PermintaanProduksiAsetApi.getList({
      ...filter,
      ...paginationConfig?.overhead,
      id_buaso: 5,
    }).catch(() =>
      setAlertPerTabConfig({
        ...alertPerTabConfig,
        overhead: {
          show: true,
          variant: "danger",
          text: "Data Permintaan Overhead Gagal Dimuat !!!",
        },
      })
    )
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_penerimaan_aset_mulai: filter?.tgl_penerimaan_aset_mulai,
    tgl_penerimaan_aset_selesai: filter?.tgl_penerimaan_aset_selesai,
    tgl_purchase_order_aset_mulai: filter?.tgl_purchase_order_aset_mulai,
    tgl_purchase_order_selesai: filter?.tgl_purchase_order_selesai,
    id_vendor: filter?.id_vendor,
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => {
    setNavbarTitle("Permintaan Produksi Aset");
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            {/* <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            /> */}
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate
            size="md"
            icon
            onClick={() =>
              history.push("permintaan-produksi-aset/produksi-aset")
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        showCloseButton
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      <div className="mt-2">
        <small className="font-weight-bold">
          List Data Permintaan Produksi Aset
        </small>
      </div>
      <Card className="p-0 m-0">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bahan">Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upah">Upah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alatMesin">Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="subkon">Subkon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overhead">Overhead</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="bahan" className="p-2">
              <TableList
                query={getTabBahan}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="bahan"
                refetch={getTabBahan.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="upah" className="p-2">
              <TableList
                query={getTabUpah}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="upah"
                refetch={getTabUpah.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="alatMesin" className="p-2">
              <TableList
                query={getTabAlatMesin}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="alatMesin"
                refetch={getTabAlatMesin.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="subkon" className="p-2">
              <TableList
                query={getTabSubkon}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="subkon"
                refetch={getTabSubkon.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="overhead" className="p-2">
              <TableList
                query={getTabOverhead}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="overhead"
                refetch={getTabOverhead.refetch}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
