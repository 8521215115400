import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Card, Row, Col, Nav } from "react-bootstrap";
import { Alert, BackButton, DataStatus } from "components";
import { DateConvert } from "utilities";
import { TabBahan, TabUpah, TabAlatMesin, TabSubcont, TabOverhead, TabSisaProduksi } from "./Tabs";
import { CloseJobMixApi } from "api";

const DetailCloseJobMix = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const routerState = location.state;
  const { header } = location.state || {}; // MENYIMPAN DATA ITEM DARI GET PAGE UNTUK INFO SECTION
  const { id } = useParams();
  const [listBDP, setListBDP] = useState({});
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  // Menangani fetching data ketika halaman di render
  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    CloseJobMixApi.getListBDP({
      id_jobmix: id,
      // no_jobmix: header.no_jobmix
    })
      .then((listBDP) => {
        setListBDP(listBDP?.data?.data ?? {});
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch((e) =>
        setFetchingStatus({
          loading: false,
          success: false,
        })
      );
  };

  useEffect(() => {
    setNavbarTitle("Close Produksi");
    header?.no_jobmix ? getInitialData() : setFetchingStatus({ loading: false, success: false });

    return () => {};
  }, []);

  const InfoSection = () => {
    const InfoItems = ({ label, value }) => (
      <tr style={{ fontSize: 14 }}>
        <td>{label}</td>
        <td className="pl-3 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );
    const ShowData = ({ label, value }) => (
      <div className="mb-2">
        <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
          {label}
        </small>
        <div className="text-dark" style={{ fontWeight: 500 }}>
          {value ? value : "-"}
        </div>
      </div>
    );

    return (
      <div>
        <Row>
          <Col md>
            <table>
              <tbody>
                <InfoItems
                  label="Tgl. Produksi"
                  value={header?.tgl_jobmix ? DateConvert(new Date(header.tgl_jobmix)).detail : "-"}
                />
                <InfoItems label="No. Produksi" value={header?.no_jobmix ?? "-"} />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table>
              <tbody>
                <InfoItems label="Customer" value={header?.nama_customer ?? "-"} />
                <InfoItems label="Barang Jadi" value={header?.nama_item ?? "-"} />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <Row className="mb-2">
          <Col>
            <ShowData
              label="Tgl. Close Produksi"
              value={
                header?.tgl_close_jobmix
                  ? DateConvert(new Date(header?.tgl_close_jobmix)).detail
                  : "-"
              }
            />
          </Col>
          <Col>
            <ShowData label="No. Close Produksi" value={header?.no_close_jobmix ?? "-"} />
          </Col>
          <Col>
            <ShowData
              label="Qty. Produksi"
              value={`${parseFloat(header?.qty_jobmix ?? 0).toPrecision()} ${
                header?.kode_satuan ?? ""
              }`}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const TableSection = () => {
    const [tabs, setTabs] = useState("bahan"); //State untuk menampung tabs yang aktif

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: "bahan",
        label: "Bahan",
        component: () => <TabBahan dataBahan={listBDP?.bahan ?? []} />,
      },
      {
        tab: "upah",
        label: "Upah",
        component: () => <TabUpah dataUpah={listBDP?.upah ?? []} />,
      },
      {
        tab: "alatMesin",
        label: "Alat & Mesin",
        component: () => <TabAlatMesin dataAlatMesin={listBDP?.alat_mesin ?? []} />,
      },
      {
        tab: "subcont",
        label: "Subcont",
        component: () => <TabSubcont dataSubcont={listBDP?.subkon ?? []} />,
      },
      {
        tab: "overhead",
        label: "Overhead",
        component: () => <TabOverhead dataOverhead={listBDP?.overhead ?? []} />,
      },
      // {
      //     tab: 'sisaProduksi',
      //     label: 'Sisa Produksi',
      //     component: () => <TabSisaProduksi dataSisaProduksi={listBDP?.sisa_produksi ?? []} />
      // },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
            {tabsConfig.map((val, index) => (
              <TabsNav key={index} tab={val.tab} label={val.label} />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(
            ({ tab, component: Component }, index) => tab === tabs && <Component key={index} />
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Close Produksi</b>
        <BackButton onClick={() => history.push("/transaksi/close-job-mix", { ...routerState })} />
      </Card.Header>
      <Card.Body>
        {fetchingStatus.loading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : fetchingStatus.success ? (
          <>
            <InfoSection />
            <TableSection />
          </>
        ) : (
          <DataStatus text="Data gagal dimuat!" />
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailCloseJobMix;
